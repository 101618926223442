import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
import {StudentMessages} from '../../shared/student-messages';
import { compileComponentFromMetadata } from '@angular/compiler';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import { DatePipe,formatDate } from '@angular/common';
import { StaffService } from '../../shared/staff/staff.service';



@Component({
  selector: 'app-corp-updateclient',
  templateUrl: './corp-updateclient.component.html',
  styleUrls: ['./corp-updateclient.component.css']
})
export class CorpUpdateclientComponent implements OnInit {
  datePickerConfig:Partial<BsDatepickerConfig>;

  public ProfileModel:FormGroup;
  public IDQModel:FormGroup;
  public PIModel:FormGroup;
  public UAModel:FormGroup;
  public id:string="";
  public edyear:string="";  
  public idQuestion:string='';
  public fileName:any;
  public iqtypes:any=[];
  public emailValid:any=[];
  public usernameValid:any=[];
  ProofofCards: FormGroup;
  public Rid :string="";
  
  clientdata=[];
  

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private route:ActivatedRoute,    private loginService: LoginService,public routing:Router) {
      this.titleservice.setTitle("Profile");
      this.datePickerConfig=Object.assign({},
        { 
          containerClass:'theme-dark-blue',
          showWeekNumbers: false,
          dateInputFormat:'MM/DD/YYYY',
          isAnimated: true,         
        });

        this.ProfileModel=this.fb.group({
          FirstName:'',
          LastName:'',
          MiddleName:'',
          DOB:'',
          SSN:'',
          CurrentEmail:'',
          CurrentPhone:'',
          Address1:'',
          Address2:'',
          City:'',
          State:'',
          ZipCode:''
        });
        this.IDQModel=this.fb.group({
          IQTypes:"2",//MY SCORE IQ
          IdUserName:'',
          IdPassword:'', 
          IdQuestion:'',
          IdAnswer:'' 
        });
        this.UAModel=this.fb.group({
          UserName:'',
          Password:''   
         });
    
 }

 ngOnInit(): void {
  let user=this.loginService.getUser();
  if(user!=null||user!=undefined){
    if(user.userRole=="corpcustomer"){
      this.id="";
      this.id = user.userID; 
      this.Rid = this.route.snapshot.queryParams["id"];     
    }
    else{
      this.routing.navigate(['corp']);
    }      
  }
  this.ProofofCards = this.fb.group({
    ClientProof: [''],
    
  });

  

  if(this.id!=null && this.id!=""){    
    let obj={
      key:"123",
      clientid:this.Rid
    }
      this.loginService.GetClientsById(obj).subscribe(res=>{      
      if(res.status==1){        
          this.EditFill(res);
          this.EditFillIDQ(res);
          //this.EditFillPI(res);
          this.EditFillUA(res);
      }
  });
}
let obj={
  key:"123"
}
this.loginService.GetIQTypes(obj).subscribe(res=>{    
  debugger
       if(res.status==1){
         for(let i=2;i<res.data.length;i++){
           debugger
           let objIQtypes={
                iqTypeId:res.data[i].iqTypeId,
                iqName:res.data[i].iqName
           }
           this.iqtypes.push(objIQtypes);
         }
     }
  });
}

//method to change dateformat
changeDateFormat(value: string) {
var datePipe = new DatePipe("en-US");
 value = datePipe.transform(value, 'MM/dd/yyyy');
 return value;
}
ssnValidation(){ 
var ssn=this.ProfileModel.value.SSN;
var ssnReg = /(^\d{2}-\d{7}$)/;
if(!ssnReg.test(ssn)) {
  Swal.fire('Warning','Please enter valid Social security number.','warning');
  $("#SocialSecurityNo").val('');
  $("#SocialSecurityNo").focus();
  return false;
 }
}
emailValidation(){
var emails=this.ProfileModel.value.CurrentEmail;
var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
if( !emailReg.test( emails ) ) {
  Swal.fire('Warning','Please enter email correctly.','warning');
  $("#Email").val('');
  $("#Email").focus();
    return false;
} 
else if(emails==""){
  Swal.fire('Warning','Please enter email address.','warning');
  return false;
}
}
phoneValidation(){ 
var phone=this.ProfileModel.value.CurrentPhone;
      var phoneReg =/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
      if(!phoneReg.test(phone)) {
      Swal.fire('Warning','Please enter valid phone number.','warning');
      $("#PhoneNo").val('');
      $("#PhoneNo").focus();  
      return false;
      }
 }

 numberValidation(){ 
  var num=this.ProfileModel.value.ZipCode;
        var numReg =/^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/;
        if(!numReg.test(num)) {
        Swal.fire('Warning','Please enter numbers only.','warning');
        $("#zipcode").val('');
        $("#zipcode").focus();  
        return false;
        }
   }

   cardValidation(){ 
    var card=this.PIModel.value.CardNumber;
          var cardReg =/^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/;
          if(!cardReg.test(card)) {
          Swal.fire('Warning','Please enter numbers only.','warning');
          $("#CardNumber").val('');
          $("#CardNumber").focus();  
          return false;
          }
     }

    
       cvvValidation(){ 
        var num=this.PIModel.value.CVV;
              var numReg =/^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/;
              if(!numReg.test(num)) {
              Swal.fire('Warning','Please enter numbers only.','warning');
              $("#cvv").val('');
              $("#cvv").focus();  
              return false;
              }
         }
      
         bzcodeValidation(){ 
          var num=this.PIModel.value.BillingZipcode;
                var numReg =/^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/;
                if(!numReg.test(num)) {
                Swal.fire('Warning','Please enter numbers only.','warning');
                $("#billingZipcode").val('');
                $("#billingZipcode").focus();  
                return false;
                }
           }
    


EditFill(rowData){  
this.ProfileModel=this.fb.group({  
ClientId:this.id,
FirstName:[rowData.data[0].firstName],
LastName:[rowData.data[0].lastName],
MiddleName:[rowData.data[0].middleName],
DOB:[rowData.data[0].dob],
SSN:[rowData.data[0].ssn],
CurrentEmail:[rowData.data[0].currentEmail],

CurrentPhone:[rowData.data[0].currentPhone],
Address1:[rowData.data[0].address1],
Address2:[rowData.data[0].address2],
City:[rowData.data[0].city],
State:[rowData.data[0].state],
ZipCode:[rowData.data[0].zipCode] ,
sProofOfCard:[rowData.data[0].sProofOfCard],

});
this.emailValid=[rowData.data[0].currentEmail];
this.usernameValid=[rowData.data[0].userName];
this.fileName=[rowData.data[0].sProofOfCard];
}
EditFillIDQ(rowData){
 this.IDQModel=this.fb.group({
  IQTypes:[rowData.data[0].iqTypeId],
  IdUserName:[rowData.data[0].idUserName],
  IdPassword:[rowData.data[0].idPassword],  
  IdQuestion:[rowData.data[0].idQuestion],
  IdAnswer:[rowData.data[0].idAnswer]
 });
}

//  EditFillPI(rowData){  
//   var billType = rowData.data[0].billingType;
//   if(billType == null || billType == "")
//   {
//     billType='Monthly';
//   }
//   var cardType = rowData.data[0].cardType;
//   if(cardType == null || cardType == "")
//   {
//    cardType='Select';
//   }
//  var expiryMonth=rowData.data[0].expiryMonth; 
//   if(expiryMonth == null || expiryMonth == "")
//   {
//     expiryMonth='Select';
//   }

//   var expiryYear=rowData.data[0].expiryYear; 
//   if(expiryYear == null || expiryYear == "")
//   {
//     expiryYear='Select';
//   }
//   else
//   {
//     expiryYear="20"+expiryYear;
//   }

//   this.PIModel=this.fb.group({
//    PricingPlan:[rowData.data[0].pricingPlan],
//    BillingType:[billType],
//    CardType:[cardType],
//    CardNumber:[rowData.data[0].cardNumber],   
//    expiryMonth:[expiryMonth], 
 
//    expiryYear:[expiryYear],
//   // ExpiryDate:expiryMonth+'-'+expiryYear,
//    CVV:[rowData.data[0].cvv],
//    BillingZipcode:[rowData.data[0].billingZipcode]
//   });

// }
EditFillUA(rowData){
 this.UAModel=this.fb.group({
  UserName:[rowData.data[0].userName],
  // Password:[rowData.data[0].password]  

 });
}
//File validation example
validateFile(name: String) {
var ext = name.substring(name.lastIndexOf('.') + 1);
if (ext.toLowerCase() == 'png') {
    return true;
}else if(ext.toLowerCase() =='jpeg'){
  return true;
}else if(ext.toLowerCase() =='jpg'){
  return true;
}else if(ext.toLowerCase() =='pdf'){
  return true;
}else if(ext.toLowerCase() =='dmp'){
  return true;
}else {
    return false;
}
}
ProofofCard(event){  

if(event.target.files.length > 0){
  const file = event.target.files[0];
  if(!this.validateFile(file.name)){
    Swal.fire("Warning!",StudentMessages.FORM_SWAL_FILEFORMATS,"warning");
    //this.parentw2file.nativeElement.value="";
    var $pw=$("#pcfile");
    $pw.val("");
    return false;
  }
  this.ProofofCards.get('ClientProof').setValue(file);
  
}
//this.ProofofCards.get('ClientProof').setValue("");
}

//Navigation
next(id,previd)
{
  
 // alert(id); alert(previd);
  document.getElementById(id+'-tab').setAttribute("aria-selected", "true");
  document.getElementById(id+'-tab').setAttribute("class", "nav-link active");
  document.getElementById(id+'-tab').setAttribute("href", "#animated-underline-profile");
  document.getElementById(id).setAttribute("class", "tab-pane fade show active pl-4");

  document.getElementById(previd).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(previd+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(previd+'-tab').setAttribute("class", "nav-link");


}
activeBKUP(id,prev1,prev2,prev3)
{
  document.getElementById(id+'-tab').setAttribute("aria-selected", "true");
  document.getElementById(id+'-tab').setAttribute("class", "nav-link active");
  document.getElementById(id+'-tab').setAttribute("href", "#animated-underline-profile");
  document.getElementById(id).setAttribute("class", "tab-pane fade show active pl-4");

  document.getElementById(prev1).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(prev1+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(prev1+'-tab').setAttribute("class", "nav-link");

  
  document.getElementById(prev2).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(prev2+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(prev2+'-tab').setAttribute("class", "nav-link");

  
  document.getElementById(prev3).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(prev3+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(prev3+'-tab').setAttribute("class", "nav-link");
}

active(id,prev1,prev2)
{
  document.getElementById(id+'-tab').setAttribute("aria-selected", "true");
  document.getElementById(id+'-tab').setAttribute("class", "nav-link active");
  document.getElementById(id+'-tab').setAttribute("href", "#animated-underline-profile");
  document.getElementById(id).setAttribute("class", "tab-pane fade show active pl-4");

  document.getElementById(prev1).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(prev1+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(prev1+'-tab').setAttribute("class", "nav-link");

  
  document.getElementById(prev2).setAttribute("class", "tab-pane fade pl-4");
  document.getElementById(prev2+'-tab').setAttribute("aria-selected", "false");
  document.getElementById(prev2+'-tab').setAttribute("class", "nav-link");
}

SaveForm()
{
  debugger

     var idPwd=$("#idPassword").val();
     var idCPassword=$("#idCPassword").val();

     if(idPwd!=idCPassword){
      Swal.fire("Warning","Identity IQ Confirm Password not matched with IQPassword","warning");
      return;
     }
     var password=$("#Password").val();
     var cnfPassword=$("#cnfPassword").val();

     if(password!=cnfPassword)
     {
       Swal.fire("Warning","User Access Confirm Password not matched with UAPassword","warning");
       return;
     }
    if(this.ProfileModel.value.FirstName==""){
      Swal.fire('Warning','Please enter firstname','warning');
      return;
    }   
    if(this.ProfileModel.value.LastName==""){
      Swal.fire('Warning','Please enter lastname','warning');
      return;
    }   
    if(this.ProfileModel.value.SSN==""){
      Swal.fire('Warning','Please enter social security number','warning');
      return;
    }   
    if(this.ProfileModel.value.CurrentEmail==""){
      Swal.fire('Warning','Please enter email address','warning');
      return;
    } 
    if(this.ProfileModel.value.CurrentPhone==""){
      Swal.fire('Warning','Please enter phone number','warning');
      return;
    }  
    if(this.ProfileModel.value.Address1==""){
      Swal.fire('Warning','Please enter address1','warning');
      return;
    }
    if(this.ProfileModel.value.City==""){
      Swal.fire('Warning','Please enter city','warning');
      return;
    }
    if(this.ProfileModel.value.State==""){
      Swal.fire('Warning','Please enter state','warning');
      return;
    }
    if(this.ProfileModel.value.ZipCode==""){
      Swal.fire('Warning','Please enter zipcode','warning');
      return;
    }
    if(this.fileName==''){
      if($("#pcfile").val()==""){
        Swal.fire('Warning','Please select identity proof','warning');
        return;
      }      
    }    
    
     //Payment Info

    //  if(this.PIModel.value.CardType=="Select"){
    //   Swal.fire('Warning','Please enter cardtype','warning');
    //   return;
    // }
    // if(this.PIModel.value.CardNumber=="" || this.PIModel.value.CardNumber==null){
    //   Swal.fire('Warning','Please enter cardnumber','warning');
    //   return;
    // }
    
    // if(this.PIModel.value.expiryMonth=="Select"){
    //   Swal.fire('Warning','Please select expiryMonth','warning');
    //   return;
    // }
    // if(this.PIModel.value.expiryYear=="Select"){
    //   Swal.fire('Warning','Please select expiryYear','warning');
    //   return;
    // }
    // if(this.PIModel.value.CVV=="" || this.PIModel.value.CVV==null){
    //   Swal.fire('Warning','Please enter cvv','warning');
    //   return;
    // }
    // if(this.PIModel.value.BillingZipcode==""|| this.PIModel.value.BillingZipcode==null){
    //   Swal.fire('Warning','Please enter billingzipcode','warning');
    //   return;
    // }    

    
    // if(this.PIModel.value.expiryYear!="")
    // {
    //   this.edyear=this.PIModel.value.expiryYear.replace('20','');
    // }

    //User Access Information
    if(this.UAModel.value.UserName=="" ||this.UAModel.value.UserName==null){
      Swal.fire('Warning','Please enter username','warning');
      return;
    }
    // if(this.UAModel.value.Password=="" || this.UAModel.value.Password==null){
    //   Swal.fire('Warning','Please enter password','warning');
    //   return;
    // }
    // if($("#cnfPassword").val()==""){
    //   Swal.fire('Warning','Please enter confirmpassword','warning');
    //   return;
    // }

   
   
         debugger
         if(this.UAModel.value.UserName !=this.usernameValid)
         {
          let objEmail={
            key:"123",
            email:this.UAModel.value.UserName,
            id:this.id.toString()
          }
          this.loginService.CheckEmailExists(objEmail).subscribe(res=>{
            if(res.status==1){                 
              Swal.fire('Warning','Username  already exists','warning');
              this.UAModel.value.UserName="";
              $("#userName").val("");
              return;
             }
         })
        }
        
         if(this.ProfileModel.value.CurrentEmail!=this.emailValid)
         {
          let objEmail={
            key:"123",
            email:this.ProfileModel.value.CurrentEmail,
            id:this.id.toString()
          }
          this.loginService.CheckEmailExists(objEmail).subscribe(res=>{
            if(res.status==1){                 
              Swal.fire('Warning','Email already exists','warning');
              this.ProfileModel.value.CurrentEmail="";
              $("#Email").val("");
              return;
             }
         })
         }
          
         if(this.ProfileModel.value.CurrentEmail !="" && this.UAModel.value.UserName != "")
         {
          let obj={
            ClientId:this.Rid,
            FirstName:this.ProfileModel.value.FirstName,
            LastName:this.ProfileModel.value.LastName,
            MiddleName:this.ProfileModel.value.MiddleName,
            DOB:this.changeDateFormat(this.ProfileModel.value.DOB),
            SSN:this.ProfileModel.value.SSN,
            CurrentEmail:this.ProfileModel.value.CurrentEmail,
            CurrentPhone:this.ProfileModel.value.CurrentPhone,
            Address1:this.ProfileModel.value.Address1,
            Address2:this.ProfileModel.value.Address2,
            City:this.ProfileModel.value.City,
            State:this.ProfileModel.value.State,
            ZipCode:this.ProfileModel.value.ZipCode,  

            IQTypeId:2, //this.IDQModel.value.IQTypes,
            IdUserName:this.IDQModel.value.IdUserName,
            IdPassword:this.IDQModel.value.IdPassword,
            IdQuestion:this.IDQModel.value.IdQuestion,        
            IdAnswer:this.IDQModel.value.IdAnswer,

            // PricingPlan:this.PIModel.value.PricingPlan,
            // BillingType:this.PIModel.value.BillingType,
            // CardType:this.PIModel.value.CardType,
            // CardNumber:this.PIModel.value.CardNumber,
            // expiryMonth:this.PIModel.value.expiryMonth,
            // expiryYear:this.edyear,   
            // ExpiryDate:this.PIModel.value.expiryMonth+'-'+this.edyear,
            // CVV:this.PIModel.value.CVV,
            // BillingZipcode:this.PIModel.value.BillingZipcode,

            UserName:this.UAModel.value.UserName,
            Password:password,                

          }               
            this.clientdata.push(obj);         
            const formData=new FormData();
            formData.append('key',"123");      
            formData.append('ClientProof',this.ProofofCards.get('ClientProof').value);        
            formData.append('clientdetails',JSON.stringify(this.clientdata[0]));       
            this.loginService.UpdateClientById(formData).subscribe(res=>{      
              debugger
              if(res.status==1){
                 Swal.fire("Success","Client updated successfully","success");
                 return;
              }
              else{
                 Swal.fire("Warning","Client not updated","warning");
                 return;
              }
            });
            
          
         }
            
      
    
  }  
}
