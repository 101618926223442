<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
               <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                   <div class="row">
                       <div class="col-md-12">
                           <ol class="breadcrumb">
                               <li class="breadcrumb-item ml-auto"><a href="client-dashboard">Dashboard</a></li>
                               <li class="breadcrumb-item active text-primary" aria-current="page"><span>View Challenges</span></li>
                           </ol>
                       </div>              
                   </div>
           
              <div class="card">
                 <div class="card-header  bg-light"><b class="text-secondary">View Challenges</b></div>          
                     <div class="card-body">
                        <div class="row pb-3">
                            <div class="col-md-12">
                                <h6><span class="text-primary">Client Name</span> : {{Name}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>Account History</h3>
                            </div>
                        </div>
                        <div class="row">
                             <div class="col-lg-12">
                                 <div class="table-responsive">
                                   <table id="tblViewChallenges" class="table table-striped table-bordered">
                                      <thead>
                                         <tr>              
                                            <th>Round Type</th>                                    
                                            <th>File Name</th>   
                                            <!-- <th>Actions</th>                 -->
                                         </tr>
                                     </thead>
                                     <tbody>
                                         <tr *ngFor="let data of rowDataGrid">                                    
                                             <td>{{data.RoundType}}</td>                              
                                             <td>{{data.CAgency}}</td>  
                                             <!-- <td><a (click)="ViewPDF(data.filename)" class="btn btn-success" title="print">Print</a> 
                                             </td>                                                                                         -->
                                         </tr>
                                     </tbody>
                                  </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <h5><span class="text-danger">{{noAccounts}}</span></h5>
                            </div>
                        </div>
                     </div>
                 </div>                
              </div>
           </div>
        </div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->