<div class="form-container outer px-2">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">
                <div class="form-content">
                    <h1 class="">Sign Up</h1>
                    <form class="text-left" [formGroup]="SignUpForm" novalidate>
                        <div class="row">
                            <div class="col-md-12">
                                <div id="firstname-field" class="field-wrapper input">
                                    <label for="firstname">REFERRAL CODE<span class="text-danger" style="font-size:medium;">*</span></label>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> 
                                    <input type="text" class="form-control" formControlName="refcode"  id="refcode"
                                     placeholder="Referral Code" maxlength="6" required>                      
                                    
                                </div>
                            </div>
                           
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div id="firstname-field" class="field-wrapper input">
                                    <label for="firstname">FIRST NAME<span class="text-danger" style="font-size:medium;">*</span></label>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> 
                                    <input type="text" class="form-control" formControlName="firstname"  id="firstname"
                                     placeholder="First Name" required>                      
                                    
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div id="lastname-field" class="field-wrapper input">
                                    <label for="lastname">LAST NAME<span class="text-danger" style="font-size:medium;">*</span></label>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    <input type="text" class="form-control" formControlName="lastname"  id="lastname"
                                     placeholder="Last Name" required>                      
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div id="emailaddress-field" class="field-wrapper input">
                                 
                                        <label for="emailaddress">EMAIL ADDRESS<span class="text-danger" style="font-size:medium;">*</span></label>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"                                         
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                                        stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail">
                                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                   
                                    <input type="text" class="form-control" formControlName="emailaddress"  id="emailaddress"
                                     placeholder="Email Address" (change)=emailvalidation() required>        
                                </div>
                            </div>
                        </div>                      
                        <div class="row">
                            <div class="col-md-12">
                                <div id="password-field" class="field-wrapper input">
                                    <div class="d-flex justify-content-between">
                                        <label for="password">PASSWORD<span class="text-danger" style="font-size:medium;">*</span></label>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg> 
                                    </div>
                                    <input type="password" class="form-control" formControlName="password"  id="Password" placeholder="Password" required>                      
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div id="confirmpassword-field" class="field-wrapper input mb-2">
                                    <div class="d-flex justify-content-between">
                                        <label for="confirmpassword">CONFIRM PASSWORD<span class="text-danger" style="font-size:medium;">*</span></label>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg> 
                                    </div>
                                    <input type="password" class="form-control" formControlName="cnfpassword"  id="cnfPassword" placeholder="Confirm Password" required>                      
                                </div>
                            </div>
                           
                        </div>
                        <div class="row">     
                            <div class="col-lg-12">                                 
                                   <div class="field-wrapper">                                                                   
                                       <button class="btn btn-primary" (click)="btnSubmit()">Submit</button>                                
                                </div>
                           
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                            <p class="signup-link text-center">Already have an account? <a href="client-login"> Sign In</a></p>
                        </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</div>


 