<app-customerservice-header></app-customerservice-header>
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
     <app-customerservice-sidebar></app-customerservice-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-md-12">           
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item ml-auto"><a href="/customerservice-dashboard">Dashboard</a></li>
                                <li class="breadcrumb-item active text-primary" aria-current="page"><span>{{htext}}</span></li>
                            </ol>        
                        </div>
                    </div>
                </div>             
                <div class="pt-1 col-xl-12 col-lg-12 col-md-12 col-sm-12">                            
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">                               
                                <div class="card-header bg-light text-primary">
                                    <div class="row">
                                        <div class="col-lg-6 pt-2">{{htext}}</div>                                       
                                    </div>                                     
                                </div>
                                <div class="card-body">
                                     <div class="row">
                                         <div class="col-md-12">
                                             <form [formGroup]="Customerservice" novalidate>
                                                 <div class="row pt-3">
                                                    <div class="col-md-2">
                                                        <label for="FirstName">FirstName </label> 
                                                        <span style="color: red;">*</span>
                                                    </div>
                                                    <div class="col-md-4">                                           
                                                         <input type="text" class="form-control"  
                                                              id="FirstName" autocomplete="off"   formControlName="FirstName"  required/>                                                   
                                                    </div>
                                                 </div>
                                                 <div class="row pt-3">
                                                    <div class="col-md-2">
                                                        <label for="LastName">LastName </label>
                                                        <span style="color: red;">*</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control"  
                                                         id="LastName"   formControlName="LastName" autocomplete="off" required/>                                                   
                                                    </div>                                            
                                                 </div>
                                                 <div class="row pt-3">
                                                    <div class="col-md-2">
                                                        <label for="MiddleName">MiddleName </label>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control"  autocomplete="off" id="MiddleName" formControlName="MiddleName" />                                                   
                                                    </div>                                            
                                                 </div>
                                                 <div class="row pt-3">
                                                    <div class="col-md-2">
                                                        <label for="DateOfBirth">DateOfBirth </label>
                                                        <span style="color: red;">*</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <input type="text" class="form-control" id="DateOfBirth" formControlName="DateOfBirth"  (change)="dobValidation()" autocomplete="off" bsDatepicker [bsConfig]="datePickerConfig" required/>                                                   
                                                    </div>                                            
                                                 </div>
                                                 <div class="row pt-3">
                                                     <div class="col-md-2">
                                                         <label for="SocialSecurityNo">SocialSecurityNo </label>
                                                         <span style="color: red;">*</span>
                                                        </div>
                                                     <div class="col-md-4">
                                                         <input type="text" autocomplete="off" class="form-control" id="SocialSecurityNo" formControlName="SocialSecurityNo" (change)="ssnValidation()" required/>                                                   
                                                        
                                                        </div> 
                                                     <div class="col-md-6">                                                        
                                                        <p>Social Security Number.Format should be XX-XXXXXXX</p>
                                                    </div>                                           
                                                 </div> 
                                                 <div class="row pt-3">
                                                     <div class="col-md-2">
                                                          <label for="EmailAddress">EmailAddress </label>
                                                          <span style="color: red;">*</span>
                                                        </div>
                                                     <div class="col-md-4">
                                                         <input type="text" autocomplete="off" class="form-control" id="EmailAddress" (change)="emailValidation()" formControlName="EmailAddress" required/>                                                   
                                                     </div>                                            
                                                 </div>

                                                 <div class="row pt-3">
                                                     <div class="col-md-2">
                                                         <label for="PhoneNo">PhoneNo </label>
                                                         <!-- <span style="color: red;">*</span> -->
                                                        </div>
                                                     <div class="col-md-4">
                                                         <input type="text" autocomplete="off" class="form-control" id="PhoneNo" formControlName="PhoneNo" (change)="phoneValidation()" />                                                   
                                                     </div>  
                                                     <div class="col-md-4">
                                                        <p>Must be at least 10 characters long, Format should be (XXX) XXX - XXXX or XXX-XXX-XXXX .</p>
                                                    </div>                                          
                                                 </div>                                           
                                                 <div class="row pt-3">
                                                     <div class="col-md-2"></div>
                                                     <div class="col-md-4">
                                                         <button type="button" class="btn btn-primary" (click)="btnSave()">{{btext}}</button>&nbsp;
                                                         <a href="customerservice-dashboard" class="btn btn-danger">Cancel</a>
                                                     </div>
                                                 </div>                                               
                                             </form>                                       
                                         </div>
                                     </div>                               
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>   
                <app-customerservice-footer></app-customerservice-footer>
   
        </div>
    </div>
</div>
   