import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public users: string;
  public firstName: string;
  public lastName:string;
  constructor(private loginservice:LoginService) { }

  ngOnInit(): void {debugger
    this.users = JSON.parse(localStorage.getItem('user'));
    if(this.users!=null){
       // this.firstName = this.users['firstName']==null?"":this.users['firstName'];
       // this.lastName = this.users['lastName']==null?"":this.users['lastName'];
        this.firstName = this.users['userName']==null?"":this.users['userName'];
    }else{
        this.firstName = "";
        this.lastName = "";
    }
    
  }

}
