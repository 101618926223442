<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-12">           
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                           <li class="breadcrumb-item active text-primary" aria-current="page"><span>{{htext}}</span></li>
                        </ol>        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-light">
                                <span class="text-secondary"><b>{{htext}}</b></span>
                            </div>
                            <div class="card-body" style="height: 70vh;">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="Challenges" novalidate>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="AccountType"> Account Type</label> </div>
                                                <div class="col-md-4">
                                                    <select formControlName="accountType" 
                                                    class="form-control" id="AccountType" required>                                                     
                                                <option value="">--Select AccountType--</option>
                                                <option value="1">Auto</option>
                                                <option value="2">Bankruptcy Remarks</option>
                                                <option value="3">Bankruptcy</option>
                                                <option value="4">ChargeOff</option>
                                                <option value="5">Child Support</option>
                                                <option value="6">Collections</option>
                                                <option value="7">CREDIT CARD</option>
                                                <option value="8">Customer Dispute</option>
                                                <option value="9">Education</option>
                                                <option value="10">General</option>
                                                <option value="11">Inquiry</option>
                                                <option value="12">Installment</option>
                                                <option value="13">Judgment</option>
                                                <option value="14">Medical </option>
                                                <option value="15">Military</option>
                                                <option value="16">Mortgage</option>
                                                <option value="17">Public Record</option>
                                                <option value="18">TaxLien</option>
                                                <option value="19">FedTaxLien</option>
                                                <option value="20">Utilities</option>
                                                <option value="21">Zero Balance</option>
                                                <option value="22">Fraud</option>
                                                <option value="23">Rent/Leasing</option>
                                                <option value="24">Address</option>
                                                <option value="25">Open Account</option>
                                                <option value="26">Education Deferment</option>
                                                <option value="27">Medical Zero Balance</option>
                                                <option value="28">Medical Outdated</option>
                                                <option value="29">HOME EQUITY</option>
                                                <option value="30">Credit Line Secured</option>
                                                <option value="31">Family Support</option>                                                </select>
                                            
                                                    
                                                </div>
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="ChallengeLevel">Challenge Level</label>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="number" class="form-control"  
                                                    id="ChallengeLevel"   formControlName="challengeLevel" maxlength="1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="ChallengeText">Challenge Text</label>
                                                </div>
                                                <div class="col-md-6">
                                                    <textarea type="text" class="form-control"  
                                                    id="ChallengeText" formControlName="challengeText" rows="8" cols="8" required></textarea>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2"></div>
                                                <div class="col-md-4">
                                                    <button type="button" class="btn btn-primary" (click)="btnSave()">{{btext}}</button>&nbsp;
                                                    <a href="admin-managechallenges" class="btn btn-danger">Cancel</a>

                                                </div>
                                            </div>

                                               
                                        </form>
                                       
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                </div>   
            </div>   
            <app-footer></app-footer>    
        </div>
    </div>

    <!--  END CONTENT AREA  -->
    </div>
</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>