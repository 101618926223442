import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-corp-resetpassword',
  templateUrl: './corp-resetpassword.component.html',
  styleUrls: ['./corp-resetpassword.component.css']
})
export class CorpResetpasswordComponent implements OnInit {
  public ResetPasswordForm:FormGroup;
  public id:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,
    private loginService: LoginService,public routing:Router) { 

     this.ResetPasswordForm=this.fb.group({
        Password:'',
        NewPassword:'',
        ConfirmPassword:''

      });
    }

  ngOnInit(): void {
    debugger
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
         if(user.userRole!="corpcustomer"){
        //this.routing.navigate(['admin']);
         }
         else
         {}
      }      

       this.id="";  
       this.id = user.clientId;   
  }
  changePassword(){
    debugger
     if(!this.ResetPasswordForm.invalid){
         //this.ResetPasswordForm.value.Password==""||
       if(
          this.ResetPasswordForm.value.NewPassword=="" ||
          this.ResetPasswordForm.value.ConfirmPassword=="")
          {
            Swal.fire('Warning','Please fill all the fields','warning');            
            return;
          }
         if(this.ResetPasswordForm.value.NewPassword!=this.ResetPasswordForm.value.ConfirmPassword)
         {
           Swal.fire('Warning','Confirm password not matched with NewPassword','warning');
           return;
         }
         else
         {
           let obj={
            key:"123",
            ClientId:this.id,
            UserPassword:this.ResetPasswordForm.value.NewPassword,
            UserRole: "corpcustomer"
           }
           this.loginService.ResetPassword(obj).subscribe(res=>{
             debugger
              if(res.status==1){
                debugger
                Swal.fire('Success','Password reset successfully','success');
                window.location.href='corp-dashboard';
                return;
              }
              else
              {
                Swal.fire('Warning','Password not reseted. ' + res.message,'warning');
                return;
              }

           });
         }
     }
     else
     {
       Swal.fire('Warning','Please fill the all the fields','warning');
       return;
     }
  }
  Cancel(){
    window.location.href='corp-dashboard'
  }
}
