import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  constructor(private fb:FormBuilder,private loginService: LoginService, private route: Router) { }

  loginForm = this.fb.group({
    username:'',
    password:''
  });

  ngOnInit(): void {
    this.loginService.logout();
  }

  login() { 
    if (!this.loginForm.invalid) {    
      let obj={
        key:"123",
        UserName:this.loginForm.value.username,
        UserPassword:this.loginForm.value.password
      }

      this.loginService.adminlogin(obj).subscribe(res =>{
      
        if (res.status == 1) {
         // this.loginService.logout();
         // res.data[0]['role'] = "admin";
          this.loginService.storeUserData(
            res.data.userID,
            res.data
          );
          //redirection
          window.location.href="dashboard/admin";
        } else {
          Swal.fire('Login Fail.', 'Please check username and password.', 'error');
        }
      }, error => {
        Swal.fire("Warning!","Error","warning");
      });
    } else {
      Swal.fire('Oops..', 'Please fill all fields.', 'error');
    }
  }

}
