<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
      <div class="layout-px-spacing">
         <div class="row layout-top-spacing">      
           <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
              <!-- <li class="breadcrumb-item active text-primary" aria-current="page"><span></span></li> -->
            </ol>
         </nav>
         <div class="card">
              <div class="card-header  bg-light"><b class="text-secondary">Admin Dashboard</b></div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table id="tblClient" class="table table-striped table-bordered">
                        <thead>
                          <tr>
                              <th>Client Name</th>                                       
                              <!-- <th>Created Date</th> -->
                              <th>Address</th>
                              <th>Email</th>
                              <th>Contact No</th>
                              <th>Status</th>
                              <th>Action</th>         
                          </tr>
                        </thead>
                          <tbody>
                              <tr *ngFor="let data of rowDataClient">
                                  <td>{{data.FullName}}</td>
                                  <!-- <td>{{data.logDate}}</td> -->                                 
                                  <td style="width:250px;">{{data.Address}}</td>                                
                                  <td>{{data.Email}}</td>
                                  <td>{{data.ContactNo}}</td>
                                  <td>
                                    <button title="click to active/inactive client"
                                  (click)="btnActive(data.ClientId,data.Status)"  class="{{data.chgColor}}" id="btnActive" 
                                   style="width: 100px;">{{data.Status}}</button>
                                  </td>
                                  <td>
                                  
                                   <a href="admin-creditfile?id={{data.ClientId}}" class="btn btn-primary fa fa-file" style="padding:.6rem 1.5rem;" ></a>

                                            
                                </td>
                                </tr>
                          </tbody>
                      </table>
                    </div>  
                  </div>
          </div>
           </div>   
           <app-footer></app-footer>    
      </div>
    </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
