
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import {AdminSidebarComponent} from './layout/sidebar/admin-sidebar/admin-sidebar.component';
import {AdminLoginComponent} from './login/admin-login/admin-login.component';
import { ClientLoginComponent } from './login/client-login/client-login.component';
import { SignUpComponent } from './client/signup/signup.component';
import { ClientProfileComponent } from './client/profile/profile.component';

import {AdminDashboardComponent} from './layout/dashboard/admin-dashboard/admin-dashboard.component';
import {AdminMasterComponent} from './admin/admin-master/admin-master.component';
import {StaffSidebarComponent} from './layout/sidebar/staff-sidebar/staff-sidebar.component';
import {StaffDashboardComponent} from './layout/dashboard/staff-dashboard/staff-dashboard.component';
import{StudentSidebarComponent} from './layout/sidebar/student-sidebar/student-sidebar.component';
import {StudentDashboardComponent} from './layout/dashboard/student-dashboard/student-dashboard.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { CreateStaffComponent } from './admin/create-staff/create-staff.component';
import { ManageStaffComponent } from './admin/manage-staff/manage-staff.component';
import { AdminStudentlistComponent } from './admin/admin-studentlist/admin-studentlist.component';
import { AdminEditstudentComponent } from './admin/admin-editstudent/admin-editstudent.component';
import { UploadStudentComponent } from './admin/upload-student/upload-student.component';
import { DataTablesModule } from 'angular-datatables';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {Ng2OrderModule} from 'ng2-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { MyLoaderComponent } from './components/my-loader/my-loader.component';
import { ClientDashboardComponent } from './layout/dashboard/client-dashboard/client-dashboard.component';
import { CreditItemsComponent } from './client/credit-items/credit-items.component';
import { ClientBillingComponent } from './client/client-billing/client-billing.component';
import { ManageBillingComponent } from './client/manage-billing/manage-billing.component';
import { ClientResetPasswordComponent } from './client/client-reset-password/client-reset-password.component';
import { ClientInfoComponent } from './client/client-info/client-info.component';
import { ManageAccounttypeComponent } from './admin/manage-accounttype/manage-accounttype.component';
import { CreateAccounttypeComponent } from './admin/create-accounttype/create-accounttype.component';
import {ClientChallengesComponent} from './client/client-challenges/client-challenges.component';
import {ChartsModule} from 'ng2-charts';
import { ServiceSettingsComponent } from './admin/service-settings/service-settings.component';
import {AdminAddchallengesComponent} from './admin/admin-addchallenges/admin-addchallenges.component';
import { AdminManagechallengesComponent } from './admin/admin-managechallenges/admin-managechallenges.component';
import { AdminAddpricingComponent } from './admin/admin-addpricing/admin-addpricing.component';
import { AdminManagepricingComponent } from './admin/admin-managepricing/admin-managepricing.component';
import { AdminCustomerserviceComponent } from './admin/admin-customerservice/admin-customerservice.component';
import { AdminManagecustomerserviceComponent } from './admin/admin-managecustomerservice/admin-managecustomerservice.component';

import { CustomerserviceLoginComponent } from './customerservice/customerservice-login/customerservice-login.component';
import { CustomerserviceDashboardComponent } from './customerservice/customerservice-dashboard/customerservice-dashboard.component';
import { CustomerserviceCreateComponent } from './customerservice/customerservice-create/customerservice-create.component';
import { CustomerserviceHeaderComponent } from './customerservice/customerservice-layout/customerservice-header/customerservice-header.component';
import { CustomerserviceFooterComponent } from './customerservice/customerservice-layout/customerservice-footer/customerservice-footer.component';
import { CustomerserviceSidebarComponent } from './customerservice/customerservice-layout/customerservice-sidebar/customerservice-sidebar.component';
import { CustomerserviceCreditfileComponent } from './customerservice/customerservice-creditfile/customerservice-creditfile.component';
import { AdminCreditfileComponent } from './admin/admin-creditfile/admin-creditfile.component';
import { AdminTraceComponent } from './admin/admin-trace/admin-trace.component';
import { AdminManagecorporatecustomerComponent } from './admin/admin-managecorporatecustomer/admin-managecorporatecustomer.component';
import { AdminAddcorporatecustomerComponent } from './admin/admin-addcorporatecustomer/admin-addcorporatecustomer.component';
import { AdminBillingreportComponent } from './admin/admin-billingreport/admin-billingreport.component';
//Corp Folder
import { CorpLoginComponent } from './login/corp-login/corp-login.component';
import { CorpDashboardComponent } from './layout/dashboard/corp-dashboard/corp-dashboard.component';
import { CorpSidebarComponent } from './layout/sidebar/corp-sidebar/corp-sidebar.component';
import { CorpCreditfileComponent } from './corp/corp-creditfile/corp-creditfile.component';
import { CorpMyprofileComponent } from './corp/corp-myprofile/corp-myprofile.component';
import { CorpResetpasswordComponent } from './corp/corp-resetpassword/corp-resetpassword.component';
import { CorpAddclientComponent } from './corp/corp-addclient/corp-addclient.component';
import { CorpUpdateclientComponent } from './corp/corp-updateclient/corp-updateclient.component';
import { AdminClientchallengesComponent } from './admin/admin-clientchallenges/admin-clientchallenges.component';
import { CorpClientchallengesComponent } from './corp/corp-clientchallenges/corp-clientchallenges.component';




@NgModule({
  declarations: [
    AppComponent,   
    HeaderComponent,
    FooterComponent, 
    AdminSidebarComponent,
    AdminLoginComponent,
    AdminDashboardComponent,
    AdminMasterComponent,
    StaffSidebarComponent,
    StaffDashboardComponent,
    ClientLoginComponent,
    StudentDashboardComponent,
    StudentSidebarComponent,
    CreateStaffComponent,
    ManageStaffComponent,
    AdminStudentlistComponent,
    AdminEditstudentComponent,
    UploadStudentComponent,
    MyLoaderComponent,
    SignUpComponent,
    ClientProfileComponent,
    ClientDashboardComponent,
    CreditItemsComponent,
    ClientBillingComponent,
    ManageBillingComponent,
    ClientResetPasswordComponent,
    ClientInfoComponent,
    ManageAccounttypeComponent,    
    CreateAccounttypeComponent,
    ClientChallengesComponent,
    ServiceSettingsComponent,
    AdminAddchallengesComponent,
    AdminManagechallengesComponent,
    AdminAddpricingComponent,
    AdminManagepricingComponent,
    AdminCustomerserviceComponent,
    AdminManagecustomerserviceComponent,    
    CustomerserviceLoginComponent, 
    CustomerserviceDashboardComponent, 
    CustomerserviceCreateComponent, CustomerserviceHeaderComponent, 
    CustomerserviceFooterComponent, CustomerserviceSidebarComponent, 
    CustomerserviceCreditfileComponent, AdminCreditfileComponent, 
    AdminTraceComponent, AdminManagecorporatecustomerComponent, 
    AdminAddcorporatecustomerComponent, AdminBillingreportComponent, 
    CorpLoginComponent, CorpDashboardComponent, CorpSidebarComponent,
    CorpCreditfileComponent,
    CorpMyprofileComponent,
    CorpResetpasswordComponent,
    CorpAddclientComponent,
    CorpUpdateclientComponent,
    AdminClientchallengesComponent,
    CorpClientchallengesComponent,
    
    
  ],
  imports: [
    ChartsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
   DataTablesModule,
   Ng2SearchPipeModule,
Ng2OrderModule,
NgxPaginationModule,
BrowserAnimationsModule,
BsDatepickerModule.forRoot(),
ModalModule.forRoot()

  ],
  bootstrap: [AppComponent],
  providers: [
    LoaderService,{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe],
})
export class AppModule { }
