import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLoginComponent } from '../../login/admin-login/admin-login.component';
import { LoginService } from '../../shared/login/login.service';
import { StaffService } from '../../shared/staff/staff.service';
import {Title} from "@angular/platform-browser";
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['./admin-master.component.css']
})

export class AdminMasterComponent implements OnInit {
  
  public rowDataClient:any=[];
  public id:string='';
  public text: string = 'Active';
  public  status:string='';

  constructor(private titleservice:Title,public loginService:LoginService,private route:Router,private staffservice:StaffService) {
    this.titleservice.setTitle("Admin Dashboard");
    this.getClientList();
    setTimeout(function(){
      $(function(){
         $("#tblClient").DataTable({
          "dom": "<'row'<'col-md-2'l>|<'col-md-7 btop'B>|<'col-md-3 text-right'f>>" +
            "<'row'<'col-md-6'><'col-md-6'>>" +
            "<'row'<'col-md-12't>>" +
            "<'row'<'col-md-4'i><'col-md-8 pull-left'p>>",
        "buttons": {
            buttons: [
                { extend: 'csv', className: 'btn' },
                { extend: 'excel', className: 'btn' },
                { extend: 'print', className: 'btn' }
            ]
          },          
           "oLanguage": {
             "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
             "sInfo": "Showing page _PAGE_ of _PAGES_",
             "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
             "sSearchPlaceholder": "Search...",
            "sLengthMenu": "Results :  _MENU_",
         },
         "order": [[ 0, "asc" ]],
         "stripeClasses": [],
         "lengthMenu": [7, 10, 20, 50],
         "pageLength": 7,
         drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
         });
      }); 
     },3000);
  }
    ngOnInit(): void {
    if (this.loginService.getToken()) {
      this.route.navigate(['dashboard/admin']);
    } else {
      this.route.navigate(['admin']);
    }

    let user=this.loginService.getUser();
    if(user.userRole!="admin"){
      this.route.navigate(['admin']);
    }
    else
    {
      this.id = user.userID;
    }  
     
  }
  getClientList() 
  {
       
    let obj={
      key:"123"
    }
       this.loginService.GetClients(obj).subscribe(res=>{
         debugger;
         if(res.status==1){
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].status=="True"){
              this.status="Active";                    
            }
            else
            {
              this.status="Inactive";     
             // $("#btnActive").addClass('btn btn-danger');  
              $("#btnActive").css('background-color','red');              
            }           

            let objLog={
              FullName:res.data[i].fullName,
              Address:res.data[i].address1+","+res.data[i].address2+","+res.data[i].city+","+res.data[i].state+","+res.data[i].zipCode,
              Email:res.data[i].currentEmail,
              ContactNo:res.data[i].currentPhone,
              ClientId:res.data[i].clientId,             
              Status:this.status,
              chgColor:res.data[i].status=="True"?'btn btn-success':'btn btn-danger'
  
              
            }
            
            this.rowDataClient.push(objLog);
        
          }

         }

       });        
  }

  btnActive(id,status){
  debugger  
     
  if(status=="Active"){
    status="0"; 
    if(id!="" && id !=null)  
        {
          let obj={
            key:"123",
            clientid:id,
            status:status
        }
        this.loginService.UpdateClientStatus(obj).subscribe(res=>{
          debugger
            if(res.status==1){   
                location.reload();
            }              
        });
      }      
   }
   else if(status=="Inactive"){
    status="1"; 
    if(id!="" && id !=null)   
        {
          let obj={
            key:"123",
            clientid:id,
            status:status
        }
        this.loginService.UpdateClientStatus(obj).subscribe(res=>{
          debugger
            if(res.status==1){   
             // this.getClientList();
              location.reload();
            }              
        });
      }  
   }
   //this.getClientList();   
  }
}