import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";
declare var $;

@Component({
  selector: 'app-customerservice-dashboard',
  templateUrl: './customerservice-dashboard.component.html',
  styleUrls: ['./customerservice-dashboard.component.css']
})
export class CustomerserviceDashboardComponent implements OnInit {
  public rowDataClients:any[]=[];
  public  status:string='';

  constructor(private loginService:LoginService,private titleservice:Title,private route:Router) {
    this.titleservice.setTitle("Manage Clients");
    this.getCustomerClientList();
    setTimeout(function(){
     $(function(){
        $("#tblClient").DataTable({
          dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
        
          buttons: {          
              buttons: [              
                  { extend: 'csv', className: 'btn' },
                  { extend: 'excel', className: 'btn' },
                  { extend: 'print', className: 'btn' }
              ]
          },
         
          "oLanguage": {
            "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
            "sInfo": "Showing page _PAGE_ of _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Search...",
           "sLengthMenu": "Results :  _MENU_",
        },
        "order": [[ 3, "desc" ]],
        "stripeClasses": [],
        "lengthMenu": [5, 10, 20, 50],
        "pageLength": 5,
        drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
        });
     });

    },3000);
   }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      debugger
      if(user.userID!=""){
        
      }
    else{
      this.route.navigate(['customerservice-login']);
    }

  }
}
  getCustomerClientList() 
  {
       
    let obj={
      key:"123"
    }
       this.loginService.GetCustomerServiceClients(obj).subscribe(res=>{
         debugger;
         if(res.status==1){
          for(let i=0;i<res.data.length;i++){
            if(res.data[i].status=="True"){
              this.status="Active";      
              $("#idStatus").css('color','red');    
            }
            else
            {
              this.status="Inactive";               
              $("#idStatus").css('color','red');              
            }           

            let objLog={
              FullName:res.data[i].fullName,
              Address:res.data[i].address1+","+res.data[i].address2+","+res.data[i].city+","+res.data[i].state+","+res.data[i].zipCode,
              Email:res.data[i].currentEmail,
              ContactNo:res.data[i].currentPhone,
              ClientId:res.data[i].clientId,  
              Status:this.status    
            }            
            this.rowDataClients.push(objLog);
        
          }

         }

       });        
  }
  btnActive(id,status){
  if(status=="Active"){
      status="0"; 
      if(id!="" && id !=null)  
          {
            let obj={
              key:"123",
              ClientId:id,                 
              mode:'inactive'
          }
          this.loginService.CreateCustomerClient(obj).subscribe(res=>{
            
              if(res.status==1){   
                  location.reload();
              }              
          });
        }      
     }
     else if(status=="Inactive"){  
      status="1"; 
      if(id!="" && id !=null)   
          {
            let obj={
              key:"123",
              Clientid:id,                    
              mode:'active'
          }
          this.loginService.CreateCustomerClient(obj).subscribe(res=>{
            
              if(res.status==1){   
               // this.getClientList();
                location.reload();
              }              
          });
        }  
     }
     //this.getClientList();   
    }
}
