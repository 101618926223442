import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';


@Component({
  selector: 'app-customerservice-login',
  templateUrl: './customerservice-login.component.html',
  styleUrls: ['./customerservice-login.component.css']
})
export class CustomerserviceLoginComponent implements OnInit {
  loginForm = this.fb.group({
    username:'',
    password:''
  });


  constructor(private fb:FormBuilder,private loginService: LoginService, private route: Router) { }

  ngOnInit(): void {
  }

  login() { debugger
    if (!this.loginForm.invalid) {   
      
      let obj={
        key:"123",
        UserName:this.loginForm.value.username,
        UserPassword:this.loginForm.value.password
      }
      this.loginService.CustomerServiceLogin(obj).subscribe(res=>{
    
        if (res.status == 1) {
          // this.loginService.logout();
            
            this.loginService.storeUserData(                         
              res.data.clientId,
              res.data             
            );
           //redirection
           if(res.status == 1)
           {
             window.location.href="customerservice-dashboard";
           }
           else
           {
             window.location.href="customerservice";
           }
           
         } else {
           Swal.fire('Login Fail.', 'Please check username and password.', 'error');
         }
       }, error => {
         Swal.fire("Warning!","Error","warning");
 
      });

    }
    else
    {
      Swal.fire('Warning','Please fill all the fields.','warning');
      return;
    }
  }
}
