import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corp-sidebar',
  templateUrl: './corp-sidebar.component.html',
  styleUrls: ['./corp-sidebar.component.css']
})
export class CorpSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
