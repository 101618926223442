import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-create-accounttype',
  templateUrl: './create-accounttype.component.html',
  styleUrls: ['./create-accounttype.component.css']
})
export class CreateAccounttypeComponent implements OnInit {
  public AccountTypes:FormGroup;
  public id:string='';
  public acctId:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 

      this.AccountTypes=this.fb.group({
        accountType:'',
        accountDetails:''
      });
    }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    debugger
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
        this.id="";  
        this.id = user.userID; 
        this.acctId = this.route.snapshot.queryParams["AccTypeId"];

        if(this.acctId!=""&& this.acctId!=null){
          let obj={
            key:"123",            
            id:this.acctId
          }
          this.loginService.GetAccountTypeById(obj).subscribe(res=>{
            debugger
           if(res.status==1){          
             this.AccountTypes=this.fb.group({
              accountType:res.data[0].accountType,
              accountDetails:res.data[0].accountTypeDetails,
               
             });
           }
         });
 
      }
    }
  }

  }

  btnSave(){  
      if(!this.AccountTypes.invalid){
         debugger
        if(this.acctId!="" && this.acctId!=undefined){
          let obj={
            key:"123",
            AccTypeId:parseInt(this.acctId),
            AccountType:this.AccountTypes.value.accountType,
            AccountTypeDetails:this.AccountTypes.value.accountDetails
          }
          this.loginService.UpdateAccountTypes(obj).subscribe(res=>{      
            if(res.status==1){
              window.location.href='manage-accounttype';
            }
            else
            {
              Swal.fire('Warning','AccountType not updated','warning');
              return;
            }
          });
         
        }
        else
        {
          let obj={
            key:"123",
            AccTypeId:0,
            AccountType:this.AccountTypes.value.accountType,
            AccountTypeDetails:this.AccountTypes.value.accountDetails
          }
          this.loginService.InsertAccountTypes(obj).subscribe(res=>{      
            if(res.status==1){
              $("#AccountType").val('');
              $("#AccountDetails").val('');
              Swal.fire('Success','AccountType saved successfully','success');
              return;
            }
            else
            {
              Swal.fire('Success','AccountType not saved','success');
              return;
            }
          });    
        }       
      }
      else
      {
        Swal.fire('warning','Please enter all the fields','warning');
        return;
      }
  }
}
