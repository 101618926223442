import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
import { removeData } from 'jquery';

@Component({
  selector: 'app-service-settings',
  templateUrl: './service-settings.component.html',
  styleUrls: ['./service-settings.component.css']
})
export class ServiceSettingsComponent implements OnInit {

   public serviceSettings:FormGroup;
   public id:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 

      this.serviceSettings=this.fb.group({
        SendGridAPIKey:'',
        FromEmail:'',
        AuthorizeAPILoginId:'',
        APITransactionKey:'',
        SecretKey:'',
        AuthorizeEnvironment:'',
        DaysMailLine1:'',
        DaysMailLine2:'',
        SevenDaysMailLine1:'',
        SevenDaysMailLine2:'',
        PaymentSuccessLine1:'',
        PaymentSuccessLine2:'',
        PaymentFailureLine1:'',
        PaymentFailureLine2:'',
        NextAttemptMailLine1:'',
        NextAttemptMailLine2:'',
        SecondPaymentFailureLine1:'',
        SecondPaymentFailureLine2:'',
        ChallengesPath:'',
        ChallengesPathResult:'',
        MailXStreamURL:'',
        MXUserId:'',
        MXPassword:'',
        MXTemplate:'',
        host:'',
        pdfKey:'',
        storageAccessKey:'',
        storageAccountName:'',
        storageContainer:'',
        EQADDRESS1:'',
        EQADDRESS2:'',
        EQADDRESS3:'',
        EXADDRESS1:'',
        EXADDRESS2:'',
        EXADDRESS3:'',
        TUADDRESS1:'',
        TUADDRESS2:'',
        TUADDRESS3:'',
        isPaypal:'',
        PayPalAPIUserName:'',
        PayPalAPIPassword :'',
        PayPalAPISignature :'',
        PayPalSandBoxServer:'',
        PayPalLiveServer :'',
        PayPalAPIVersion :'',
        PayPalPaymentMethod:'',
        PayPalEnvironment :''
      });
    }

  ngOnInit(): void {
    
    let user=this.loginService.getUser();
    if(user==null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
        this.id="";  
        this.id = user.userID; 
       }
       if(this.id!="" && this.id!=null){
         let obj={
           key:"123"
         }
         this.loginService.GetServiceSettings(obj).subscribe(res=>{          
          if(res.status==1)
          {   
            if (res.data.isPaypal == "True") {                   
              $("#isPaypalYes").prop('checked', true);                 
          }
          else {
              $("#isPaypalYes").prop('checked', false);
          }
          if (res.data.isPaypal == "False") {                   
              $("#isPaypalNo").prop('checked', true);                   
          }
          else {
              $("#isPaypalNo").prop('checked', false);
          }
              this.serviceSettings=this.fb.group({   
              SendGridAPIKey:res.data.sendGridAPIKey,
              FromEmail:res.data.fromemail,
              AuthorizeAPILoginId:res.data.authApiLoginId,
              APITransactionKey:res.data.apiTransactionKey,
              SecretKey:res.data.secretKey,
              AuthorizeEnvironment:res.data.authEnvironment,              
              DaysMailLine1:res.data.days14MailLine1,
              DaysMailLine2:res.data.days14MailLine2,
              SevenDaysMailLine1:res.data.days7MailLine1,
              SevenDaysMailLine2:res.data.days7MailLine2,
              PaymentSuccessLine1:res.data.paymentSuccessLine1,
              PaymentSuccessLine2:res.data.paymentSuccessLine2,
              PaymentFailureLine1:res.data.paymentFailureLine1,
              PaymentFailureLine2:res.data.paymentFailureLine2,
              NextAttemptMailLine1:res.data.nextAttemptMailLine1,
              NextAttemptMailLine2:res.data.nextAttemptMailLine2,
              SecondPaymentFailureLine1:res.data.secondPaymentFailureLine1,
              SecondPaymentFailureLine2:res.data.secondPaymentFailureLine2,
              ChallengesPath:res.data.challengesPath,
              ChallengesPathResult:res.data.challengesPathResult,
              MailXStreamURL:res.data.mailXStreamURL,
              MXUserId:res.data.mxUserid,
              MXPassword:res.data.mxPassword,
              MXTemplate:res.data.mxTemplate,
              host:res.data.host,
              pdfKey:res.data.pdfKey,
              storageAccessKey:res.data.storageAccessKey,
              storageAccountName:res.data.storageAccountName,
              storageContainer:res.data.storageContainer,
              EQADDRESS1:res.data.eqaddresS1,
              EQADDRESS2:res.data.eqaddresS2,
              EQADDRESS3:res.data.eqaddresS3,
              EXADDRESS1:res.data.exaddresS1,
              EXADDRESS2:res.data.exaddresS2,
              EXADDRESS3:res.data.exaddresS3,
              TUADDRESS1:res.data.tuaddresS1,
              TUADDRESS2:res.data.tuaddresS2,
              TUADDRESS3:res.data.tuaddresS3,
              isPaypal:res.data.isPaypal,
              PayPalAPIUserName:res.data.payPalAPIUserName,
              PayPalAPIPassword :res.data.payPalAPIPassword,
              PayPalAPISignature :res.data.payPalAPISignature,
              PayPalSandBoxServer:res.data.payPalSandBoxServer,
              PayPalLiveServer :res.data.payPalLiveServer,
              PayPalAPIVersion :res.data.payPalAPIVersion,
              PayPalPaymentMethod:res.data.payPalPaymentMethod,
              PayPalEnvironment :res.data.payPalEnvironment
             });            
          }

         });
       }
    }

  }

  btnSave(){
  
   if(!this.serviceSettings.invalid){
    
       if(this.id!="" && this.id !=null)
        {
         
          var radioValue = $("input[name='isPaypal']:checked").val();
          this.serviceSettings.value.isPaypal=radioValue;
         
          
          let obj={
            key:"123",          
            sendGridAPIKey:this.serviceSettings.value.SendGridAPIKey,
            fromemail:this.serviceSettings.value.FromEmail,
            authApiLoginId:this.serviceSettings.value.AuthorizeAPILoginId,
            apiTransactionKey:this.serviceSettings.value.APITransactionKey,
            secretKey:this.serviceSettings.value.SecretKey,
            authEnvironment:this.serviceSettings.value.AuthorizeEnvironment,
            days14MailLine1:this.serviceSettings.value.DaysMailLine1,
            days14MailLine2:this.serviceSettings.value.DaysMailLine2,
            days7MailLine1:this.serviceSettings.value.SevenDaysMailLine1,
            days7MailLine2:this.serviceSettings.value.SevenDaysMailLine2,
            paymentSuccessLine1:this.serviceSettings.value.PaymentSuccessLine1,
            paymentSuccessLine2:this.serviceSettings.value.PaymentSuccessLine2,
            paymentFailureLine1:this.serviceSettings.value.PaymentFailureLine1,
            paymentFailureLine2:this.serviceSettings.value.PaymentFailureLine2,
            nextAttemptMailLine1:this.serviceSettings.value.NextAttemptMailLine1,
            nextAttemptMailLine2:this.serviceSettings.value.NextAttemptMailLine2,
            secondPaymentFailureLine1:this.serviceSettings.value.SecondPaymentFailureLine1,
            secondPaymentFailureLine2:this.serviceSettings.value.SecondPaymentFailureLine2,
            challengesPath:this.serviceSettings.value.ChallengesPath,
            challengesPathResult:this.serviceSettings.value.ChallengesPathResult,
            mailXStreamURL:this.serviceSettings.value.MailXStreamURL,
            mxUserid:this.serviceSettings.value.MXUserId,
            mxPassword:this.serviceSettings.value.MXPassword,
            mxTemplate:this.serviceSettings.value.MXTemplate,
            host:this.serviceSettings.value.host,
            pdfKey:this.serviceSettings.value.pdfKey,
            storageAccessKey:this.serviceSettings.value.storageAccessKey,
            storageAccountName:this.serviceSettings.value.storageAccountName,
            storageContainer:this.serviceSettings.value.storageContainer,
            eqaddresS1:this.serviceSettings.value.EQADDRESS1,
            eqaddresS2:this.serviceSettings.value.EQADDRESS2,
            eqaddresS3:this.serviceSettings.value.EQADDRESS3,
            exaddresS1:this.serviceSettings.value.EXADDRESS1,
            exaddresS2:this.serviceSettings.value.EXADDRESS2,
            exaddresS3:this.serviceSettings.value.EXADDRESS3,
            tuaddresS1:this.serviceSettings.value.TUADDRESS1,
            tuaddresS2:this.serviceSettings.value.TUADDRESS2,
            tuaddresS3:this.serviceSettings.value.TUADDRESS3,
            isPaypal :this.serviceSettings.value.isPaypal,
            PayPalAPIUserName:this.serviceSettings.value.PayPalAPIUserName,
            PayPalAPIPassword :this.serviceSettings.value.PayPalAPIPassword,
            PayPalAPISignature :this.serviceSettings.value.PayPalAPISignature,
            PayPalSandBoxServer:this.serviceSettings.value.PayPalSandBoxServer,
            PayPalLiveServer :this.serviceSettings.value.PayPalLiveServer,
            PayPalAPIVersion :this.serviceSettings.value.PayPalAPIVersion,
            PayPalPaymentMethod:this.serviceSettings.value.PayPalPaymentMethod,
            PayPalEnvironment :this.serviceSettings.value.PayPalEnvironment,
          }
          this.loginService.CreateServiceSettings(obj).subscribe(res=>{  
           
             if(res.status==1)
             {
                Swal.fire('Success','Service Settings saved successfully','success');
                return;
             }
             else
             {
               Swal.fire('Warning','Service Settings not saved','warning');
               return;
             }
          });
        }
     }
     else{
        Swal.fire('Warning','All the fields are mandatory','warning');
        return;
     }
  }

}
