import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Constants } from '../constants';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  authToken: any;
  user: any;
  role: any;
  constructor(private http: HttpClient) { }

  Baseurl(){
    return Constants.BASE_URL;
  }

  clientlogin(userData):any{
    debugger
  
    // return this.http.post<any>(this.Baseurl()+Constants.CHECK_USER,userData,{headers: 
    //   {'Access-Control-Allow-Origin': '*' } });
    return this.http.post<any>(this.Baseurl()+Constants.CHECK_USER,userData);
    
  }
  CheckEmailExists(userData):any{
    return this.http.get<any>(this.Baseurl()+Constants.CHECK_EMAILEXISTS+"?key="+userData.key+"&email="+userData.email+"&id="+userData.id);
  }
  AddClient(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.ADD_CLIENT,userData);
  }
  UpdateClientById(formData):any{
   // console.log(formData);
    return this.http.post<any>(this.Baseurl()+Constants.UPDATE_CLIENTS,formData);
  }
  GetClientsById(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTS+"?key="+userData.key+"&clientid="+userData.clientid)
  }
  GetClients(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTS+"?key="+userData.key);
  }
  
  GetClientsByRefcode(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTS+"?key="+userData.key +"&refcode=" + userData.refcode);
  }
  GetBillingReport(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GetBillingReport+"?key="+userData.key +"&refcode=" + userData.refcode);
  }
  GetCreditReportData(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GetCreditReportData+"?key="+userData.key+"&clientid="+userData.clientid)
  }
  GetCreditItemDetails(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GetCreditItemDetails+"?key="+userData.key+"&clientid="+userData.clientid)
  }
  
  CreditPull(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.CreditPull+"?key="+userData.key+"&clientid="+userData.clientid)
  }
  adminlogin(userData): any {
    return this.http.post<any>(this.Baseurl()+Constants.ADMIN_LOGIN,userData);   
  }

  stafflogin(userData): any {
    return this.http.get<any>(this.Baseurl()+Constants.GET_STAFF+"?username="+userData.username+"&password="+userData.password);
  }

  studentlogin(userData): any {
    return this.http.get<any>(this.Baseurl()+Constants.GET_STUDENTS+"?username="+userData.username+"&password="+userData.password);
  }


  logout(): any {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  storeUserData(token: any, user: any): void {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));

    this.authToken = token;
    this.user = user;
  }

  loadToken() {
    this.authToken = localStorage.getItem('token');
  }
  
  getToken(): string {
    return localStorage.getItem('token');
  }
  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }
  AddUpdateBilling(BillingModel):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.ADDUPDATE_BILLINGDETAILS,BillingModel);
  }
  GetClientBillingById(BillingModel):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTBILLING+"?key="+BillingModel.key+"&clientid="+BillingModel.clientid+"&billingId="+BillingModel.billingId);
  }
  GetClientBilling(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTBILLING+"?key="+userData.key+"&clientid="+userData.clientid);
  }
  DeleteBillingDetails(BillingModel):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETE_BILLINGDETAILS,BillingModel);
  }
  ResetPassword(ResetPasswordFrom):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.RESET_PASSWORD,ResetPasswordFrom);
   }
   ConfirmUserAccount(userInfo):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.CONFIRM_USERACCOUNT,userInfo);
  }
  InsertAccountTypes(AccountTypes):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.INSERT_ACCOUNTTYPE,AccountTypes);
  }
  UpdateAccountTypes(AccountTypes):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.UPDATE_ACCOUNTTYPE,AccountTypes);
  }
  DeleteAccountTypes(AccountTypes):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETE_ACCOUNTTYPE,AccountTypes);
  }
  GetAccountTypes(AccountTypes):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_ACCOUNTTYPES+"?key="+AccountTypes.key);
  }
  GetAccountTypeById(AccountTypes):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_ACCOUNTTYPEBYID+"?key="+AccountTypes.key+"&id="+AccountTypes.id)
  }

  CreateChallengeAH(items):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.CreateChallengeAH,items);
  }
  CreateChallengeINQ(items):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.CreateChallengeINQ,items);
  }
  CreateChallengePR(items):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.CreateChallengePR,items);
  }
  GetClientChallengesById(AccountTypes):Observable<any>{
    debugger
    return this.http.get<any>(this.Baseurl()+Constants.GET_CLIENTCHALLENGES+"?key="+AccountTypes.key+"&id="+AccountTypes.id)
  }
  GetRefreshCreditPull(AccountTypes):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_REFRESHCREDITPULL+"?key="+AccountTypes.key+"&clientid="+AccountTypes.clientid+"&round="+AccountTypes.round)
  }
  GetChallengesByMonth(AccountTypes):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CHALLENGESBYMONTH+"?key="+AccountTypes.key+"&clientid="+AccountTypes.clientid)
  }
  GetServiceSettings(serviceSettings):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_SERVICESETTINGS+"?key="+serviceSettings.key);
  }
  CreateServiceSettings(serviceSettings):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.INSERT_IUSERVICESETTINGS,serviceSettings);
  }
  UpdateClientStatus(userData):any{
    // return this.http.post<any>(this.Baseurl()+Constants.UPDATE_ClientStatus+"?key="+userData.key+"&clientid="+userData.clientid+"&status="+userData.status,null);
    return this.http.post<any>(this.Baseurl()+Constants.UPDATE_ClientStatus,userData);
  }

  //Admin-Add Challenges
  InsertChallenges(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.INSERT_Challenge,userData);
  }   
  DeleteChallenges(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETE_Challenge,userData);
  }
  GetChallenges(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_Challenge+"?key="+userData.key);
  }
  GetChallengesById(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.EDIT_ChallengeById+"?key="+userData.key+"&id="+userData.id);
  }

//Admin-Add Pricings
  InsertPricings(userData):Observable<any>{
     return this.http.post<any>(this.Baseurl()+Constants.INSERT_Pricing,userData);
  }   
  DeletePricings(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETE_Pricing,userData);
  }
  GetPricings(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_Pricing+"?key="+userData.key);
  }
  GetPricingsById(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.EDIT_Pricing+"?key="+userData.key+"&id="+userData.id);
  }

  getCreditScore(userData):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CREDITSCORE+"?key="+userData.key+"&clientid="+userData.clientid);
  }

  getChallengeHistory(userData):any{
     return this.http.get<any>(this.Baseurl()+Constants.GET_CHALLENGEHISTORY+"?key="+userData.key+"&clientid="+userData.clientid);
  }
  
  getAccountHistory(userData):any{
     return this.http.get<any>(this.Baseurl()+Constants.GET_ACCOUNTHISTORY+"?key="+userData.key+"&clientid="+userData.clientid);
  }

  getCustomerServiceById(userData):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CUSTOMERSERVICE+"?key="+userData.key+"&id="+userData.id);
  }
  getCustomerService(userData):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CUSTOMERSERVICE+"?key="+userData.key);
  }

  InsertCustomerService(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.INSERT_CUSTOMERSERVICE,userData);
  }
  DeleteCustomerService(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETE_CUSTOMERSERVICE,userData);
  }
  
  CustomerServiceLogin(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.GET_LOGINCUSTOMERSERVICE,userData);
  }

  GetCustomerServiceClients(userData):any{
    return this.http.get<any>(this.Baseurl()+Constants.GET_CUSTOMERSERVICECLIENTS+"?key="+userData.key);
  }

  CreateCustomerClient(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.INSERT_CUSTOMERCLIENT,userData);
  }
  

  getPdf(file):any{
    return this.http.post<any>(this.Baseurl()+Constants.GET_PDF+"?filepath=" + file+"&key=123",{responseType: 'arraybuffer'});
    }
    //To view pdf
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
         var ascii = binaryString.charCodeAt(i);
         bytes[i] = ascii;
      }
      return bytes;
   }
   saveByteArray(file, byte) {
    var res = file.split(".");
    var filetype=res[1];
    var bytes = new Uint8Array(byte); // pass your byte response to this constructor
  
    var blob=null;
    if(filetype == "jpg" || filetype == "jpeg" || filetype == "png" || filetype == "bmp")
    {
       blob=new Blob([bytes], {type: "application/image"});// change resultByte to bytes
       var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download=file;
        link.click();
    }
    else
    {
       blob=new Blob([bytes], {type: "application/pdf"});// change resultByte to bytes
       var url = window.URL.createObjectURL(blob);
      window.open(url,'blank');
    }
  };


  GetIQTypes(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_IQTYPES+"?key="+userData.key);
  }

  //Trace
  GetTrace(userData):Observable<any>{
    return this.http.get<any>(this.Baseurl()+Constants.GET_TRACEALL+"?key="+userData.key);
  }
  DeleteTrace(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETETRACE,userData);
  }
  DeleteAllTrace(userData):Observable<any>{
    return this.http.post<any>(this.Baseurl()+Constants.DELETEALLTRACES+"?key="+userData.key,userData);
  }

  //Corporate Customers
 
 GetCorporateCustomers(userData):Observable<any>{
  return this.http.get<any>(this.Baseurl()+Constants.GetCorpCustomer+"?key="+userData.key);
}
InsertCorporateCustomer(userData):Observable<any>{
  return this.http.post<any>(this.Baseurl()+Constants.InsertCorpCustomer,userData);
}   
GetCorporateCustomersById(userData):Observable<any>{
  return this.http.get<any>(this.Baseurl()+Constants.GetCorpCustomerById+"?key="+userData.key+"&id="+userData.id);
}
UpdateCorpCustomerStatus(userData):Observable<any>{
  debugger
  return this.http.post<any>(this.Baseurl()+Constants.UpdateCorpCustomerstatus,userData);
}  

CorpLogin(userData): any {
  return this.http.post<any>(this.Baseurl()+Constants.CheckCorpCustomerUser,userData);   
 } 

 GetChallengesToAdmin(userData):any{
  return this.http.get<any>(this.Baseurl()+Constants.GetChallengesForAdmin+"?key="+userData.key+"&refcode="+userData.refcode);
}
 InsertLetterPrintAudit(userData):any{
   debugger
  return this.http.post<any>(this.Baseurl()+Constants.AddLetterPrintAudit,userData);
 }
}
