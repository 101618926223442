
<app-customerservice-header></app-customerservice-header>
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
     <app-customerservice-sidebar></app-customerservice-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->


    <div id="content" class="main-content">
        <div class="layout-px-spacing">
             <div class="row layout-top-spacing"> 
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="row">
            <div class="col-lg-12">
                <h5 class="text-secondary pt-2">Customer Service Dashboard</h5>
            </div>
        </div>
        
             </div>      
        
             <div class="pt-1 col-xl-12 col-lg-12 col-md-12 col-sm-12">                    
        <div class="row">
            <div class="col-md-12">
                <div class="card">                               
                     <div class="card-header bg-light text-primary">
                        <div class="row">
                            <div class="col-lg-6 pt-2">Manage Clients</div>
                                <div class="col-lg-6"><a href="/customerservice-client"    class="btn btn-primary pull-right">Create Clients</a>
                            </div>
                        </div>                                     
                     </div>
                     <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table id="tblClient" class="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Client Name</th>                                       
                                                <th>Address</th>
                                                <th>Email</th>
                                                <th>Contact No</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of rowDataClients">
                                                <td  style="width:150px;">{{data.FullName}}</td>
                                                <td style="width:200px;">{{data.Address}}</td>                                
                                                <td  style="width:100px;">{{data.Email}}</td>
                                                <td  style="width:130px;">{{data.ContactNo}}</td>
                                                <td  style="width:100px;" id="idStatus">
                                                    <button title="click to active/inactive client"
                                                    (click)="btnActive(data.ClientId,data.Status)" class="btn btn-success"
                                                     id="btnActive" style="width: 100px;">{{data.Status}}</button>
                                                </td>
                                                <td  style="width:300px;">
                                                    
                                                <a href="customerservice-client?id={{data.ClientId}}" class="btn btn-primary fa fa-edit" style="padding:.6rem 1.5rem;margin:.3rem;" title="Edit Client"></a>&nbsp;
                                                &nbsp;<a href="customerservice-creditfile?id={{data.ClientId}}" class="btn btn-primary fa fa-file" style="padding:.6rem 1.5rem;" title="Credit File"></a>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> 
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>   
             </div>   
             <app-customerservice-footer></app-customerservice-footer>
   
            </div>
        </div>
    </div>
               
            

    
        <!--  END CONTENT AREA  -->
    
    
 </div>

   
