import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
import { ParseError } from '@angular/compiler';


@Component({
  selector: 'app-client-challenges',
  templateUrl: './client-challenges.component.html',
  styleUrls: ['./client-challenges.component.css']
})
export class ClientChallengesComponent implements OnInit {

  public rowDataGrid:any=[];
  public rowDataInquires:any=[];
  public rowDataPR:any=[];
  public id:string='';
  public Name:string='';
  public noAccounts:string='';
  public noInquires:string='';
  public noPR:string='';

  
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { }

    ngOnInit(): void {
      let user=this.loginService.getUser();
      debugger
      if(user!=null||user!=undefined){
           if(user.userRole!="client"){
          //this.routing.navigate(['admin']);
           }
           else
           {}
        }      
  
         this.id="";  
         this.id = user.clientId; 
         this.Name=user.userName;  
         this.GetClientChallengesDetails(); 
       }
  ViewPDF(file)
  {debugger
    var base64EncodedPDF="";
    this.loginService.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.loginService.base64ToArrayBuffer(res.data);
          this.loginService.saveByteArray(file, sampleArr);
      }
    });
  }
     GetClientChallengesDetails(){
      if(this.id!=null && this.id!=""){    
        let obj={
          key:"123",
          id:this.id
        }
        this.loginService.GetClientChallengesById(obj).subscribe(res=>{  
            if(res.status==1){             
              if(res.data.length>0){       
                 for(let i=0;i<res.data.length;i++){
                   if(res.data[i].mode=="Account-")
                      {debugger
                         let objCChallenges={
                           Mode:res.data[i].mode,            
                           RoundType:res.data[i].roundType,
                           ClientId:res.data[i].clientId,
                           ClientName:res.data[i].clientName,
                           CAgency:res.data[i].cAgency+"-"+res.data[i].roundType,
                           filename:res.data[i].crFilename,
                           }
                            this.rowDataGrid.push(objCChallenges);
                   }
                }
              }
              else
              {
                   this.noAccounts="No AccountTypes";
              }              
           }
        });
      }
    }
  
}