import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-admin-addchallenges',
  templateUrl: './admin-addchallenges.component.html',
  styleUrls: ['./admin-addchallenges.component.css']
})
export class AdminAddchallengesComponent implements OnInit {
  public Challenges:FormGroup;
  public id:string='';
  public acctypeId:number;
  public CLevel:string='';
  public Cid:string='';
  public htext:string='';
  public btext:string='';
  
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
   {
    this.Challenges=this.fb.group({
      accountType:'',
      challengeLevel:'',
      challengeText:'',
      accTypeId:''
    });
  }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
        this.htext="Create Challenges";
        this.btext="Save";
        this.id="";  
        this.id = user.userID; 
        this.Cid = this.route.snapshot.queryParams["ChallengeId"];
        if(this.Cid!="" && this.Cid!=null){
               this.htext="Update Challenges";
               this.btext="Update";
          let obj={
            key:"123",            
            id:parseInt(this.Cid)
          }
          this.loginService.GetChallengesById(obj).subscribe(res=>{        
           if(res.status==1){       

             this.Challenges=this.fb.group({              
              challengeLevel:res.data.challengeLevel,
              challengeText:res.data.challengeText,
             // ChallengeId:res.data.challengeId,
              accountType:res.data.accTypeId
              
             });
             $("#AccountType").prop('disabled',true);
           }
         });
 
        }
       }    
    }
  }
 
 

  btnSave(){
  debugger
    if(!this.Challenges.invalid){
      this.acctypeId=parseInt(this.Challenges.value.accountType);
      this.CLevel=this.Challenges.value.challengeLevel;
      if(this.Cid!='' && this.Cid!=null && this.Cid!=undefined){
  
        let obj={
          key:"123",    
          AccTypeId:this.acctypeId,
          ChallengeLevel:this.CLevel.toString(),
          ChallengeText:this.Challenges.value.challengeText,
          status:"1",
          ChallengeId:parseInt(this.Cid)
        }
        this.loginService.InsertChallenges(obj).subscribe(res=>{  
               
          if(res.status==1){
            window.location.href='admin-managechallenges';
          }
          else
          {
            Swal.fire('Success','Challenge not saved','success');
            return;
          }
        });
      }
      else
      {
        let obj={
          key:"123",    
          AccTypeId:this.acctypeId,
          ChallengeLevel:this.CLevel.toString(),
          ChallengeText:this.Challenges.value.challengeText,
          Status:"1"
        }
        this.loginService.InsertChallenges(obj).subscribe(res=>{          
          if(res.status==1){
            $("#AccountType").val('');
            $("#ChallengeLevel").val('');
            $("#ChallengeText").val('');
            Swal.fire('Success','Challenge saved successfully','success');
            return;
          }
          else
          {
            Swal.fire('Success','Challenge not saved','success');
            return;
          }
        });
       
      }
   }
    else
    {
      Swal.fire('warning','Please enter all the fields','warning');
      return;
    }
     
  }
}
