<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="client-dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Credit Items</span></li>
                    </ol>
                </div>
            </div>
            <div class="card">
                <div class="card-header  bg-light"><b class="text-secondary">Credit Items</b></div>
                <div class="card-body">
                     <div class="row">
                        <div class="col-lg-4">
                            <strong> Round Status: </strong><label id="roundType"></label>
                        </div>
                         <div class="col-lg-4">
                             <strong>Pull Credit Date: </strong> <label id="dateReportPulls"></label>
                         </div> 
                         <div class="col-lg-4">
                            <strong># Negative Items: </strong> <label id="negativeItems"></label>
                        </div> 
                     </div>
                     <div class="row pt-4">
                        <div class="table-responsive">    
                          <div>
                               <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width:220px;"></th>
                                           <th style="background-color:#C0392B;color:white;width:250px;">Equifax</th>
                                           <th style="background-color:#9B59B6;color:white;width:250px;">Experian</th>
                                           <th  style="background-color:#3498DB;color:white;width:250px;">TransUnion</th>
                                        </tr>
                                    </thead>
                                  
                                    <tbody  id="creditScore">
                                        
                                    </tbody>
                                   </table>                               
                               </div>
                       </div>
                    </div>
                     <div class="row pt-4">
                         <div class="col-lg-6"><strong><h5>Account History</h5></strong></div>
                         <div class="col-lg-6 text-right">
                             <button class="btn btn-primary" (click)="CreateRefreshCredit()">Refresh Credit Report</button>&nbsp;&nbsp;
                             <button class="btn btn-primary" (click)="CreateChallenge()">Challenge Incorrect Items</button>
                         </div>
                     </div>
                     <div class="row pt-4">
                         <div class="table-responsive">    
                           <div>
                                <table class="table">
                                     <thead>
                                         <tr>
                                            <th style="width:250px;"></th>
                                            <th style="background-color:#C0392B;color:white;width:250px;">Equifax</th>
                                            <th style="background-color:#9B59B6;color:white;width:250px;">Experian</th>
                                            <th  style="background-color:#3498DB;color:white;width:250px;">TransUnion</th>
                                         </tr>
                                     </thead>
                                   
                                     <tbody  id="credititems" >
                                 
                                     </tbody>
                                    </table>                               
                                </div>
                        </div>
                     </div>

                     <div class="row pt-4">
                        <div class="col-lg-6"><strong><h5>Public Records</h5></strong></div>
                        <div class="col-lg-6 text-right">
                            <!-- <button hidden class="btn btn-primary" (click)="CreateChallengeForPR()">Challenge Incorrect Public Records</button> -->
                        </div>
                    </div>

                    <div class="row pt-4">
                        <div class="table-responsive">
                            <div >
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width:250px;"></th>
                                           <th style="background-color:#C0392B;color:white;width:250px;">Equifax</th>
                                           <th style="background-color:#9B59B6;color:white;width:250px;">Experian</th>
                                           <th  style="background-color:#3498DB;color:white;width:250px;">TransUnion</th>
                                        </tr>
                                    </thead>
                            <tbody  id="PublicRecords">
                                
                            </tbody>
                                </table>
                            </div>
                       </div>
                    </div>

                     <div class="row pt-4">
                        <div class="col-lg-6"><strong><h5>Inquiries</h5></strong></div>
                        <div class="col-lg-6 text-right">
                            <!-- <button hidden class="btn btn-primary" (click)="CreateChallengeForInquiries()">Challenge Incorrect Inquiries</button> -->
                        </div>
                    </div>

                    <div class="row pt-4">
                        <div class="table-responsive">
                            <div >
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width:250px;"></th>
                                           <th style="background-color:#C0392B;color:white;width:250px;">Equifax</th>
                                           <th style="background-color:#9B59B6;color:white;width:250px;">Experian</th>
                                           <th  style="background-color:#3498DB;color:white;width:250px;">TransUnion</th>
                                        </tr>
                                    </thead>
                            <tbody  id="inquiries">
                            </tbody>
                                </table>
                            </div>
                       </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-lg-12 text-right">
                            <button class="btn btn-primary" id="btnChallenge" (click)="CreateChallenge()">Challenge Incorrect Items</button>
                        </div>
                    </div>

                    


                </div>
        </div>
                
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>