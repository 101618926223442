import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
import { ParseError } from '@angular/compiler';

@Component({
  selector: 'app-client-billing',
  templateUrl: './client-billing.component.html',
  styleUrls: ['./client-billing.component.css']
})
export class ClientBillingComponent implements OnInit {

  public BillingModel:FormGroup;
  public rowDataBind:any=[];
  public years:string='';
  public id:string='';
  public billingId:any;
  public isprimary:boolean;


  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,
    private loginService: LoginService,public routing:Router) { 
     this.BillingModel=this.fb.group({
       CardType:'',
       CardNumber:'',
       expiryMonth:'',
       expiryYear:'',
       CVV:'',
       isPrimary:'',
  
     });

    }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
         if(user.userRole!="client"){
        //this.routing.navigate(['admin']);
         }
         else
         {}
      }      

       this.id="";  
       this.id = user.clientId;   
       this.billingId=this.route.snapshot.queryParams["billingId"];
      
       if(this.billingId!=""&& this.billingId!=null){
         let obj={
           key:"123",
           clientid:this.id,
           billingId:this.billingId
         }
         this.loginService.GetClientBillingById(obj).subscribe(res=>{
          if(res.status==1){          
           if(res.data[0].isPrimary==true)
           {
             $("#isPrimary").prop("checked",true);
           }
           else
           {
             $("#isPrimary").prop("checked", false);
           }
            this.BillingModel=this.fb.group({
               CardType:res.data[0].cardType,
               CardNumber:res.data[0].cardNumber,
               expiryMonth:res.data[0].expiryMonth,
               expiryYear:"20"+res.data[0].expiryYear,
               CVV:res.data[0].cvv,              
               isPrimary:res.data[0].isPrimary
            });
          }
        });

       }
      
   
  }
  // checkValue(e){
  //   debugger
  //       if(e.target.checked!=null){
  //         this.isprimary=e.target.checked;      
  //   }

  // }
 SaveBilling(){    
    if(!this.BillingModel.invalid)
    {
      if(this.BillingModel.value.expiryYear!=""){
          this.years=this.BillingModel.value.expiryYear.replace("20",'');

      }

      if ($('#isPrimary').is(':checked')){
          this.isprimary=true;
      }else
      {
        this.isprimary=false;
      }
     
      if(this.billingId!="" && this.billingId!=undefined)
      {
        let obj={
          key:"123", 
          ClientId:this.id,  
          BillingId:parseInt(this.billingId),    
          CardType:this.BillingModel.value.CardType,
          CardNumber:this.BillingModel.value.CardNumber,
          expiryMonth:this.BillingModel.value.expiryMonth,
          expiryYear:this.years,
          ExpiryDate:this.BillingModel.value.expiryMonth+"-"+this.years,
          CVV:this.BillingModel.value.CVV,
          isPrimary:this.isprimary
        }
        this.loginService.AddUpdateBilling(obj).subscribe(res=>{
          if(res.status==1){ debugger
              window.location.href='manage-billing';
          }
          else
          {
             Swal.fire('Warning','Payment method not updated.','warning');
             return;
          }
        });
      }
      else
      {
        let obj={
               key:"123", 
               ClientId:this.id,      
               CardType:this.BillingModel.value.CardType,
               CardNumber:this.BillingModel.value.CardNumber,
               expiryMonth:this.BillingModel.value.expiryMonth,
               expiryYear:this.years,
               ExpiryDate:this.BillingModel.value.expiryMonth+"-"+this.years,
               CVV:this.BillingModel.value.CVV,
               isPrimary:this.isprimary
        }                 
        this.loginService.AddUpdateBilling(obj).subscribe(res=>{
          if(res.status==1){    debugger               
              Swal.fire("Success","Payment method saved successfully.","success");
              return;
          }
          else
          {
               Swal.fire('Warning','Payment method not saved.','warning');
               return;
          }
        });
       
      }
    }   
    else{
      Swal.fire('Warning','Please fill all the fields.','warning');
      return;
    }
  }

  CancelBilling(){
        window.location.href='manage-billing';
  }
}
