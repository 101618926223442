import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
import { ParseError } from '@angular/compiler';

@Component({
  selector: 'app-client-reset-password',
  templateUrl: './client-reset-password.component.html',
  styleUrls: ['./client-reset-password.component.css']
})
export class ClientResetPasswordComponent implements OnInit {
  public ResetPasswordForm:FormGroup;
  public id:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,
    private loginService: LoginService,public routing:Router) { 

     this.ResetPasswordForm=this.fb.group({
        Password:'',
        NewPassword:'',
        ConfirmPassword:''

      });
    }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
         if(user.userRole!="client"){
        //this.routing.navigate(['admin']);
         }
         else
         {}
      }      

       this.id="";  
       this.id = user.clientId;   
  }
  changePassword(){
    debugger
     if(!this.ResetPasswordForm.invalid){
         
       if(this.ResetPasswordForm.value.Password==""||
          this.ResetPasswordForm.value.NewPassword=="" ||
          this.ResetPasswordForm.value.ConfirmPassword=="")
          {
            Swal.fire('Warning','Please fill all the fields','warning');            
            return;
          }
         if(this.ResetPasswordForm.value.NewPassword!=this.ResetPasswordForm.value.ConfirmPassword)
         {
           Swal.fire('Warning','Confirm password not matched with NewPassword','warning');
           return;
         }
         else
         {
           let obj={
            key:"123",
            ClientId:this.id,
            UserPassword:this.ResetPasswordForm.value.NewPassword
           }
           this.loginService.ResetPassword(obj).subscribe(res=>{
             debugger
              if(res.status==1){
                debugger
                Swal.fire('Success','Password reset successfully','success');
                window.location.href='client-dashboard';
                return;
              }
              else
              {
                Swal.fire('Warning','Password not reseted','warning');
                return;
              }

           });
         }
     }
     else
     {
       Swal.fire('Warning','Please fill the all the fields','warning');
       return;
     }
  }
  Cancel(){
    window.location.href='client-dashboard'
  }
}
