import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/login/login.service';
import {Title} from "@angular/platform-browser";

import { HttpClient } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-admin-trace',
  templateUrl: './admin-trace.component.html',
  styleUrls: ['./admin-trace.component.css']
})
export class AdminTraceComponent implements OnInit {
 public rowDataGrid:any=[];

  constructor(private titleservice:Title,
    private _httpClient: HttpClient,    
    private loginservice:LoginService,
    private route:Router) {
      this.titleservice.setTitle("Trace List");
      this.GetTraces();
      setTimeout(function(){
        $(function(){      
          $("#tblTrace").DataTable({   
            dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
            
            buttons: {          
                buttons: [              
                    { extend: 'csv', className: 'btn' },
                    { extend: 'excel', className: 'btn' },
                    { extend: 'print', className: 'btn' }
                ]
            },
           
            "oLanguage": {
              "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
              "sInfo": "Showing page _PAGE_ of _PAGES_",
              "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
              "sSearchPlaceholder": "Search...",
             "sLengthMenu": "Results :  _MENU_",
          },
          "order": [[ 3, "desc" ]],
          "stripeClasses": [],
          "lengthMenu": [5, 10, 20, 50],
          "pageLength": 5,
          drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
          });
       });
       },3000);
     }

  ngOnInit(): void {
    let user=this.loginservice.getUser();

    if(user!=null||user!=undefined){
      if(user.userRole !="admin"){
        this.route.navigate(['admin']);
      }
    }
    else if(user==null)
    {
      this.route.navigate(['admin']);
    }
   

  }

  GetTraces(){
    let obj={
      key:"123"
    }
    this.loginservice.GetTrace(obj).subscribe(res=>{
       if(res.status==1){
            for(let i=0;i<res.data.length;i++){
              let objLog={
                Exception:res.data[i].exception,
                Error:res.data[i].error,
                TraceDate:res.data[i].traceDate,
                TraceId:res.data[i].traceId,
              }
               this.rowDataGrid.push(objLog);
            }
        }
    });
  }
  Delete(Id){
    let obj2={
      key:"123",
      TraceId:Id
    }
    debugger
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
      if (result.value) {
        
            this.loginservice.DeleteTrace(obj2).subscribe(res=>{
              debugger
              if(res.status==1){                debugger
               
                Swal.fire({
                 icon: 'success',
                  title: 'Success',
                  text: 'Trace deleted successfully'
                }).then(function(){
                    location.href='admin-trace';
                });
              }else{
                Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Error in deleting Trace'
                });
              }
            });   
      }
      
    });
  }

  DeleteAll(){
    let obj3={
      key:"123"      
    }
    debugger
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
      if (result.value) {
        
            this.loginservice.DeleteAllTrace(obj3).subscribe(res=>{
              debugger
              if(res.status==1){        
                debugger               
                Swal.fire({
                 icon: 'success',
                  title: 'Success',
                  text: 'Trace deleted successfully'
                }).then(function(){
                    location.href='admin-trace';
                });
              }else{
                Swal.fire({
                  icon: 'warning',
                  title: 'Warning',
                  text: 'Error in deleting Trace'
                });
              }
            });   
      }
      
    });
  }
}
