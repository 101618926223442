import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
import { ParseError } from '@angular/compiler';
@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.css']
})
export class ClientInfoComponent implements OnInit {

  public msg:string='';
  public success:string='';
  public fail:string='';
  public confirm:string='';
  public elink:string='';
  public userInfo:FormGroup;

  
  constructor(private titleservice:Title,private route:ActivatedRoute,
    private loginService: LoginService,private fb: FormBuilder) {
      this.userInfo=this.fb.group({
        success:'',
        fail:'',
        elink:''
      });
     }

  ngOnInit(): void {
       debugger

      
    this.msg=this.route.snapshot.queryParams["mode"];
    this.elink=this.route.snapshot.queryParams["email"];
    if(this.msg!="")
    {
          if(this.msg=="success")
          {
            $("#lblConfirm").hide();
            
            this.success="Thank you for registering. We send verification link to your mail.";
 
          }
          else if(this.msg=="fail"){
            $("#lblConfirm").hide();
            this.fail="Your registration failed. please try again later";
          }
          else if(this.msg=="confirm"){            
            let obj={
                 key:"123",
                 Email:this.elink,
                 }
                 this.loginService.ConfirmUserAccount(obj).subscribe(res=>{
                   debugger
                   if(res.status==1){
                    $("#lblConfirm").show();
                   }
                   else
                   {
                    $("#lblConfirm").show();

                   }
               })    

          }  
    }
     
  }

}
