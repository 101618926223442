<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-corp-sidebar></app-corp-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="/corp-dashboard">Dashboard</a></li>
                    </ol>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right mb-3">
                 <a href="corp-addclient" class="btn btn-primary"><i class="fa fa-plus"></i>&nbsp; Add New Client</a>&nbsp; 
                </div>
              </div>
             
            <div class="card">
                <div class="card-header  bg-light"><b class="text-secondary">Corporate Customer Dashboard</b></div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="tblDashboard" class="table  table-bordered">
                          <thead>
                            <tr>                           
                              <th>Client Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Status</th>  
                              <th>Action</th>        
                            </tr>
                          </thead>
                           <tbody>
                           <tr *ngFor="let data of rowDataGrid">
                             <td>{{data.Fullname}}</td>                           
                             <td>{{data.Email}}</td>
                             <td>{{data.PhoneNumber}}</td>                            
                             <td> <button title="click to active/inactive client"
                                (click)="UpdateStatus(data.RowId,data.Status)" class="{{data.chgColor}}" id="btnActive" 
                                 style="width: 100px;">{{data.Status}}</button>
                               </td>      
                               <td>     
                                <a href="corp-updateclient?id={{data.RowId}}" title="Edit Client" class="btn btn-info fa fa-edit" style="padding:.6rem 1.5rem;" ></a>
                             &nbsp;&nbsp;                             
                                <a href="corp-creditfile?id={{data.RowId}}" title="View Credit File" class="btn btn-primary fa fa-file" style="padding:.6rem 1.5rem;" ></a>&nbsp;&nbsp;
                                       
                             </td>                      
                             </tr>                 
                          </tbody>
                        </table>
                      </div>  
                        
                </div>
            </div>                
            </div>
        </div>
    </div>
    <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->
