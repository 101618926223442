import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-admin-customerservice',
  templateUrl: './admin-customerservice.component.html',
  styleUrls: ['./admin-customerservice.component.css']
})
export class AdminCustomerserviceComponent implements OnInit {
  public CustomerService:FormGroup;
  public id:string='';
  public CSId:string='';
  public htext:string='';
  public btext:any='';

  constructor(private titleservice:Title,private route:ActivatedRoute,
    private fb: FormBuilder,private loginService: LoginService,public routing:Router) 
    {
     this.CustomerService=this.fb.group({
      FirstName:'',
      LastName:'',
      MiddleName:'',
      PhoneNumber:'',
      Email:''
     });
  }
  ngOnInit(): void {
    
    debugger
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
        this.htext="Create User";
        this.btext="Save";
        this.id="";  
        this.id = user.userID; 
        this.CSId = this.route.snapshot.queryParams["RowId"];
        if(this.CSId!='' && this.CSId!=null){
               this.htext="Update User";
               this.btext="Update";
          let obj={
            key:"123",            
            id:parseInt(this.CSId)
          }
          this.loginService.getCustomerServiceById(obj).subscribe(res=>{     
            debugger   
           if(res.status==1){       
            $("#Email").prop("disabled",true);
             this.CustomerService=this.fb.group({              
              FirstName:res.data[0].firstName,
              LastName:res.data[0].lastName,
              MiddleName:res.data[0].middleName,
              PhoneNumber:res.data[0].phoneNumber,
              Email:res.data[0].email,
              RowId:res.data[0].rowId
              
             });
             
           }
         });
 
        }
       }
    }
  }
  btnSave(){

    if(!this.CustomerService.invalid)
    { 
      if(this.CustomerService.value.Email!=""){
        var email=this.CustomerService.value.Email;
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!regex.test(email)){
          
          Swal.fire("Warning!","Please enter valid email.","warning");
          return false;
         }
      }

      if(this.CustomerService.value.PhoneNumber!=""){
        var phone=this.CustomerService.value.PhoneNumber;
        let regex = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
        if(!regex.test(phone)){
          
          Swal.fire("Warning!","Please enter  valid phonenumber.","warning");
          return false;
         }
      }


      if(this.CSId!="" && this.CSId!=null && this.CSId!=undefined)
      {
        let obj={
          key:"123",    
          FirstName:this.CustomerService.value.FirstName,
          LastName:this.CustomerService.value.LastName,
          MiddleName:this.CustomerService.value.MiddleName,
          PhoneNumber:this.CustomerService.value.PhoneNumber,
          Email: this.CustomerService.value.Email,         
          RowId:this.CSId
        }
        this.loginService.InsertCustomerService(obj).subscribe(res=>{  
          debugger
          if(res.status==1){  
            debugger        
              window.location.href='admin-managecustomerservice';
            }
            else
            {
              Swal.fire('Warning','User not updated','warning');
              return;
            }
        });
      }
      else
      {
        let objs={
          key:"123",
          email:this.CustomerService.value.Email
        }
        this.loginService.CheckEmailExists(objs).subscribe(res=>{
          if(res.status==1){
            Swal.fire('Warning','Email already exists.','warning');
            $("#Email").val('');
            $("#Email").focus();
            return false;
          }
          else
          {
            let obj={
              key:"123",    
              FirstName:this.CustomerService.value.FirstName,
              LastName:this.CustomerService.value.LastName,
              MiddleName:this.CustomerService.value.MiddleName,
              PhoneNumber:this.CustomerService.value.PhoneNumber,
              Email: this.CustomerService.value.Email,  
              CreatedBy:this.id.toString(),      
            
            }
            this.loginService.InsertCustomerService(obj).subscribe(res=>{  
              debugger
              if(res.status==1){          
                window.location.href='admin-managecustomerservice';
              }
              else
              {
                Swal.fire('Warning','User not saved','warning');
                return;
              }
            });
          }
        })
        
      }      
    }
    else
    {
       Swal.fire("Warning","Please fill all mandatroy fields","warning");
       return;
    }
  }

}
