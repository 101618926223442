<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-corp-sidebar></app-corp-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-12">           
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item ml-auto"><a href="corp-dashboard">Dashboard</a></li>
                           <li class="breadcrumb-item active text-primary" aria-current="page"><span>{{htext}}</span></li>
                        </ol>        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-light">
                                <span class="text-secondary"><b>{{htext}}</b></span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="CorporateCustomer"  autocomplete="off">
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">First Name  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Firstname"   formControlName="FirstName" maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Lastname">Last Name  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Lastname"   formControlName="LastName" maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="RefCode">Referral Code  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="RefCode"   formControlName="RefCode" maxlength="6"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="BillingEmailAddress">Email Address  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control" autocomplete="off" 
                                                    id="BillingEmailAddress"  
                                                     formControlName="BillingEmailAddress" (change)="emailValidation()" maxlength="50"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="PhoneNumber1">PhoneNumber1</label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="PhoneNumber1"   formControlName="PhoneNumber1"
                                                    (change)="phoneValidation()"  maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="PhoneNumber2">PhoneNumber2</label> 
                                                    
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="PhoneNumber2"   
                                                    formControlName="PhoneNumber2" (change)="phoneValidation()" maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">Address1 </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Address1"   formControlName="Address1" maxlength="100"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">Address2  </label> 
                                                    
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Address2"   formControlName="Address2" maxlength="100"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">City </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="City"   formControlName="City" maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">State  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <select class="form-control" formControlName="State"  required>
                                                        <option value="">--Select State--</option>
                                                        <option value="Alabama">Alabama</option>
                                                        <option value="Alaska">Alaska</option>
                                                        <option value="Arkansas">Arkansas</option>
                                                        <option value="Arizona">Arizona</option>
                                                        <option value="California">California</option>
                                                        <option value="Connecticut">Connecticut</option>
                                                        <option value="Colorado">Colorado</option>
                                                        <option value="Delaware">Delaware</option> 
                                                        <option value="Dist Columbia">Dist. Columbia</option>
                                                        <option value="Florida">Florida</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Hawaii">Hawaii</option>                                                
                                                        <option value="Idaho">Idaho</option>
                                                        <option value="Iowa">Iowa</option>
                                                        <option value="Illinois">Illinois</option>
                                                        <option value="Indiana">Indiana</option>
                                                        <option value="Kansas">Kansas</option>
                                                        <option value="Kentucky">Kentucky</option>
                                                        <option value="Louisiana">Louisiana</option>
                                                        <option value="Massachusetts">Massachusetts</option>
                                                        <option value="Maine">Maine</option>
                                                        <option value="Mississippi">Mississippi</option>
                                                        <option value="Michigan">Michigan</option>
                                                        <option value="Missouri">Missouri</option>
                                                        <option value="Maryland">Maryland</option>
                                                        <option value="Minnesota">Minnesota</option>
                                                        <option value="Montana">Montana</option>
                                                        <option value="Nebraska">Nebraska</option>
                                                        <option value="New Jersey">New Jersey</option>
                                                        <option value="New Mexico">New Mexico</option>
                                                        <option value="North Carolina">North Carolina</option>                                               
                                                        <option value="North Dakota">North Dakota</option>
                                                        <option value="New Hampshire">New Hampshire</option>
                                                        <option value="New York">New York</option>
                                                        <option value="Ohio">Ohio</option>
                                                        <option value="Oregon">Oregon</option>                                               
                                                        <option value="Oklahoma">Oklahoma</option>
                                                        <option value="Pennsylvania">Pennsylvania</option>
                                                        <option value="Rhode Island">Rhode Island</option>
                                                        <option value="South Carolina">South Carolina</option>
                                                        <option value="South Dakota">South Dakota</option>
                                                        <option value="Tennessee">Tennessee</option>
                                                        <option value="Texas">Texas</option>                                                                                                                                          <option value="Nevada">Nevada</option>
                                                        <option value="Vermont">Vermont</option>
                                                        <option value="Virginia">Virginia</option>                                     
                                                        <option value="Utah">Utah</option>
                                                        <option value="Washington">Washington</option>
                                                        <option value="West Virginia">West Virginia</option>
                                                        <option value="Wisconsin">Wisconsin</option>                                            
                                                        <option value="Wyoming">Wyoming</option>                                             
        
                                                    </select> 
                                                </div>
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">ZipCode </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Zipcode"   formControlName="Zipcode"
                                                    (change)="numberValidation()"  maxlength="10"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                           




                                            <div class="row pt-3">
                                                <div class="col-md-2"></div>
                                                <div class="col-md-4">
                                                    <button type="button" class="btn btn-primary" (click)="btnSave()">{{btext}}</button>&nbsp;
                                                    <a href="corp-dashboard" class="btn btn-danger">Cancel</a>

                                                </div>
                                            </div>

                                               
                                        </form>
                                       
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                </div>   
            </div>   
            <app-footer></app-footer>    
        </div>
    </div>

    <!--  END CONTENT AREA  -->
    </div>
</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>