import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-admin-addcorporatecustomer',
  templateUrl: './admin-addcorporatecustomer.component.html',
  styleUrls: ['./admin-addcorporatecustomer.component.css']
})
export class AdminAddcorporatecustomerComponent implements OnInit {
  public CorporateCustomer:FormGroup;
  public id:string='';
  public htext:string='';
  public btext:string='';
  public RId:string='';
  public strEmail:string='';
  
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
   {
    this.CorporateCustomer=this.fb.group({    
      FirstName:'',
      LastName:'',
      RefCode:'',
      BillingEmailAddress:'',
      PhoneNumber1:'',
      PhoneNumber2:'',
      Address1:'',
      Address2:'',
      City:'',
      State:'',
      Zipcode:'',
    });
  }

  ngOnInit(): void {
    debugger
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
          this.htext="Create Corporate Customer";
          this.btext="Save";        
          this.id = user.userID; 
          this.RId = this.route.snapshot.queryParams["Rid"];
          if(this.RId!="" && this.RId!=null){
               this.htext="Update Corporate Customer";
               this.btext="Update";
               let obj={
                     key:"123",            
                     id:this.RId.toString()
                  }
                  this.loginService.GetCorporateCustomersById(obj).subscribe(res=>{        
                  if(res.status==1){       
                    if(res.data[0].billingEmailAddress!=""){
                     $("#BillingEmailAddress").prop('readonly',true); 
                     $("#RefCode").prop('readonly',true);
                    }
                     debugger
                     this.CorporateCustomer=this.fb.group({              
                          FirstName:res.data[0].firstName,
                          LastName:res.data[0].lastName,
                          RefCode:res.data[0].refCode,
                          BillingEmailAddress:res.data[0].billingEmailAddress,
                          PhoneNumber1:res.data[0].phoneNumber1,
                          PhoneNumber2:res.data[0].phoneNumber2,
                          Address1:res.data[0].address1,
                          Address2:res.data[0].address2,
                          City:res.data[0].city,
                          State:res.data[0].state,
                          Zipcode:res.data[0].zipcode
                        });    
            
                      }
                  });

            }

        }    
    }
  }
 
  emailValidation(){
    var emails=this.CorporateCustomer.value.BillingEmailAddress;
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if( !emailReg.test( emails ) ) {
      Swal.fire('Warning','Please enter email correctly.','warning');
      $("#BillingEmailAddress").val('');
      $("#BillingEmailAddress").focus();
        return false;
    } 
    else if(emails==""){
      Swal.fire('Warning','Please enter email address.','warning');
      return false;
    }
  }
  phoneValidation(){ 
    var phone=this.CorporateCustomer.value.PhoneNumber1;
          var phoneReg =/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
          if(!phoneReg.test(phone)) {
          Swal.fire('Warning','Please enter valid phone number.','warning');
          $("#PhoneNumber1").val('');
          $("#PhoneNumber1").focus();  
          $("#PhoneNumber2").val('');
          $("#PhoneNumber2").focus();  
          return false;
          }
     }
  
     numberValidation(){ 
      var num=this.CorporateCustomer.value.Zipcode;
            var numReg =/^\d*[0-9](|.\d*[0-9]|,\d*[0-9])?$/;
            if(!numReg.test(num)) {
            Swal.fire('Warning','Please enter numbers only.','warning');
            $("#Zipcode").val('');
            $("#Zipcode").focus();  
            return false;
            }
       }
    
  
  btnSave(){
  debugger
  if(this.CorporateCustomer.value.FirstName!="" && 
  this.CorporateCustomer.value.LastName!="" &&
  this.CorporateCustomer.value.RefCode!="" &&
  this.CorporateCustomer.value.BillingEmailAddress!="" &&
  this.CorporateCustomer.value.PhoneNumber1!="" &&
  this.CorporateCustomer.value.Address1!="" &&
  this.CorporateCustomer.value.City!="" &&
  this.CorporateCustomer.value.State!="" &&
  this.CorporateCustomer.value.Zipcode!="" ){

     
      if(this.RId!='' && this.RId!=null && this.RId!=undefined){      
          let obj={
              key:"123",
              FirstName:this.CorporateCustomer.value.FirstName,
              LastName:this.CorporateCustomer.value.LastName,
              RefCode:this.CorporateCustomer.value.RefCode,
              BillingEmailAddress:this.CorporateCustomer.value.BillingEmailAddress,
              PhoneNumber1:this.CorporateCustomer.value.PhoneNumber1,
              PhoneNumber2:this.CorporateCustomer.value.PhoneNumber2,
              Address1:this.CorporateCustomer.value.Address1,
              Address2:this.CorporateCustomer.value.Address2,
              City:this.CorporateCustomer.value.City,
              State:this.CorporateCustomer.value.State,
              Zipcode:this.CorporateCustomer.value.Zipcode,
              Status:"1",
              UpdatedBy:this.id.toString(),
              RowId:this.RId.toString()
            }
            this.loginService.InsertCorporateCustomer(obj).subscribe(res=>{   
            if(res.status==1){
                 window.location.href='admin-corporatecustomer';
            }
            else
            {
               Swal.fire('Warning','Corporate Customer not saved','warning');
               return;
            }
         });
      }
      else
      {
            let obj={
                key:"123",
                FirstName:this.CorporateCustomer.value.FirstName,
                LastName:this.CorporateCustomer.value.LastName,
                RefCode:this.CorporateCustomer.value.RefCode,
                BillingEmailAddress:this.CorporateCustomer.value.BillingEmailAddress,
                PhoneNumber1:this.CorporateCustomer.value.PhoneNumber1,
                PhoneNumber2:this.CorporateCustomer.value.PhoneNumber2,
                Address1:this.CorporateCustomer.value.Address1,
                Address2:this.CorporateCustomer.value.Address2,
                City:this.CorporateCustomer.value.City,
                State:this.CorporateCustomer.value.State,
                Zipcode:this.CorporateCustomer.value.Zipcode,
                Status:"1",
                CreatedBy:this.id.toString(),
                }
                this.loginService.InsertCorporateCustomer(obj).subscribe(res=>{         
                     if(res.status==1){                
                        Swal.fire({
                        icon: 'success',
                        title:'Success',
                        text: 'Corporate Customer saved successfully'
                         }).then(function(){
                          window.location.href='admin-corporatecustomer';
                      });
                   }      
                   else
                   {

                    Swal.fire('Error',res.message);
                      return;
                   }
          });
  
        }
      }
      else
      {
         Swal.fire('warning','Please enter all the fields','warning');
         return;
      }
  }
}
