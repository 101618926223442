export class Constants {
      //Base Url
 //public static BASE_URL = 'https://api.reversemycredit.com/api/';
   public static BASE_URL = 'https://reversemycreditapi.plexif.com/api/';
  // public static BASE_URL='http://localhost:55184/api/';

//Client Methods
public static CHECK_USER='CheckClient';
public static ADD_CLIENT='addclient'; 
public static CHECK_EMAILEXISTS='CheckEmailExists';
public static UPDATE_CLIENTS='UpdateClient';
public static GET_CLIENTS='getclients';
public static GetBillingReport='GetBillingReport';
public static GetCreditItemDetails='GetCreditReportDetails';
public static CreditPull='creditpull';
public static GetCreditReportData='GetCreditReportData';
public static GET_CLIENTBILLING='GetClientBillingDetails';
public static ADDUPDATE_BILLINGDETAILS='AddUpdateBillingDetails';
public static DELETE_BILLINGDETAILS='DeleteBillingDetails';
public static RESET_PASSWORD='ResetPassword';
public static CONFIRM_USERACCOUNT='ConfirmUserAccount';
public static GET_CHALLENGESBYMONTH='GetChallengesByMonth';
public static GET_SERVICESETTINGS='GetServiceSettings';
public static INSERT_IUSERVICESETTINGS='IUServiceSettings';
public static GetChallengesForAdmin='GetChallenges';
public static AddLetterPrintAudit='AddLetterPrintAudit';


 
public static INSERT_ACCOUNTTYPE='InsertAccountType';
public static UPDATE_ACCOUNTTYPE='UpdateAccountType';
public static DELETE_ACCOUNTTYPE='DeleteAccountType';
public static GET_ACCOUNTTYPES='GetAccountTypes';
public static GET_ACCOUNTTYPEBYID='GetAccountTypes';
public static CreateChallengeAH='CreateChallengeAH';
public static CreateChallengeINQ='CreateChallengeINQ';
public static CreateChallengePR='CreateChallengePR';
public static GET_CLIENTCHALLENGES='GetChallenges';
public static GET_REFRESHCREDITPULL='RefreshCreditPull';
public static GET_IQTYPES='GetIQTypes';
public static UPDATE_ClientStatus='UpdateClientStatus';

//Corporate Customers
public static GetCorpCustomer='GetCorpCustomer';
public static InsertCorpCustomer='AddCorpCustomer';
public static GetCorpCustomerById='GetCorpCustomer';
public static UpdateCorpCustomerstatus='UpdateCorpCustStatus';


public static KEY='123';

  //Staff Methods
  public static STAFF_ADD = 'AddStaff';
  public static GET_STAFF='GetStaff';
  public static UPDATE_STAFF='UpdateStaff';
  public static DELETE_STAFF='DeleteStaff';

  //Admin Login
  public static ADMIN_LOGIN = 'CheckAdmin'; 

  //student login
  public static GET_STUDENTS = 'GetStudents';

  //File Uploads
  public static UPLOAD_FILE='UploadFile';
  public static UPLOAD_FILES='UploadFiles';

  
  public static UPDATE_STUDENTS='UpdateStudent';
  public static DELETE_STUDENTS="DeleteStudent";

  public static UPLOAD_STUDENT='UploadStudents';
  public static GET_FORMS = 'getforms';
  public static GET_PDF = 'getpdf';
  //Resend Mail
public static RESEND_EMAIL='ResendMail';
public static UPDATE_PASSWORD='UpdateStaff';

//Student
public static GET_TERMS='GetAcademicTerms';
public static UPDATE_STUDENT='UpdateStudent';

public static SAVE_REQUESTDOCUEMNT='RequestDocuments';
public static SAVE_ACCEPTDOCUEMNT='AcceptDocuments';

public static SAVE_SUBMITDOCUEMNT='SubmitDocuments';
//create Student
public static SUBMIT_FORM='SubmitForm';
//ParentSubmit
public static PARENT_SUBMIT='SubmitFormByParent';
public static SUBMIT_REJECT='RejectForm';
public static GET_TIMESHEET='GetStudentTimesheets';

//ADD CHALLENGES
public static INSERT_Challenge='AddChallengeMaster';
public static EDIT_ChallengeById='EditChallengeMaster';
public static DELETE_Challenge='DeleteChallengeMaster';
public static GET_Challenge='GetChallengeMaster';

//ADD PRICING
public static INSERT_Pricing='AddPricing';
public static EDIT_Pricing='GetPricings';
public static DELETE_Pricing='DeletePricing';
public static GET_Pricing='GetPricings';

//Credit Score
public static GET_CREDITSCORE='getcreditscore';
public static GET_CHALLENGEHISTORY='challengehistory';
public static GET_ACCOUNTHISTORY='AccountHistory';

public static INSERT_CUSTOMERSERVICE='AddCustomerService';
public static GET_CUSTOMERSERVICE='GetCustomerService';
public static DELETE_CUSTOMERSERVICE='DeleteCustomerService';

public static GET_LOGINCUSTOMERSERVICE='CheckCustomerServiceUser';
public static GET_CUSTOMERSERVICECLIENTS='GetClients';

public static INSERT_CUSTOMERCLIENT='IUDClient';

//Trace
public static GET_TRACEALL='GetTraces';
public static DELETETRACE='DeleteTrace';
public static DELETEALLTRACES='DeleteAllTraces';

public static CheckCorpCustomerUser='CheckCorpCustomerUser';

}
