<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="client-dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Reset Password</span></li>
                    </ol>
                </div>
               
            </div>
            <div class="card">
                <div class="card-header  bg-light"><b class="text-secondary">Reset Password</b></div>          
                <div class="card-body">
                    <form class="text-left" [formGroup]="ResetPasswordForm" novalidate>
                        <div class="row">
                            <div class="col-lg-3">
                                <label>Password</label>
                               <input type="text" class="form-control" placeholder="Password" formControlName="Password"/>
                            </div>
                        </div>
                        <div class="row  pt-2">
                            <div class="col-lg-3">
                                <label>New Password</label>
                               <input type="password" class="form-control" placeholder="New Password" formControlName="NewPassword"/>
                            </div>
                        </div>
                        <div class="row  pt-2">
                            <div class="col-lg-3">
                                <label>Confirm Password</label>
                               <input type="password" class="form-control" placeholder="Confirm Password" formControlName="ConfirmPassword"/>
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-lg-12">
                               <button class="btn btn-primary" (click)="changePassword()">Submit</button>&nbsp;&nbsp;
                               <button class="btn btn-danger" (click)="Cancel()">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>                
        </div>
    </div>
</div>
<app-footer></app-footer>
</div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->