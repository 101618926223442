import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormControl,FormsModule,FormBuilder,FormGroup,Validators, MaxLengthValidator} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";
declare var $;
@Component({
  selector: 'app-manage-billing',
  templateUrl: './manage-billing.component.html',
  styleUrls: ['./manage-billing.component.css']
})
export class ManageBillingComponent implements OnInit {

  public rowDataGrid:any=[];
  public id:string='';
  public Bid:string='';
  public btnDelete:any;

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,
    private loginService: LoginService,public routing:Router) {
      this.titleservice.setTitle("Manage Billing");
          setTimeout(function(){
        $(function(){
           $("#tblBilling").DataTable({
            //  dom: '<"row"<"col-md-12"<"row"<"col-md-6"B><"col-md-6"f> > ><"col-md-12"rt> <"col-md-12"<"row"<"col-md-5"i><"col-md-7"p>>> >',    
           
            //  buttons: {          
            //      buttons: [              
            //          { extend: 'csv', className: 'btn' },
            //          { extend: 'excel', className: 'btn' },
            //          { extend: 'print', className: 'btn' }
            //      ]
            //  },
            
             "oLanguage": {
               "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
               "sInfo": "Showing page _PAGE_ of _PAGES_",
               "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
               "sSearchPlaceholder": "Search...",
              "sLengthMenu": "Results :  _MENU_",
           },
           "order": [[ 3, "desc" ]],
           "stripeClasses": [],
           "lengthMenu": [5, 10, 20, 50],
           "pageLength": 5,
           drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
           });
        });
   
       },3000);
   
     }

  ngOnInit(): void {
    let user=this.loginService.getUser();  
    if(user!=null||user!=undefined){
         if(user.userRole!="client"){
        //this.routing.navigate(['admin']);
         }
         else
         {}
      }      

       this.id="";  
       this.id = user.clientId;   
       this.GetBillingDetails(); 
     }

   GetBillingDetails(){     
    if(this.id!=null && this.id!=""){    
      let obj={
        key:"123",
        clientid:this.id
      }
      this.loginService.GetClientBilling(obj).subscribe(res=>{  
        
          if(res.status==1){                  
            for(let i=0;i<res.data.length;i++){
              let objCBilling={
               Bid:res.data[i].billingId,            
               CardType:res.data[i].cardType,
               CardNumber:res.data[i].cardNumber,
               EDate:res.data[i].expiryMonth+"-"+"20"+res.data[i].expiryYear,
               PrimaryCard:res.data[i].isPrimary == true ? "Yes" : "No",
               //btnDelete:res.data[i].isPrimary==true?"disabled":"visiable",
              }     

              this.rowDataGrid.push(objCBilling);      
            }    
          }         
      });

    }
  }

 Delete(Bid){
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to delete!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.value) {
      let obj={
        key:"123",
        BillingId:Bid
      }
          this.loginService.DeleteBillingDetails(obj).subscribe(res=>{           
            if(res.status==1){
              //this.GetBillingDetails();
             Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Billing details deleted successfully'
              });
              window.location.href='manage-billing';
            }
            else
            {
              Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Error in deleting billing details'
              });
            }
          });   
    }
    
  });

 }
  
  CreateBilling(){
    window.location.href='client-billing';
  }
}
