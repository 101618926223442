<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
             <div class="row layout-top-spacing">      
                 <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                     <div class="row">
                         <div class="col-md-12">
                             <ol class="breadcrumb">
                                 <li class="breadcrumb-item ml-auto"><a href="client-dashboard">Dashboard</a></li>
                                 <li class="breadcrumb-item active text-primary" aria-current="page"><span>Payment Method</span></li>
                             </ol>
                         </div>               
                     </div>
                     <div class="card">
                         <div class="card-header  bg-light"><b class="text-secondary">Payment Method</b></div>
                             <div class="card-body"> 
                                 <form [formGroup]="BillingModel">
                                    <!-- <div class="row">
                                        <div class="col-md-4 mb-4">
                                            <label for="validationDefault19">Billing Type<span class="text-danger">*</span></label>
                                            <select class="form-control" id="BillingType"  
                                            formControlName="BillingType">                                        
                                            <option value="">Select</option>    
                                            <option value="Monthly">Monthly</option>    
                                            <option value="Quarterly">Quarterly</option>   
                                            <option value="Half-Yearly">Half-Yearly</option>   
                                            <option value="Annually">Annually</option>    
                                        </select>
                                        </div>
                                    </div>-->

                                    <div class="row">                                         <div class="col-md-4 mb-4">
                                            <label for="validationDefault20">Card Type<span class="text-danger">*</span></label>
                                            <select class="form-control" id="CardType"  
                                            formControlName="CardType">                                        
                                            <option value="">Select</option>    
                                            <option value="VISA">Visa</option>    
                                            <option value="Captel One">Captel One</option>   
                                            <option value="Mastercard">Mastercard</option>   
                                        </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 mb-4">
                                            <label for="validationDefault01">Card Number<span class="text-danger">*</span></label>
                                            <input  type="text" class="form-control" id="CardNumber" placeholder="Card Number"
                                             formControlName="CardNumber" required>
                                        </div>
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-md-4 mb-4">
                                            <label for="validationDefault0122">Expiry Date</label>
                                            <input  type="text" class="form-control" id="ExpiryDate" placeholder="Card Number"
                                             formControlName="ExpiryDate" required>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                      
                                        <div class="col-md-2 mb-4">
                                            <label for="month">Expiration Date<span class="text-danger">*</span></label>
                                            <select class="form-control" id="edMonth"
                                             formControlName="expiryMonth" required>
                                                <option value="">Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>                                 
                                        </div>
                                        <div class="col-md-2 mt-2">                                          
                                             <label for="Year"></label>
                                             <select class="form-control" id="edYear" 
                                             formControlName="expiryYear" required>
                                                <option value="">Year</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                            </select>
                                        </div>                                   
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 mb-4">
                                            <label for="validationDefault01">CVV<span class="text-danger">*</span></label>
                                            <input  type="text" class="form-control" id="cvv" placeholder="CVV"
                                             formControlName="CVV" required>
                                        </div>                                        
                                    </div>
                                    <!-- <div class="row">
                                        <div class="col-md-4 mb-4">
                                            <label for="validationDefault01">Zipcode<span class="text-danger">*</span></label>
                                            <input  type="text" class="form-control" id="billingZipcode" placeholder="Zipcode"
                                             formControlName="BillingZipcode" required>
                                        </div>                                        
                                    </div>-->
                                    <div class="row"> 
                                        <div class="col-md-4">
                                            <div class="custom-control custom-checkbox  mb-3">
                                                <input type="checkbox" class="custom-control-input" 
                                                id="isPrimary" name="isPrimary" fromControlName="isPrimary">
                                                <label class="custom-control-label" for="isPrimary">Is Primary</label>
                                              </div>   
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-6">
                                            <button class="btn btn-primary" (click)="SaveBilling()">Submit</button>&nbsp;&nbsp;
                                             <button class="btn btn-danger" (click)="CancelBilling()">Cancel</button>&nbsp;&nbsp;
                                        </div>
                                    </div>
                                 </form>
                             </div>
                         </div>                
                     </div>
                 </div>
            </div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->