import { Component, DebugElement, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import { DatePipe,formatDate } from '@angular/common';



@Component({
  selector: 'app-customerservice-create',
  templateUrl: './customerservice-create.component.html',
  styleUrls: ['./customerservice-create.component.css']
})
export class CustomerserviceCreateComponent implements OnInit {
  datePickerConfig:Partial<BsDatepickerConfig>;
  public Customerservice:FormGroup;
  public CId:string='';
  public PtypeId:string='';
  public Mode:string='insert';
  public htext:string='';
  public btext:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
              private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
              {
                this.datePickerConfig=Object.assign({},
                  { 
                    containerClass:'theme-dark-blue',
                    showWeekNumbers: false,
                    dateInputFormat:'MM/DD/YYYY',
                    isAnimated: true,         
                  });
          
                this.Customerservice=this.fb.group({
                  FirstName:'',
                  LastName:'',
                  MiddleName:'',
                  DateOfBirth:'',
                  SocialSecurityNo:'',
                  EmailAddress:'',
                  PhoneNo:''                  
                });
              }

   ngOnInit(): void {
     
    let user=this.loginService.getUser();
   
    if(user!=null||user!=undefined){        
       if(user.userRole=="customerservice")
       {
        this.htext="Create Client";
        this.btext="Save";
        this.CId=this.route.snapshot.queryParams["id"];
        if(this.CId!=""&& this.CId!=null && this.CId!=undefined){
          this.htext="Update Client";
          this.btext="Update";
          let obj={
            key:"123",            
            clientid:parseInt(this.CId)
          }
          this.loginService.GetClientsById(obj).subscribe(res=>{          
           if(res.status==1){
            $("#EmailAddress").prop("disabled",true);
            this.Customerservice=this.fb.group({              
              FirstName:res.data[0].firstName,
              LastName:res.data[0].lastName,
              MiddleName:res.data[0].middleName,
              DateOfBirth:res.data[0].dob,
              SocialSecurityNo:res.data[0].ssn,
              EmailAddress:res.data[0].currentEmail,
              PhoneNo:res.data[0].currentPhone          
            });
          }
        });
       }
     }
   }
 }
 changeDateFormat(value: string) {
  var datePipe = new DatePipe("en-US");
   value = datePipe.transform(value, 'MM/dd/yyyy');
   return value;
}
dobValidation(){ 
  // var dob=this.Customerservice.value.DateOfBirth;
  // var dobReg=/^\d{2}\/\d{2}\/\d{4}$/;
  // if(!dobReg.test(dob)) {
  //    Swal.fire('Warning','Please enter valid Date of birth.','warning');
  //    $("#DateOfBirth").val('');
  //    $("#DateOfBirth").focus();
  //   }
  }
  ssnValidation(){ 
  var ssn=this.Customerservice.value.SocialSecurityNo;
  var ssnReg = /(^\d{2}-\d{7}$)/;
  if(!ssnReg.test(ssn)) {
    Swal.fire('Warning','Please enter valid Social security number.','warning');
    $("#SocialSecurityNo").val('');
    $("#SocialSecurityNo").focus();
    return false;
   }
  }
 emailValidation(){
   var emails=this.Customerservice.value.EmailAddress;
   var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
   if( !emailReg.test( emails ) ) {
       Swal.fire('Warning','Please enter valid email.','warning');
       $("#EmailAddress").val('');
       $("#EmailAddress").focus(); 
       return false;
   } 
 }
 
 phoneValidation(){ 
 var phone=this.Customerservice.value.PhoneNo;
      if(phone != "")
      {
        var phoneReg =/^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
        if(!phoneReg.test(phone)) {
        Swal.fire('Warning','Please enter valid phone number.','warning');
        $("#PhoneNo").val('');
        $("#PhoneNo").focus();  
        return false;
        }
      }
       
  }
 btnSave(){
     
    if(!this.Customerservice.invalid)
    { 
      if(this.CId!=""&& this.CId!=null && this.CId!=undefined)
      {   
         
          let obj={
          key:"123",
          FirstName:this.Customerservice.value.FirstName,
          LastName:this.Customerservice.value.LastName,
          MiddleName:this.Customerservice.value.MiddleName,
          DOB:this.changeDateFormat(this.Customerservice.value.DateOfBirth),
          SSN:this.Customerservice.value.SocialSecurityNo,
          CurrentEmail:this.Customerservice.value.EmailAddress,
          CurrentPhone:this.Customerservice.value.PhoneNo,  
          mode:"update",
          ClientId:parseFloat(this.CId),
          CreatedBy:1
        }
        this.loginService.CreateCustomerClient(obj).subscribe(res=>{
          
           if(res.status==1){
              window.location.href='customerservice-dashboard';
           }
           else
           {
             Swal.fire('warning','Client not updated','warning');
             return;
           }
        });

      }
      else
      {
           let objEmail={
               key:"123",
               email:this.Customerservice.value.EmailAddress
              }
              this.loginService.CheckEmailExists(objEmail).subscribe(res=>{
                  if(res.status==1){     
                     
                    Swal.fire('Warning','Email  already exists','warning');
                    $("#EmailAddress").val("");
                    $("#EmailAddress").focus(); 
                    return false;
                  }
                  else
                  {
                     let obj={
                         key:"123",
                         FirstName:this.Customerservice.value.FirstName,
                         LastName:this.Customerservice.value.LastName,
                         MiddleName:this.Customerservice.value.MiddleName,
                         DOB:this.changeDateFormat(this.Customerservice.value.DateOfBirth),
                         SSN:this.Customerservice.value.SocialSecurityNo,
                         CurrentEmail:this.Customerservice.value.EmailAddress,
                         CurrentPhone:this.Customerservice.value.PhoneNo,  
                         mode:this.Mode,            
                    }
                    this.loginService.CreateCustomerClient(obj).subscribe(res=>{  
                    
                    if(res.status==1){        
                      
                      window.location.href='customerservice-dashboard';
                    }
                    else{
                          Swal.fire('warning','Client not saved!','warning');
                          return;
                    }
              });
           }
        });         
      }
    }
    else
    {
      Swal.fire("Warning",'Please fill all mandatory fields','warning');
      return;
    }
  } 
}
