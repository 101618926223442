import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
declare var $;

@Component({
  selector: 'app-corp-clientchallenges',
  templateUrl: './corp-clientchallenges.component.html',
  styleUrls: ['./corp-clientchallenges.component.css']
})
export class CorpClientchallengesComponent implements OnInit {
  
  public id:string='';
  public rowDataGrid:any=[];
  public refCode:string='';
 
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { }

  ngOnInit(): void {
    debugger
    this.rowDataGrid=[];
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['corp']);
    }
    if(user!=null||user!=undefined)
    {        
       if(user.userRole=="corpcustomer")
       {
          this.id = user.userID;  
          this.GetClientChalenges(user.key);          
       }
    }
  }

  GetClientChalenges(refCode){
    let objrCode={
      key:"123",
      refcode:refCode      
     }
     this.loginService.GetChallengesToAdmin(objrCode).subscribe(res=>{         
         if(res.status==1){
           this.rowDataGrid=[];
           for(let i=0;i<res.data.length;i++){                  
              let objCorporate={
                  RefCode:res.data[i].refCode,    
                  Fullname:res.data[i].clientName,
                  RoundType:res.data[i].roundType,
                  Agency:res.data[i].cAgency,
                  Filename:res.data[i].crFilename,
                  creditRepFileId:res.data[i].creditRepFileId +'~'+ res.data[i].clientId   
                 } 
                 this.rowDataGrid.push(objCorporate);          
           }  
         }
         else
         {       
            this.rowDataGrid=[];
         }     
     });
   }
  

  checkAll() {    
    $("#selectAll").on("click", function () {
        if ($("input:checkbox").prop("checked")) {
           $("input:checkbox[name='row-check']").prop("checked", true);
        } else {
         $("input:checkbox[name='row-check']").prop("checked", false);
     }
   });
 }

 checkAllCheckBox(){
  // Check event on each table row checkbox
     $("input:checkbox[name='row-check']").on("change", function () {
        var total_check_boxes = $("input:checkbox[name='row-check']").length;
        var total_checked_boxes = $("input:checkbox[name='row-check']:checked").length;

         // If all checked manually then check selectAll checkbox
       if (total_check_boxes === total_checked_boxes) {
         $("#selectAll").prop("checked", true);
       }
       else {
         $("#selectAll").prop("checked", false);
     }
   });
 }


  btnMark(){
    var open = [];
    var total_checked_boxes = $("input:checkbox[name='row-check']:checked").length;
    if(total_checked_boxes==0){
      Swal.fire({
        icon: 'warning',
        title:'Warning',
        text: 'Please select atleast one checkbox'
      });
      return;
    }   

    $.each($("input:checkbox[name='row-check']:checked"), function () {
        var arrFields=$(this).val().split('~');
        var lid=parseInt(arrFields[0]);
        var cid=parseInt(arrFields[1]);

        let obj={
          key:"123",
          ClientId:cid,
          LetterId:lid
        }
        open.push(obj);
    });
    this.loginService.InsertLetterPrintAudit(open).subscribe(res=>{
      
      if(res.status==1){
        
        Swal.fire('Success','Letter(s) mark as print successfully.').then(function(){
            window.location.href='corp-clientchallenges';     
           });
        }
      else
      {
        Swal.fire('Error',res.message);
        return;
      }
    });
    

  }
 
  ViewPDF(file)
  {

    var base64EncodedPDF="";
    this.loginService.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.loginService.base64ToArrayBuffer(res.data);
          this.loginService.saveByteArray(file, sampleArr);
      }
    });
  }
 
}
