<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
              <li class="breadcrumb-item active text-primary" aria-current="page"><span>Manage Pricing</span></li>
            </ol>
         </nav>
         <div class="row">
           <div class="col-md-12 text-right mb-3">
            <a href="admin-addpricing" class="btn btn-primary"><i class="fa fa-plus"></i>&nbsp; Create Pricing</a>&nbsp; 
           </div>
         </div>
         <div class="card">
              <div class="card-header  bg-light"><b class="text-secondary">Manage Pricing</b></div>
                   <div class="card-body">
                    <div class="table-responsive">
                      <table id="tblPricing" class="table  table-bordered">
                        <thead>
                          <tr>                           
                            <th>Pricing Type</th>
                            <th>PricingType Desc </th>
                            <th>Price PerMonth</th>
                            <th>Setup Fee</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of rowDataGrid">
                           <td style="width: 200px;">{{data.PricingType}}</td>
                           <td style="width: 200px;">{{data.PricingTypeDesc}}</td>

                           <td style="width: 200px;">{{data.PricePerMonth}}</td>
                           <td style="width: 200px;">{{data.SetupFee}}</td>
                        
                           <td style="width: 200px;"> <a href="admin-addpricing?PricingId={{data.PricingId}}" class="btn btn-success fa fa-edit" title="Edit"  style="padding-left:1rem;margin:.2rem;width:50px;"></a>
                            <button (click)="Delete(data.PricingId)" class="btn btn-danger fa fa-trash" title="Delete"  style="padding-left:1rem;margin:.2rem;width:50px;"></button>
                            </td>
                         </tr> 
                       </tbody>
                      </table>
                  </div>  
                   </div>
         </div>
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
