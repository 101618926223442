import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../shared/login/login.service';
import Swal from 'sweetalert2';
import {ChartOptions,ChartType} from 'chart.js';
import {Label,Color} from 'ng2-charts';
import { IfStmt } from '@angular/compiler';



@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit {
  public id:string=""; 
  public CreditReportId:string="";

  public barChartOptions:ChartOptions={
    responsive:true
  }

   public barChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];
   public barChartType: ChartType = 'bar';
   public barChartLegend = true;
   public barChartData:any=[];  
   public ChartValues:any=[]; 
   public ChartRound:any=[];
   public barChartColors:any=[];
   public ChartData:any=[];
   public data:any=[];
   public ChartDataSets:any=[];
   public rowDataGridCredit:any=[];
   public rowDataGridChallenge:any=[];
   public rowDataGridAccount:any=[];
   public rowDataGridAccountRound2:any=[];
   public rowDataGridAccountRound3:any=[];
   public R1:any=[];
   public round2:string='';
   public round3:string='';
   public cdate:any=[];
   public cdateRound2:any=[];
   public cdateRound3:any=[];
  
  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      this.id="";
    this.id = user.clientId;
    }
    this.GetCreditItemDetails();
     this.DisplayChart();
     this.GetCreditScore();
     this.GetChallengeHistory();
     this.GetAccountHistory();

  }
  
  DisplayChart()
  {
    if(this.id!="" && this.id!=null){
      let obj={
      key:"123",
      clientid:this.id
      }
      this.loginService.GetChallengesByMonth(obj).subscribe(res=>{
        
        if(res.status==1)
        {
          for(let i=0;i<res.data.length;i++){
            let objs={
              arround:res.data[i].label,
              ChartDataSets:res.data[i].data
            }
          
          this.ChartRound.push(objs.arround);  
          this.ChartValues.push(objs.ChartDataSets); 
          this.ChartData.push(objs);          
          } 
            var val =res.data;
            this.barChartData= val;         
        }
        else
        {
          this.barChartData= null;    
         
        }
      });
    }
  
    
      
  }
   
 
  ViewCreditItems()
  {
    window.location.href='credit-items';
  }
  GetCreditItemDetails()
  {
    $("#btnCreditPull").hide();              
    $("#btnViewCreditPull").hide();

    if(this.id!=null && this.id!=""){    
      let obj={
        key:"123",
        clientid:this.id
      }
      this.loginService.GetCreditItemDetails(obj).subscribe(res=>{
        if(res.status==1){        
           if(res.data != null)
           {
            // this.CreditReportId=res.data.credReportId;
             
              $("#dateReportPulls").html(res.data.dateReportPulls);
              $("#roundType").html(res.data.roundType.toUpperCase());
              $("#totitems").html(res.data.totitems);
              $("#negativeItems").html(res.data.negativeItems);
              $("#nextDueDate").html(res.data.nextDueDate);
              $("#nextRound").html(res.data.nextRound);
              
              $("#btnViewCreditPull").show();
              var dueDate = new Date(res.data.nextDueDate);
              var currentDate = new Date();//new Date("09/09/2020");
             if(dueDate <= currentDate)
              {
                $("#nextDueDate").addClass('span1');
              }
              else
              {
                $("#nextDueDate").removeClass('span1');
              }
           }
           else
           {
            $("#btnCreditPull").show();
           }
        }
        else
        {
          $("#btnCreditPull").show();
        }
        
    });
  }
  }
  CreditPull()
  {
    debugger
    if(this.id!=null && this.id!=""){    
      let obj={
          key:"123",
        clientid:this.id
      }
      this.loginService.CreditPull(obj).subscribe(res=>{
        if(res.status==1){        
          Swal.fire('Success','Credit pull completed successfully.','success');
          window.location.href='credit-items';   
        }
        else
        {
          Swal.fire('Warning',res.message,'warning');
        }
    });
  }
  }

  GetCreditScore(){
    
    var cdate="";
    var items="";
    var eqScore="";
    var exScore="";
    var tuScore="";
    if(this.id!=null && this.id!=""){    
      let obj={
          key:"123",
          clientid:this.id
      }
      this.loginService.getCreditScore(obj).subscribe(res=>{
    
        if(res.status==1){           
         
          for(let i=0;i<res.data.length;i++)
          {
             cdate=res.data[i].createdDate;          
          }
          var tdEQItems=""; var tdEXItems=""; var tdTUItems="";var tdCDate="";
          for(let j=0;j<res.data.length;j++){
            if(res.data[j].agency=="Equifax"){
              eqScore=res.data[j].score;
            }
            if(res.data[j].agency=="Experian"){
              exScore=res.data[j].score;
            }
            if(res.data[j].agency=="TransUnion"){
              tuScore=res.data[j].score;
            }
            
            var eqDisp="black";var exDisp="black";var tuDisp="black";
            if(parseInt(eqScore) < 600)
            {
              eqDisp="red";
            }
            else if(parseInt(eqScore) > 600 && parseInt(eqScore) < 650)
            {
              eqDisp="orange";
            }
            if(parseInt(eqScore) > 650)
            {
              eqDisp="green";
            }
  
            if(parseInt(exScore) < 600)
            {
              exDisp="red";
            }
            else if(parseInt(exScore) > 600 && parseInt(exScore) < 650)
            {
              exDisp="orange";
            }
            if(parseInt(exScore) > 650)
            {
              exDisp="green";
            }
  
            if(parseInt(tuScore) < 600)
            {
              tuDisp="red";
            }
            else if(parseInt(tuScore) > 600 && parseInt(tuScore) < 650)
            {
              tuDisp="orange";
            }
            if(parseInt(tuScore) > 650)
            {
              tuDisp="green";
            }

            if(res.data[j].agency=="Equifax"){
              tdEQItems+= "<td  style=padding-left:100px;color:"+ eqDisp +">"+eqScore+"</td>"
            }
            if(res.data[j].agency=="Experian"){
              tdEXItems+= "<td  style=padding-left:100px;color:"+ exDisp +">"+exScore+"</td>"
            }
            if(res.data[j].agency=="TransUnion"){
              tdTUItems+= "<td  style=padding-left:100px;color:"+ tuDisp +">"+tuScore+"</td>"
            }
            
            
            if(res.data[j].agency=="Equifax"){
            tdCDate+= "<th  style=padding-left:100px>"+res.data[j].createdDate+"</th>"
            }
          }       

         
            items+="<thead>"
            items+="<tr><th><strong>Date :</strong></th>"+tdCDate+"</tr>"
            items+="</thead>"          
            items+="<tbody><tr><td style=background-color:#C0392B;color:white;width:350px;><strong>Equifax </strong></td>"+tdEQItems+"</tr>"
            items+="<tr><td style=background-color:#9B59B6;color:white;width:350px;><strong>Experian </strong></td>"+tdEXItems+"</tr>"
            items+="<tr><td style=background-color:#3498DB;color:white;width:350px;><strong>TransUnion </strong></td>"+tdTUItems+"</tr>"
            items+="</tbody>"
          }

          $("#tblCreditscore").html(items);
      });
    }
  }
  GetChallengeHistory(){
  
    if(this.id!=null && this.id!=""){    
      let obj={
          key:"123",
          clientid:this.id
      }
      this.loginService.getChallengeHistory(obj).subscribe(res=>{
  
        if(res.status==1){           
           
            for(let i=0;i<res.data.length;i++){
              let objChallenge={
                Merchant:res.data[i].merchant,
                Account:res.data[i].number,
                Round1:res.data[i].round1==1?"Y":"-",
                Round2:res.data[i].round2==1?"Y":"-",
                Round3:res.data[i].round3==1?"Y":"-",
              }
              this.rowDataGridChallenge.push(objChallenge);
            }
             // Swal.fire("Success",'ChallengeHistory','success');
           }
      });
    }
  }
  GetAccountHistory(){
  debugger
    if(this.id!=null && this.id!=""){    
      let obj={
          key:"123",
          clientid:this.id
      }
      this.loginService.getAccountHistory(obj).subscribe(res=>{
    
        if(res.status==1){        
          debugger
          if(res.data.round1.length > 0)
        {
          for(let i=0;i<res.data.round1.length;i++){
                   
            this.R1="Round-1";              
            this.cdate=res.data.round1[i].challengeDate;    
            let objAccount={
              Merchant:res.data.round1[i].merchantName,
              OpenDate:res.data.round1[i].openDate,
              Equifax:res.data.round1[i].equifax =='0' ?'N' :'Y',
              Experian:res.data.round1[i].experian=='0' ?'N' :'Y',
              Transunion:res.data.round1[i].transunion=='0' ?'N' :'Y'
            }
          
            this.rowDataGridAccount.push(objAccount);
          }
        }
        else
        {
          $("#tblAccountHistory").hide();
        }


          if(res.data.round2.length > 0)
        {
          for(let i=0;i<res.data.round2.length;i++){
                   
                        
            this.cdateRound2=res.data.round2[i].challengeDate;    
            let objAccount={
              Merchant:res.data.round2[i].merchantName,
              OpenDate:res.data.round2[i].openDate,
              Equifax:res.data.round2[i].equifax=='0' ?'N' :'Y',
              Experian:res.data.round2[i].experian=='0' ?'N' :'Y',
              Transunion:res.data.round2[i].transunion=='0' ?'N' :'Y'
            }
          
            this.rowDataGridAccountRound2.push(objAccount);
          }
        }
        else
        {
          $("#tblAccountHistoryRound2").hide();
        }

        if(res.data.round3.length > 0)
        {
          for(let i=0;i<res.data.round3.length;i++){
                   
                        
            this.cdateRound3=res.data.round3[i].challengeDate;    
            let objAccount={
              Merchant:res.data.round3[i].merchantName,
              OpenDate:res.data.round3[i].openDate,
              Equifax:res.data.round3[i].equifax=='0' ?'N' :'Y',
              Experian:res.data.round3[i].experian=='0' ?'N' :'Y',
              Transunion:res.data.round3[i].transunion=='0' ?'N' :'Y'
            }
          
            this.rowDataGridAccountRound3.push(objAccount);
          }
        }
        else
        {
          $("#tblAccountHistoryRound3").hide();
        }
          
        
        }
      });
    }
  }
}
