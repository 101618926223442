<app-customerservice-header></app-customerservice-header>
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
     <app-customerservice-sidebar></app-customerservice-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
             <div class="row layout-top-spacing"> 
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                        <div class="col-md-12">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item ml-auto"><a href="customerservice-dashboard">Dashboard</a></li>
                                <li class="breadcrumb-item active text-primary" aria-current="page"><span>Credit File</span></li>
                            </ol>
                        </div>
                    </div> 
               </div>      
             </div>
                   
             <div class="row">
            <div class="col-md-12">
                <div class="card">                               
                     <div class="card-header bg-light text-primary">
                        <div class="row">
                            <div class="col-lg-6 pt-2">Credit File</div>
                              
                        </div>                                     
                     </div>
                     <div class="card-body">
                        <div id="toggleAccordion" class="accordion">
                            <div class="row">
                                <div class="col-lg-12">
                                   <div class="card">
                                       <div class="card-header collapsed text-primary" id="CreditScore" data-toggle="collapse" data-target="#apcreditscore" aria-expanded="true" aria-controls="apcreditscore">
                                            Credit Score
                                       </div>
                                       <div id="apcreditscore" class="collapse" aria-labelledby="CreditScore" data-parent="#toggleAccordion">

                                        <div class="card-body text-secondary">
                                             <div class="table-responsive">
                                                 <table id="tblCreditscore" class="table  table-bordered">                                               
                                                 </table>
                                             </div>
                                        </div> 
                                   </div>                           
                               </div>
                           </div>
                       </div>                  
                       
                       <div class="row pt-4">
                           <div class="col-lg-12">
                               <div class="card">
                                   <div class="card-header collapsed text-primary" id="ChallengeHistory" data-toggle="collapse" data-target="#apChallengeHistory" aria-expanded="true" aria-controls="apChallengeHistory">
                                       Challenge History
                                   </div>
                                   <div id="apChallengeHistory" class="collapse" aria-labelledby="ChallengeHistory" data-parent="#toggleAccordion">

                                   <div class="card-body text-secondary">
                                       <div class="table-responsive">
                                       <table id="tblChallengeHistory" class="table  table-bordered">
                                          
                                        <thead>
                                           <tr>
                                               <th>Merchant</th>
                                               <th>#Number</th>
                                               <th>Round-1</th>
                                               <th>Round-2</th>
                                               <th>Round-3</th>
                                           </tr>                               
                                       </thead>
                                       <tbody>
                                           <tr *ngFor="let data of rowDataGridChallenge">
                                               <td>{{data.Merchant}}</td>
                                               <td>{{data.Account}}</td>
                                               <td>{{data.Round1}}</td>
                                               <td>{{data.Round2}}</td>
                                               <td>{{data.Round3}}</td>
                                           </tr>
                                       </tbody>
                                       </table>
                                       </div>
                                   </div>
                               </div>
                               </div>
                           </div>
                       </div>
                      
                       <div class="row pt-4">
                           <div class="col-lg-12">
                               <div class="card">
                                   <div class="card-header collapsed text-primary" id="AccountHistory" data-toggle="collapse" data-target="#apAccountHistory" aria-expanded="true" aria-controls="apAccountHistory">
                                       Account History
                                   </div>
                                   <div id="apAccountHistory" class="collapse" aria-labelledby="AccountHistory" data-parent="#toggleAccordion">

                                   <div class="card-body text-secondary">
                                       <div class="table-responsive">
                                       <table id="tblAccountHistory" class="table  table-bordered">
                                          
                                           <thead>
                                               <tr><td colspan="1" class="text-danger"><strong>{{R1}}</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdate}}</td></tr>
                                               <tr>
                                                   <th>Merchant</th>
                                                   <th>Open Date</th>
                                                   <th>EQUIFAX</th>
                                                   <th>EXPERIAN</th>
                                                   <th>TRANSUNION</th>
                                               </tr>                               
                                           </thead>
                                           <tbody>
                                               
                                               <tr *ngFor="let data of rowDataGridAccount">
                                                   <td>{{data.Merchant}}</td>
                                                   <td>{{data.OpenDate}}</td>
                                                   <td>{{data.Equifax}}</td>
                                                   <td>{{data.Experian}}</td>
                                                   <td>{{data.Transunion}}</td>
                                               </tr>
                                           </tbody>
                                           
                                       </table>
                                   </div>
                                   </div>
                                   <div class="card-body text-secondary">
                                    <div class="table-responsive">
                                    <table id="tblAccountHistoryRound2"  class="table  table-bordered">
                                       
                                        <thead>
                                            <tr><td colspan="1" class="text-danger"><strong>Round-2</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdateRound2}}</td></tr>
                                            <tr>
                                                <th>Merchant</th>
                                                <th>Open Date</th>
                                                <th>EQUIFAX</th>
                                                <th>EXPERIAN</th>
                                                <th>TRANSUNION</th>
                                            </tr>                               
                                        </thead>
                                        <tbody>
                                            
                                            <tr *ngFor="let data of rowDataGridAccountRound2">
                                                <td>{{data.Merchant}}</td>
                                                <td>{{data.OpenDate}}</td>
                                                <td>{{data.Equifax}}</td>
                                                <td>{{data.Experian}}</td>
                                                <td>{{data.Transunion}}</td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                                </div>
                                <div class="card-body text-secondary">
                                    <div class="table-responsive">
                                    <table id="tblAccountHistoryRound3" class="table  table-bordered">
                                       
                                        <thead>
                                            <tr><td colspan="1" class="text-danger"><strong>Round-3</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdateRound3}}</td></tr>
                                            <tr>
                                                <th>Merchant</th>
                                                <th>Open Date</th>
                                                <th>EQUIFAX</th>
                                                <th>EXPERIAN</th>
                                                <th>TRANSUNION</th>
                                            </tr>                               
                                        </thead>
                                        <tbody>
                                            
                                            <tr *ngFor="let data of rowDataGridAccountRound3">
                                                <td>{{data.Merchant}}</td>
                                                <td>{{data.OpenDate}}</td>
                                                <td>{{data.Equifax}}</td>
                                                <td>{{data.Experian}}</td>
                                                <td>{{data.Transunion}}</td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                                </div>
                               </div>
                               </div>
                           </div>
                       </div>
                   </div>
                    </div>
                </div>
            </div>
             </div>   
               
             <app-customerservice-footer></app-customerservice-footer>
   
        </div>
    </div>
    
               
            

    
        <!--  END CONTENT AREA  -->
  
</div>
