import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-corp-addclient',
  templateUrl: './corp-addclient.component.html',
  styleUrls: ['./corp-addclient.component.css']
})
export class CorpAddclientComponent implements OnInit {

  public SignUpForm:FormGroup;
  public id:string='';
  public htext:string='';
  public btext:string='';
  public RefCode:string='';
  public strEmail:string='';
  
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
   {
    this.titleservice.setTitle("Add New Client");

   this.SignUpForm = this.fb.group({
      firstname:'',
      lastname:'',
      emailaddress:'',
      password:'',
      cnfpassword:'',
      refcode:''
    });
  }

  ngOnInit(): void {
    debugger
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['corp']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="corpcustomer")
       {
        this.htext="Add Client";
        this.btext="Save";
        this.id = user.clientId;    
        
        this.SignUpForm =this.fb.group({
          refcode:user.key,
          firstname:'',
          lastname:'',
          emailaddress:'',
          password:'',
          cnfpassword:''         
        });
        $("#refcode").prop("readonly",true);
        
      }
    }
  }
  
  btnSave(){
  debugger
    if(!this.SignUpForm.invalid){     
        if(this.SignUpForm.value.password!=this.SignUpForm.value.cnfpassword){
             Swal.fire('Warning','Confirm Password not matched with Password','warning');
             return;
        }

        var semail=this.SignUpForm.value.emailaddress;
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!regex.test(semail)){
        $("#emailaddress").focus();
        Swal.fire("Warning!","Please enter valid Email Address.","warning");
        $("#emailaddress").val("");
        return 
        }    
        let objs={
                    key:"123",
                    email:this.SignUpForm.value.emailaddress
                 }
                 this.loginService.CheckEmailExists(objs).subscribe(res=>{                   
                     if(res.status==1){     
                        $("#emailaddress").focus();          
                        Swal.fire('Warning','Email Address already exists','warning');
                        $("#emailaddress").val("");
                        return;
                      }
                      else
                      {
                         let obj={
                           key:"123",
                           FirstName:this.SignUpForm.value.firstname,
                           LastName:this.SignUpForm.value.lastname,
                           CurrentEmail:this.SignUpForm.value.emailaddress,
                           Password:this.SignUpForm.value.password,
                           refCode:this.SignUpForm.value.refcode,
                           
                         }
                         this.loginService.AddClient(obj).subscribe(res=>{    
                           debugger                     
                         if(res.status==1){
                            window.location.href='corp-dashboard';
                          }
                          else{
                                Swal.fire('Error',res.message +' '+ res.data,'error')
                                return;                          
                              }
                        });
                      }
                  });   
    }
    else
    {
           Swal.fire('Warning', 'Please fill all required fields.', 'warning');
           return;
    }
 
  }
     emailvalidation(){        
       var semail=this.SignUpForm.value.emailaddress;
       let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
       if(!regex.test(semail)){
         $("#emailaddress").focus();
         Swal.fire("Warning!","Please enter valid Email Address.","warning");
         $("#emailaddress").val("");
         return false;
      }   
    } 
}