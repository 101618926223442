import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
declare var $;


@Component({
  selector: 'app-admin-billingreport',
  templateUrl: './admin-billingreport.component.html',
  styleUrls: ['./admin-billingreport.component.css']
})
export class AdminBillingreportComponent implements OnInit {
  public CorporateCustomer:FormGroup;
  public id:string='';
  public ddlRefCode:string='';
  public CorpCustomer:any=[];
  public rowDataGrid:any=[];
  public rowDataClient:any=[]
  public active:string='';
  public empt:string='';
 
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
   {
    this.CorporateCustomer=this.fb.group({    
     refCode:''
    });
    setTimeout(function(){
      $(function(){
         $("#tblBilling").DataTable({
          "dom": "<'row'<'col-md-2'l>|<'col-md-7 btop'B>|<'col-md-3 text-right'f>>" +
            "<'row'<'col-md-6'><'col-md-6'>>" +
            "<'row'<'col-md-12't>>" +
            "<'row'<'col-md-4'i><'col-md-8 pull-left'p>>",
            "buttons": {
                buttons: [
                  { extend:'csv', className: 'btn' },
                 { extend: 'excel', className: 'btn' },
                 { extend: 'print', className: 'btn' }
            ]
          },          
           "oLanguage": {
             "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
             "sInfo": "Showing page _PAGE_ of _PAGES_",
             "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
             "sSearchPlaceholder": "Search...",
            "sLengthMenu": "Results :  _MENU_",
         },
         
         "order": [[ 0, "asc" ]],
         "stripeClasses": [],
         "lengthMenu": [7, 10, 20, 50],
         "pageLength": 7,
         drawCallback: function () { $('.dataTables_paginate > .pagination').addClass(' pagination-style-13 pagination-bordered mb-5'); }
         });

       
      }); 
     },3000);
 
  }

  ngOnInit(): void {
  
    this.rowDataGrid=[];
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined)
    {        
       if(user.userRole=="admin")
       {
          this.id = user.userID;  
          this.GetCorporateCustomer();      
       
          this.empt=this.route.snapshot.queryParams["refCode"];
          if(this.empt == null)
          {
            this.empt="";
          }
          

          debugger
          $("#refcode").val(this.empt);
          this.GetfrmClients(this.empt);
          
         
          
        }
    }
    
  }

    GetClientsAll(){
      this.rowDataGrid=[];
       let obj={
         key:"123"
       }
       this.loginService.GetClients(obj).subscribe(res=>{
          if(res.status==1){
            for(let i=0;i<res.data.length;i++){   
              if(res.data[i].status=="True"){
                this.active="Active";
              }
              else{
                this.active="Inactive";
              }          
              let objClient={
                Fullname:res.data[i].fullName,                           
                Email:res.data[i].currentEmail,   
                PhoneNumber:res.data[i].currentPhone,
                Status:this.active       
              } 
              this.rowDataGrid.push(objClient);
               
              $("#tdFoot").hide();
            }           
           }
           else
           {
             $("#tdFoot").show();
            this.rowDataGrid=[];
           }
          });
     
    }  

    GetCorporateCustomer(){
           let obj={
          key:"123",          
        }
        this.loginService.GetCorporateCustomers(obj).subscribe(res=>{           
          if(res.status==1){                  
            for(let i=0;i<res.data.length;i++){                     
              let objCorp={
                   rowId:res.data[i].rowId,
                   refCode:res.data[i].refCode
              }
              this.CorpCustomer.push(objCorp);
            }
          }
        });
    }

    btnSubmit(){
    
      this.ddlRefCode=this.CorporateCustomer.value.refCode;
      location.href='/admin-billingreport?refCode='+this.ddlRefCode;  
     
    }

    GetfrmClients(empt){
      
      
      
         let objrCode={
           key:"123",
           refcode:this.empt
         }
         this.loginService.GetBillingReport(objrCode).subscribe(res=>{
                  
            if(res.status==1){
             this.rowDataGrid=[];
             for(let i=0;i<res.data.length;i++){   
               if(res.data[i].status=="True"){
                 this.active="Active";
               }
               else{
                 this.active="Inactive";
               }          
               let objCorporate={
                RefCode:res.data[i].refCode,    
                 Fullname:res.data[i].firstName,                           
                 Email:res.data[i].currentEmail,   
                 PhoneNumber:res.data[i].currentPhone,
                 BillingMonth: res.data[i].month != 0 ? res.data[i].month + '/' + res.data[i].year : "",
                 ChallengesSent:res.data[i].challengesCount != 0 ? res.data[i].challengesCount : "",
                 LettersSent: res.data[i].lettersSent != 0 ? res.data[i].lettersSent : "",
                 Status:this.active       
               } 
               this.rowDataGrid.push(objCorporate);
               
             }
            
            }
            else
            {
            
              this.rowDataGrid=[];
            }
            
         });
      
    
  }
 
  }

