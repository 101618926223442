import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AdminLoginComponent} from './login/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminMasterComponent } from './admin/admin-master/admin-master.component';
import { CreateStaffComponent } from './admin/create-staff/create-staff.component';
import { ManageStaffComponent } from './admin/manage-staff/manage-staff.component';
import { ClientLoginComponent } from './login/client-login/client-login.component';
import { SignUpComponent } from './client/signup/signup.component';
import { ClientProfileComponent } from './client/profile/profile.component';

import { AdminStudentlistComponent} from './admin/admin-studentlist/admin-studentlist.component';
import { AdminEditstudentComponent} from  './admin/admin-editstudent/admin-editstudent.component';
import { UploadStudentComponent } from './admin/upload-student/upload-student.component';
import { ClientDashboardComponent} from './layout/dashboard/client-dashboard/client-dashboard.component';
import { CreditItemsComponent} from './client/credit-items/credit-items.component'
import { ManageBillingComponent}from './client/manage-billing/manage-billing.component';
import { ClientBillingComponent}from './client/client-billing/client-billing.component';
import { ClientResetPasswordComponent} from './client/client-reset-password/client-reset-password.component';
import { ClientInfoComponent} from './client/client-info/client-info.component';
import { ManageAccounttypeComponent} from './admin/manage-accounttype/manage-accounttype.component';
import { CreateAccounttypeComponent} from './admin/create-accounttype/create-accounttype.component';
import { ClientChallengesComponent} from './client/client-challenges/client-challenges.component';
import { ServiceSettingsComponent} from './admin/service-settings/service-settings.component';
import { AdminAddchallengesComponent} from './admin/admin-addchallenges/admin-addchallenges.component';
import { AdminManagechallengesComponent } from './admin/admin-managechallenges/admin-managechallenges.component';
import { AdminAddpricingComponent } from './admin/admin-addpricing/admin-addpricing.component';
import { AdminManagepricingComponent } from './admin/admin-managepricing/admin-managepricing.component';
import { AdminCustomerserviceComponent} from './admin/admin-customerservice/admin-customerservice.component';
import { AdminManagecustomerserviceComponent} from './admin/admin-managecustomerservice/admin-managecustomerservice.component';
import { CustomerserviceLoginComponent} from './customerservice/customerservice-login/customerservice-login.component';
import { CustomerserviceDashboardComponent} from './customerservice/customerservice-dashboard/customerservice-dashboard.component';
import { CustomerserviceCreateComponent} from './customerservice/customerservice-create/customerservice-create.component';
import { CustomerserviceCreditfileComponent}  from './customerservice/customerservice-creditfile/customerservice-creditfile.component';
import { AdminCreditfileComponent} from './admin/admin-creditfile/admin-creditfile.component';
import { AdminTraceComponent } from './admin/admin-trace/admin-trace.component';
import { AdminManagecorporatecustomerComponent } from './admin/admin-managecorporatecustomer/admin-managecorporatecustomer.component';
import { AdminAddcorporatecustomerComponent} from './admin/admin-addcorporatecustomer/admin-addcorporatecustomer.component';
import { AdminBillingreportComponent} from './admin/admin-billingreport/admin-billingreport.component';
import { CorpLoginComponent} from './login/corp-login/corp-login.component';
import { CorpDashboardComponent} from './layout/dashboard/corp-dashboard/corp-dashboard.component';
import { CorpSidebarComponent} from './layout/sidebar/corp-sidebar/corp-sidebar.component';
import { CorpCreditfileComponent } from './corp/corp-creditfile/corp-creditfile.component';
import { CorpMyprofileComponent } from './corp/corp-myprofile/corp-myprofile.component';
import { CorpResetpasswordComponent } from './corp/corp-resetpassword/corp-resetpassword.component';
import { CorpAddclientComponent } from './corp/corp-addclient/corp-addclient.component';

import { CorpUpdateclientComponent } from './corp/corp-updateclient/corp-updateclient.component';
import { AdminClientchallengesComponent } from './admin/admin-clientchallenges/admin-clientchallenges.component';
import { CorpClientchallengesComponent } from './corp/corp-clientchallenges/corp-clientchallenges.component';
const routes: Routes = [
  { path: '',  component: ClientLoginComponent },
  { path: 'client-login',  component: ClientLoginComponent },
  { path: 'client-profile',  component: ClientProfileComponent },
  { path: 'admin', component: AdminLoginComponent },
  { path: 'signup', component: SignUpComponent },
  { path: 'dashboard/admin', component:AdminMasterComponent},
  { path: 'manage-staff', component:ManageStaffComponent},
  { path: 'create-staff',component:CreateStaffComponent},
  { path: 'admin-studentlist',component:AdminStudentlistComponent},
  { path: 'admin-editstudent',component:AdminEditstudentComponent},
  { path: 'update-profile',component:AdminEditstudentComponent},
  { path: 'upload-student',component:UploadStudentComponent},
  { path: 'client-dashboard',component:ClientDashboardComponent},
  { path: 'credit-items',component:CreditItemsComponent},
  { path: 'manage-billing',component:ManageBillingComponent},
  { path: 'client-billing',component:ClientBillingComponent},
  { path: 'client-reset-password',component:ClientResetPasswordComponent},
  { path: 'client-info',component:ClientInfoComponent},
  { path: 'manage-accounttype',component:ManageAccounttypeComponent},
  { path: 'create-accounttype',component:CreateAccounttypeComponent},
  { path: 'client-challenges',component:ClientChallengesComponent},
  { path: 'service-settings',component:ServiceSettingsComponent},
  { path: 'admin-addchallenges',component:AdminAddchallengesComponent},
  { path: 'admin-managechallenges',component:AdminManagechallengesComponent},
  { path: 'admin-addpricing',component:AdminAddpricingComponent},
  { path: 'admin-managepricing',component:AdminManagepricingComponent},
  { path: 'admin-customerservice',component:AdminCustomerserviceComponent},
  { path: 'admin-managecustomerservice',component:AdminManagecustomerserviceComponent},
  { path: 'customerservice',component:CustomerserviceLoginComponent},
  { path: 'customerservice-dashboard',component:CustomerserviceDashboardComponent},
  { path: 'customerservice-client',component:CustomerserviceCreateComponent},
  { path: 'customerservice-creditfile',component:CustomerserviceCreditfileComponent},
  { path: 'admin-creditfile',component:AdminCreditfileComponent},
  { path: 'admin-trace',component:AdminTraceComponent},
  { path: 'admin-corporatecustomer',component:AdminManagecorporatecustomerComponent},
  { path: 'admin-addcorporatecustomer',component:AdminAddcorporatecustomerComponent},
  { path: 'admin-billingreport',component:AdminBillingreportComponent},
  { path: 'corp',component:CorpLoginComponent},
  { path: 'corp-dashboard',component:CorpDashboardComponent},
  { path: 'corp-sidebar',component:CorpSidebarComponent},
  { path: 'corp-creditfile',component:CorpCreditfileComponent},
  { path: 'corp-profile',component:CorpMyprofileComponent},
  { path: 'corp-reset-password',component:CorpResetpasswordComponent},
  { path: 'corp-addclient',component:CorpAddclientComponent},
  { path: 'corp-updateclient',component:CorpUpdateclientComponent},
  { path: 'admin-clientchallenges',component:AdminClientchallengesComponent},
  { path: 'corp-clientchallenges',component:CorpClientchallengesComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  
}
