import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-admin-addpricing',
  templateUrl: './admin-addpricing.component.html',
  styleUrls: ['./admin-addpricing.component.css']
})
export class AdminAddpricingComponent implements OnInit {
  public Pricing:FormGroup;
  public id:string='';
  public PtypeId:string='';
  
  public htext:string='';
  public btext:string='';

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) 
   {
    this.Pricing=this.fb.group({
      PricingType:'',
      PricingTypeDesc:'',
      PricePerMonth:'',
      SetupFee:''
    });
  }


  ngOnInit(): void {
    debugger
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){        
       if(user.userRole=="admin")
       {
        this.htext="Create Pricing";
        this.btext="Save";
        this.id="";  
        this.id = user.userID; 
        this.PtypeId = this.route.snapshot.queryParams["PricingId"];
        if(this.PtypeId!='' && this.PtypeId!=null){
               this.htext="Update Pricing";
               this.btext="Update";
          let obj={
            key:"123",            
            id:parseInt(this.PtypeId)
          }
          this.loginService.GetPricingsById(obj).subscribe(res=>{     
            debugger   
           if(res.status==1){       
             debugger
             this.Pricing=this.fb.group({              
              PricingType:res.data[0].pricingType,
              PricingTypeDesc:res.data[0].pricingTypeDesc,
              PricePerMonth:res.data[0].pricePerMonth,
              SetupFee:res.data[0].setupFee,
              
             });
             
           }
         });
 
        }
       }    
    }
  }
  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;

  // }

//    decimalOnly(evt, obj) {

//     var charCode = (evt.which) ? evt.which : evt.keyCode
//     var value = obj.value;
//     var dotcontains = value.indexOf('.') != -1;
//     if (dotcontains)
//         if (charCode == 46) return false;
//     if (charCode == 46) return true;
//     if (charCode > 31 && (charCode < 48 || charCode > 57))
//         return false;
//     return true;
// }

  decimalOnly(evnt) {    
    debugger
    // var charC = (evnt.which) ? evnt.which : evnt.keyCode; 
    //         if (charC == 46) { 
    //             if (el.value.indexOf('.') != -1) { 
    //                 return false; 
    //             } else { 
    //                 return true; 
    //             } 
    //         } else { 
    //             if (charC > 31 && (charC < 48 || charC > 57)) 
    //                 return false; 
    //         } 
    //         return true; 
    var charCode = (evnt.which) ? evnt.which : evnt.keyCode;
          if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
             return false;

          return true;
       
  }


  btnSave(){
    debugger
      if(!this.Pricing.invalid){   
        if(this.PtypeId!="" && this.PtypeId!=null && this.PtypeId!=undefined){    
          let obj={
            key:"123",    
            PricingType:this.Pricing.value.PricingType,
            PricingTypeDesc:this.Pricing.value.PricingTypeDesc,
            PricePerMonth:parseFloat(this.Pricing.value.PricePerMonth),
            SetupFee:parseFloat(this.Pricing.value.SetupFee),
            PricingId:parseInt(this.PtypeId)          
            
          }
          this.loginService.InsertPricings(obj).subscribe(res=>{  
                 debugger
            if(res.status==1){
              debugger
              window.location.href='admin-managepricing';
            }
            else
            {
              Swal.fire('Success','pricing not saved','success');
              return;
            }
          });
        }
        else
        {
          let obj={
            key:"123",    
            PricingType:this.Pricing.value.PricingType,
            PricingTypeDesc:this.Pricing.value.PricingTypeDesc,
            PricePerMonth:parseFloat(this.Pricing.value.PricePerMonth),
            SetupFee:parseFloat(this.Pricing.value.SetupFee),             
            PricingId:0
          }
          this.loginService.InsertPricings(obj).subscribe(res=>{  
            debugger        
            if(res.status==1){
              $("#PricingType").val('');
              $("#PricingTypeDesc").val('');
              $("#PricePerMonth").val('');
              $("#SetupFee").val('');
              Swal.fire('Success','Pricing saved successfully','success');
              return;
            }
            else
            {
              Swal.fire('Warning','Pricing not saved','warning');
              return;
            }
          });
         
        }
     }
      else
      {
        Swal.fire('warning','Please enter all the fields','warning');
        return;
      }
       
    }
}
