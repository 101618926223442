<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="client-dashboard">Dashboard</a></li>
                        <li class="breadcrumb-item active text-primary" aria-current="page"><span>Billing</span></li>
                    </ol>
                </div>
               
            </div>
            <div class="row">

            <div class="col-md-12 text-right mb-3">
                <button class="btn btn-primary" (click)="CreateBilling()">New Payment Method</button>
            </div>

        </div>
        <div class="card">
                <div class="card-header  bg-light"><b class="text-secondary">Manage Billing Details</b></div>          
                <div class="card-body">
                   <div class="row">
                       <div class="col-lg-12">
                        <div class="table-responsive">
                            <table id="tblBilling" class="table table-striped table-bordered">
                              <thead>
                                <tr>
                                    <!-- <th>Billing Type</th>                                        -->
                                    <th>Card Type</th>
                                    <th>Card Number</th>
                                    <th>Expiry Date</th>
                                    <th>Is Primary</th>
                                    <th>Action</th>   
                                </tr>
                              </thead>
                                <tbody>
                                    <tr *ngFor="let data of rowDataGrid">
                                        <!-- <td>{{data.BillingType}}</td> -->
                                        <td>{{data.CardType}}</td>                                       
                                        <td>{{data.CardNumber}}</td>                                      
                                        <td>{{data.EDate}}</td>
                                        <td>{{data.PrimaryCard}}</td>
                                        <td> <a href="client-billing?billingId={{data.Bid}}" class="btn btn-success fa fa-edit" title="Edit"></a>&nbsp;
                                            <button *ngIf="data.PrimaryCard !='Yes'" (click)="Delete(data.Bid)" class="btn btn-danger fa fa-trash" title="Delete"></button>
                                           </td>                                                   
                                      </tr>
                                </tbody>
                            </table>
                        </div>
                       </div>

                   </div>
                </div>
        </div>
                
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->