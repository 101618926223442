<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <!-- <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div> -->
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
       <div class="layout-px-spacing">
         <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <nav class="breadcrumb-one" aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                    <li class="breadcrumb-item active text-primary" aria-current="page"><span>Manage Trace</span></li>
                  </ol>
               </nav>
                 <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                         <div class="card-header bg-secondary">
                            <span class="text-white"><b>Manage Trace</b></span>
                          </div>
                          <div class="card-body">
                            <div class="row pl-3">
                                <div class="col-lg-12">
                                    <button (click)="DeleteAll()" class="btn btn-danger" title="Delete All">Delete All</button>
                                </div>
                            </div>
                              <div class="table-responsive" style="padding-top:15px">
                                <table id="tblTrace" class="table  table-bordered table-striped"  width="100%">
                                  <thead>
                                    <tr>
                                        <th>Exception</th>                                       
                                        <th>Error</th>
                                        <th>Trace Date</th>                                                                              
                                        <th>Actions</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      <tr *ngFor="let item of rowDataGrid">
                                        <td style="width:350px;">{{item.Exception}}</td>                                       
                                        <td style="width:250px;">{{item.Error}}</td>
                                        <td style="width:100px;">{{item.TraceDate}}</td>
                                         <td style="width:100px;">
                                         
                                          <button (click)="Delete(item.TraceId)" class="btn btn-danger fa fa-trash" title="Delete"></button>
                                        </td>
                                      </tr>
                                    </tbody> 
                                   
                                   </table>
                            </div> 
                          

                            </div>
                          </div>
                        </div>
                    </div>  
                
           
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>
</div>

    <!--  END CONTENT AREA  -->
 
<!-- END MAIN CONTAINER -->
</div>