import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
declare var $;

@Component({
  selector: 'app-admin-clientchallenges',
  templateUrl: './admin-clientchallenges.component.html',
  styleUrls: ['./admin-clientchallenges.component.css']
})
export class AdminClientchallengesComponent implements OnInit {
  public ClientChallenges:FormGroup;
  public id:string='';
  public ddlRefCode:string='';
  public CorpCustomer:any=[];
  public rowDataGrid:any=[];
  public active:string='';
  public empt:string='';
 
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) {

      this.ClientChallenges=this.fb.group({    
        refCode:''
       });
      //  setTimeout(function(){
      //   $(function(){
      //    var c2=  $("#tblClientChallenges").DataTable({
      
      //         headerCallback:function(e, a, t, n, s) {
      //             e.getElementsByTagName("th")[0].innerHTML='<label class="new-control new-checkbox checkbox-outline-info m-auto">\n<input type="checkbox" class="new-control-input chk-parent select-customers-info" id="customer-all-info">\n<span class="new-control-indicator"></span><span style="visibility:hidden">c</span>\n</label>'
      //         },
      //         columnDefs:[ {
      //             targets:0, width:"30px", className:"", orderable:!1, render:function(e, a, t, n) {
      //                 return'<label class="new-control new-checkbox checkbox-outline-info  m-auto">\n<input type="checkbox" class="new-control-input child-chk select-customers-info" id="customer-all-info">\n<span class="new-control-indicator"></span><span style="visibility:hidden">c</span>\n</label>'
      //             }
      //         }],             
             
      //     });
  
      //     multiCheck(c2);
  
      //       }); 
      //     },3000);
         }

  ngOnInit(): void {
    this.rowDataGrid=[];
    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined)
    {        
       if(user.userRole=="admin")
       {
          this.id = user.userID;  
          this.GetCorporateCustomer();
       }
    }
  }

  GetCorporateCustomer(){
    let obj={
   key:"123",          
 }
 this.loginService.GetCorporateCustomers(obj).subscribe(res=>{           
   if(res.status==1){                  
     for(let i=0;i<res.data.length;i++){                     
        let objCorp={
            rowId:res.data[i].rowId,
            refCode:res.data[i].refCode
         }
         this.CorpCustomer.push(objCorp);
        }
      }
    });
  }

  btnSubmit(){

     this.ddlRefCode=this.ClientChallenges.value.refCode;   
     if(this.ddlRefCode!=""){     
      let objrCode={
       key:"123",
       refcode:this.ddlRefCode      
      }
      this.loginService.GetChallengesToAdmin(objrCode).subscribe(res=>{
          
          if(res.status==1){
            this.rowDataGrid=[];
            for(let i=0;i<res.data.length;i++){                  
               let objCorporate={
                   RefCode:res.data[i].refCode,    
                   Fullname:res.data[i].clientName,
                   RoundType:res.data[i].roundType,
                   Agency:res.data[i].cAgency,
                   Filename:res.data[i].crFilename,
                   creditRepFileId:res.data[i].creditRepFileId +'~'+ res.data[i].clientId   
                  } 
                  this.rowDataGrid.push(objCorporate);          
            }  
          }
          else
          {       
             this.rowDataGrid=[];
          }     
      });
    }
    else
    {
      location.href='admin-clientchallenges';
    }

  }
  
  
   checkAll() {    
     $("#selectAll").on("click", function () {
         if ($("input:checkbox").prop("checked")) {
            $("input:checkbox[name='row-check']").prop("checked", true);
         } else {
          $("input:checkbox[name='row-check']").prop("checked", false);
      }
    });
  }

  checkAllCheckBox(){
   // Check event on each table row checkbox
      $("input:checkbox[name='row-check']").on("change", function () {
         var total_check_boxes = $("input:checkbox[name='row-check']").length;
         var total_checked_boxes = $("input:checkbox[name='row-check']:checked").length;

          // If all checked manually then check selectAll checkbox
        if (total_check_boxes === total_checked_boxes) {
          $("#selectAll").prop("checked", true);
        }
        else {
          $("#selectAll").prop("checked", false);
      }
    });
  }


  btnMark(){
    var open = [];
    var total_checked_boxes = $("input:checkbox[name='row-check']:checked").length;
    if(total_checked_boxes==0){
      Swal.fire({
        icon: 'warning',
        title:'Warning',
        text: 'Please select atleast one checkbox'
      });
      return;
    }   

    $.each($("input:checkbox[name='row-check']:checked"), function () {
        var arrFields=$(this).val().split('~');
        var lid=parseInt(arrFields[0]);
        var cid=parseInt(arrFields[1]);

        let obj={
          key:"123",
          ClientId:cid,
          LetterId:lid
        }
        open.push(obj);
    });
    this.loginService.InsertLetterPrintAudit(open).subscribe(res=>{
      
      if(res.status==1){
        
        Swal.fire('Success','Letter(s) mark as print successfully.').then(function(){
            window.location.href='admin-clientchallenges';     
           });
        }
      else
      {
        Swal.fire('Error',res.message);
        return;
      }
    });
    

  }

  ViewPDF(file)
  {

    var base64EncodedPDF="";
    this.loginService.getPdf(file).subscribe(res=>{
      if(res.status==1){
          var sampleArr = this.loginService.base64ToArrayBuffer(res.data);
          this.loginService.saveByteArray(file, sampleArr);
      }
    });
  }
 
}
function multiCheck(c2: any) {
  throw new Error('Function not implemented.');
}

