<app-header></app-header>
<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <app-staff-sidebar></app-staff-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div class="col-md-12">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                        <!-- <li class="breadcrumb-item active text-primary" aria-current="page"><span>My Profile</span></li> -->
                    </ol>
                </div>
            </div>
            <div class="card">
                <div class="card-header  bg-light"><b class="text-secondary">Client Dashboard</b></div>
                <div class="card-body">
                    <h5 style="color: red;">Recommended time frame -30 days from last challenge</h5>
                    <div class="row">
                        
                        <div class="col-lg-4">                                         
                            <div class="card">
                                <div class="card-header text-primary">
                                  Credit File
                                 </div>                        
                                 <ul class="list-group list-group-flush">
                            <li class="list-group-item">Date of Last review: <label  id="dateReportPulls"></label> </li>
                            <li class="list-group-item"># items: <label  id="totitems"></label> </li>
                            <li class="list-group-item"># Negative items: <label  id="negativeItems"></label> </li>
                          <li class="list-group-item">Current Status: <label  id="roundType"></label> </li>
                          <li class="list-group-item">Next Action: <label  id="nextRound"></label> </li>
                          <li class="list-group-item">Due Date: <label class="span1" id="nextDueDate"></label> </li>
                          <input type="button" (click)="CreditPull()" id="btnCreditPull" class=" btn btn-primary" 
                          value="Refresh Credit Report">
                          <input type="button" (click)="ViewCreditItems()" id="btnViewCreditPull" class=" btn btn-primary" 
                          value="View Credit Report">
                                 </ul>                    
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <div class="card">
                                <div class="card-header text-primary">
                                    Challenges for Negative Items
                                </div> 
                                <div class="card-body">
                                    <canvas  id="chart" baseChart 
                                    [datasets]="barChartData"
                                    [labels]="barChartLabels"
                                    [options]="barChartOptions"
                                    [colors]="barChartColors"
                                    [legend]="barChartLegend"
                                    [chartType]="barChartType">
                                  </canvas>
                                </div>                               
                            </div>
                        </div>
                    </div>   

                    <div id="toggleAccordion" class="accordion">
                        <div class="row pt-4">
                            <div class="col-lg-12">
                               <div class="card">
                                   <div class="card-header collapsed text-primary" id="CreditScore" data-toggle="collapse" data-target="#apcreditscore" aria-expanded="true" aria-controls="apcreditscore">
                                        Credit Score
                                   </div>
                                   <div id="apcreditscore" class="collapse" aria-labelledby="CreditScore" data-parent="#toggleAccordion">

                                    <div class="card-body text-secondary">
                                   <div class="table-responsive">
                                        <table id="tblCreditscore" class="table  table-bordered">                                               
                                       </table>
                                   </div>
                                    </div> 
                               </div>                           
                           </div>
                       </div>
                   </div>                  
                   
                   <div class="row pt-4">
                       <div class="col-lg-12">
                           <div class="card">
                               <div class="card-header collapsed text-primary" id="ChallengeHistory" data-toggle="collapse" data-target="#apChallengeHistory" aria-expanded="true" aria-controls="apChallengeHistory">
                                   Challenge History
                               </div>
                               <div id="apChallengeHistory" class="collapse" aria-labelledby="ChallengeHistory" data-parent="#toggleAccordion">

                               <div class="card-body text-secondary">
                                   <div class="table-responsive">
                                    <table id="tblChallengeHistory" class="table  table-bordered">
                                       
                                        <thead>
                                           <tr>
                                               <th>Merchant</th>
                                               <th>#Number</th>
                                               <th>Round-1</th>
                                               <th>Round-2</th>
                                               <th>Round-3</th>
                                           </tr>                               
                                       </thead>
                                       <tbody>
                                           <tr *ngFor="let data of rowDataGridChallenge">
                                               <td>{{data.Merchant}}</td>
                                               <td>{{data.Account}}</td>
                                               <td>{{data.Round1}}</td>
                                               <td>{{data.Round2}}</td>
                                               <td>{{data.Round3}}</td>
                                           </tr>
                                       </tbody>
                                       </table>
                                      </div>
                               </div>
                           </div>
                           </div>
                       </div>
                   </div>
                  
                   <div class="row pt-4">
                       <div class="col-lg-12">
                           <div class="card">
                               <div class="card-header collapsed text-primary" id="AccountHistory" data-toggle="collapse" data-target="#apAccountHistory" aria-expanded="true" aria-controls="apAccountHistory">
                                   Account History
                               </div>
                               <div id="apAccountHistory" class="collapse" aria-labelledby="AccountHistory" data-parent="#toggleAccordion">

                               <div class="card-body text-secondary">
                                   <div class="table-responsive">
                                    <div class="card-body text-secondary">
                                        <div class="table-responsive">
                                        <table id="tblAccountHistory" class="table  table-bordered">
                                           
                                            <thead>
                                                <tr><td colspan="1" class="text-danger"><strong>{{R1}}</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdate}}</td></tr>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <th>Open Date</th>
                                                    <th>EQUIFAX</th>
                                                    <th>EXPERIAN</th>
                                                    <th>TRANSUNION</th>
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                
                                                <tr *ngFor="let data of rowDataGridAccount">
                                                    <td>{{data.Merchant}}</td>
                                                    <td>{{data.OpenDate}}</td>
                                                    <td>{{data.Equifax}}</td>
                                                    <td>{{data.Experian}}</td>
                                                    <td>{{data.Transunion}}</td>
                                                </tr>
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                    </div>
                                    <div class="card-body text-secondary">
                                        <div class="table-responsive">
                                        <table id="tblAccountHistoryRound2"  class="table  table-bordered">
                                           
                                            <thead>
                                                <tr><td colspan="1" class="text-danger"><strong>Round-2</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdateRound2}}</td></tr>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <th>Open Date</th>
                                                    <th>EQUIFAX</th>
                                                    <th>EXPERIAN</th>
                                                    <th>TRANSUNION</th>
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                
                                                <tr *ngFor="let data of rowDataGridAccountRound2">
                                                    <td>{{data.Merchant}}</td>
                                                    <td>{{data.OpenDate}}</td>
                                                    <td>{{data.Equifax}}</td>
                                                    <td>{{data.Experian}}</td>
                                                    <td>{{data.Transunion}}</td>
                                                </tr>
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                    </div>
                                    <div class="card-body text-secondary">
                                        <div class="table-responsive">
                                        <table id="tblAccountHistoryRound3" class="table  table-bordered">
                                           
                                            <thead>
                                                <tr><td colspan="1" class="text-danger"><strong>Round-3</strong></td><td colspan="4" class="text-danger"><strong>Challenge Date</strong> :{{cdateRound3}}</td></tr>
                                                <tr>
                                                    <th>Merchant</th>
                                                    <th>Open Date</th>
                                                    <th>EQUIFAX</th>
                                                    <th>EXPERIAN</th>
                                                    <th>TRANSUNION</th>
                                                </tr>                               
                                            </thead>
                                            <tbody>
                                                
                                                <tr *ngFor="let data of rowDataGridAccountRound3">
                                                    <td>{{data.Merchant}}</td>
                                                    <td>{{data.OpenDate}}</td>
                                                    <td>{{data.Equifax}}</td>
                                                    <td>{{data.Experian}}</td>
                                                    <td>{{data.Transunion}}</td>
                                                </tr>
                                            </tbody>
                                            
                                        </table>
                                    </div>
                                    </div>
    
                               </div>
                               </div>
                           </div>
                           </div>
                       </div>
                   </div>
                   
               </div>
        </div>
                
        </div>
    </div>
</div>
        <app-footer></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>

<!-- END MAIN CONTAINER -->
