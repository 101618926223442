<div class="container">
    <form [formGroup]="userInfo">
    <div class="row pt-5">
        <div class="col-lg-12">
            <span style="padding-top:20px;color: darkgreen;text-align: center;font-size:20px;">{{success}}</span>

            <span style="padding-top:20px;color: red;text-align:center;font-size:20px;">{{fail}}</span>
            
            <span id="lblConfirm" class="text-center" style="padding-top:20px;"><h4>Thank you for confirming your account.<a href='client-login' class="text-primary">Login now to continue</a></h4></span>
            
        </div>
    </div>
</form>
</div>
