<!--  BEGIN SIDEBAR  -->
   
<nav id="sidebar">
    <div class="shadow-bottom"></div>
    <ul class="list-unstyled menu-categories" id="accordionExample">
        <li class="menu">
            <a href="customerservice-dashboard" data-active="true"  aria-expanded="true" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                    <span>Dashboard</span>
                </div>
               
            </a>
          
        </li>           
        <li class="menu">
            <a routerLink="/customerservice" aria-expanded="false" class="dropdown-toggle">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                    <span>Sign Out</span>
                </div>
            </a>
        </li>

   
     
        
    </ul>
    <!-- <div class="shadow-bottom"></div> -->
    
</nav>



<!--  END SIDEBAR  -->
<html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
        <script type="text/javascript">
         var gearPage = document.getElementById('GearPage');
         if(null != gearPage)
         {
             gearPage.parentNode.removeChild(gearPage);
             document.title = "Error";
         }
         </script>
         </html><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
        <script type="text/javascript">
         var gearPage = document.getElementById('GearPage');
         if(null != gearPage)
         {
             gearPage.parentNode.removeChild(gearPage);
             document.title = "Error";
         }
         </script>
         </html>