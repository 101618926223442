<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-12">           
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                           <li class="breadcrumb-item active text-primary" aria-current="page"><span>Service Settings</span></li>
                        </ol>        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-light">
                                <span class="text-secondary"><b>Service Settings</b></span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="serviceSettings" novalidate>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="SendGridAPIKey">Send Grid API Key<span class="text-danger">*</span></label> </div>
                                                <div class="col-md-6">
                                                    <input type="text" formControlName="SendGridAPIKey" 
                                                    class="form-control" id="SendGridAPIKey" required />
                                                    
                                                </div>
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">From Email<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="FromEmail" formControlName="FromEmail" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Authorize.Net API Login Id<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="AuthorizeAPILoginId" formControlName="AuthorizeAPILoginId" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">API Transaction Key<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="APITransactionKey" formControlName="APITransactionKey" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Secret Key<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="SecretKey" formControlName="SecretKey" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Authorize.Net Environment<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="AuthorizeEnvironment" formControlName="AuthorizeEnvironment" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="isPaypal">IsPaypal <span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <label class="radio-inline"><input type="radio" name="isPaypal" id="isPaypalYes" value="True">&nbsp;Yes</label>&nbsp;
                                                    <label class="radio-inline"><input type="radio" name="isPaypal" id="isPaypalNo"  value="False">&nbsp;No</label>                                                  
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalAPIUserName">PayPal APIUserName<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalAPIUserName" formControlName="PayPalAPIUserName" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalAPIPassword ">PayPal APIPassword <span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalAPIPassword" formControlName="PayPalAPIPassword" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalAPISignature">PayPal APISignature<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalAPISignature" formControlName="PayPalAPISignature" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalSandBoxServer">PayPal SandBoxServer<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalSandBoxServer" formControlName="PayPalSandBoxServer" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalLiveServer">PayPal LiveServer<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalLiveServer" formControlName="PayPalLiveServer" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalAPIVersion ">PayPal APIVersion<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalAPIVersion" formControlName="PayPalAPIVersion" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalPaymentMethod">PayPal PaymentMethod<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalPaymentMethod" formControlName="PayPalPaymentMethod" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="PayPalEnvironment">PayPal Environment<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PayPalEnvironment" formControlName="PayPalEnvironment" required/>                                                   
                                                </div>                                            
                                            </div>





                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">14 Days Mail Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="DaysMailLine1" formControlName="DaysMailLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">14 Days Mail Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="DaysMailLine2" formControlName="DaysMailLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">7 Days Mail Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="SevenDaysMailLine1" formControlName="SevenDaysMailLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">7 Days Mail Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="SevenDaysMailLine2" formControlName="SevenDaysMailLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Payment Success Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PaymentSuccessLine1" formControlName="PaymentSuccessLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Payment Success Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PaymentSuccessLine2" formControlName="PaymentSuccessLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Payment Failure Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PaymentFailureLine1" formControlName="PaymentFailureLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Payment Failure Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="PaymentFailureLine2" formControlName="PaymentFailureLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Next Attempt Mail Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="NextAttemptMailLine1" formControlName="NextAttemptMailLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Next Attempt Mail Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="NextAttemptMailLine2" formControlName="NextAttemptMailLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Second Payment Failure Line1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="SecondPaymentFailureLine1" formControlName="SecondPaymentFailureLine1" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Second Payment Failure Line2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="SecondPaymentFailureLine2" formControlName="SecondPaymentFailureLine2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Challenges Path<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="ChallengesPath" formControlName="ChallengesPath" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Challenges Path Result<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="ChallengesPathResult" formControlName="ChallengesPathResult" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">MailXStream URL<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="MailXStreamURL" formControlName="MailXStreamURL" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">MX UserId<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="MXUserId" formControlName="MXUserId" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">MX Password<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="MXPassword" formControlName="MXPassword" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">MX Template<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="MXTemplate" formControlName="MXTemplate" required/>                                                   
                                                </div>                                            
                                            </div>



                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Host<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="host" formControlName="host" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Pdf Key<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="pdfKey" formControlName="pdfKey" required/>                                                   
                                                </div>                                            
                                            </div>
                                           
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="AccountDetails">Storage AccessKey<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="storageAccessKey" formControlName="storageAccessKey" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="storageAccountName">Storage AccountName<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="storageAccountName" formControlName="storageAccountName" required/>                                                   
                                                </div>                                            
                                            </div>
                                           
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="storageAccountName">Storage Container<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="storageContainer" formControlName="storageContainer" required/>                                                   
                                                </div>                                            
                                            </div>
                                           
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EQADDRESS1">EQADDRESS1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EQADDRESS1" formControlName="EQADDRESS1" required/>                                                   
                                                </div>                                            
                                            </div>
                                           
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EQADDRESS2">EQADDRESS2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EQADDRESS2" formControlName="EQADDRESS2" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EQADDRESS3">EQADDRESS3<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EQADDRESS3" formControlName="EQADDRESS3" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EXADDRESS1">EXADDRESS1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EXADDRESS1" formControlName="EXADDRESS1" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EXADDRESS2">EXADDRESS2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EXADDRESS2" formControlName="EXADDRESS2" required/>                                                   
                                                </div>                                            
                                            </div>
 
                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="EXADDRESS3">EXADDRESS3<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="EXADDRESS3" formControlName="EXADDRESS3" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="TUADDRESS1">TUADDRESS1<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="TUADDRESS1" formControlName="TUADDRESS1" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="TUADDRESS2">TUADDRESS2<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="TUADDRESS2" formControlName="TUADDRESS2" required/>                                                   
                                                </div>                                            
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-3">
                                                    <label for="TUADDRESS3">TUADDRESS3<span class="text-danger">*</span></label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="text" class="form-control"  
                                                    id="TUADDRESS3" formControlName="TUADDRESS3" required/>                                                   
                                                </div>                                            
                                            </div>
 
 

                                            <div class="row pt-3">
                                                <div class="col-md-3"></div>
                                                <div class="col-md-6">
                                                     <button type="button" class="btn btn-primary" (click)="btnSave()">Save</button>&nbsp;
                                                    <a href="dashboard/admin" class="btn btn-danger">Cancel</a> 

                                                </div>
                                            </div>

                                               
                                        </form>
                                       
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                </div>   
            </div>   
            <app-footer></app-footer>    
        </div>
    </div>

    <!--  END CONTENT AREA  -->
    </div>
</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>