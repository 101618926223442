import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";
declare var $;


@Component({
  selector: 'app-admin-managecorporatecustomer',
  templateUrl: './admin-managecorporatecustomer.component.html',
  styleUrls: ['./admin-managecorporatecustomer.component.css']
})
export class AdminManagecorporatecustomerComponent implements OnInit {
  public rowDataGrid:any=[];
  public rowValue:string="";
  public id:string='';
  public active:string='';
  
 
  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private route:ActivatedRoute,private loginService: LoginService,public routing:Router)
     {
      this.titleservice.setTitle("Manage Corporate Customer");  
     
    
      setTimeout(function(){
        $(function(){
          $("#tblCorporate").DataTable({
             
            "dom": "<'row'<'col-md-2'l>|<'col-md-7 btop'B>|<'col-md-3 text-right'f>>" +
            "<'row'<'col-md-6'><'col-md-6'>>" +
            "<'row'<'col-md-12't>>" +
            "<'row'<'col-md-4'i><'col-md-8 pull-left'p>>",    
            "buttons": {
              buttons: [
                  { extend: 'csv', className: 'btn' },
                  { extend: 'excel', className: 'btn' },
                  { extend: 'print', className: 'btn' }
              ]
          },
          "oLanguage": {
            "oPaginate": { "sPrevious": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>', "sNext": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>' },
            "sInfo": "Showing page _PAGE_ of _PAGES_",
            "sSearch": '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>',
            "sSearchPlaceholder": "Search...",
           "sLengthMenu": "Results :  _MENU_",
        },     
          "stripeClasses": [],
          "lengthMenu": [7,10, 20, 50],
          "pageLength": 7
        });
      
      });
    },3000);
  }


     ngOnInit(): void {      
      let user=this.loginService.getUser();      
      if(user!=null||user!=undefined){        
         if(user.userRole=="admin")
         {
          this.id="";  
          this.id = user.userID;  
        }
      }      
      this.GetCorporateCustomerDetails(); 
    }
  
    GetCorporateCustomerDetails(){
      debugger
      if(this.id!=null && this.id!=""){    
        let obj={
          key:"123"
        }
        this.loginService.GetCorporateCustomers(obj).subscribe(res=>{   
            debugger
            if(res.status==1){          
              for(let i=0;i<res.data.length;i++){
                if(res.data[i].status=="True"){
                  this.active="Active";                 
                }
                else{
                  this.active="Inactive";                  
                }
                let objCorporate={
                  Fullname:res.data[i].firstName +" "+res.data[i].lastName,            
                  RefferalCode :res.data[i].refCode ,
                  Email:res.data[i].billingEmailAddress ,   
                  PhoneNumber:res.data[i].phoneNumber1 ,
                  Status:this.active,
                  RowId :res.data[i].rowId ,
                  chgColor:res.data[i].status=="True"?'btn btn-success':'btn btn-danger'
  
                } 
                this.rowDataGrid.push(objCorporate);
              }
              
            }
        });
      }
  
    }

    UpdateStatus(rowId,_status){
      
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to change status!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change it!'
      }).then((result) => {
        if (result.value) {  
            if(_status=="Active"){
               status="0";               
            }
            else
            {
              status="1";              
            }
          let obj={
            key:"123",
            Status:status,
            RowId:rowId
          }
              this.loginService.UpdateCorpCustomerStatus(obj).subscribe(res=>{  
                debugger                    
                if(res.status==1){   
                  Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Status changed successfully'
                  }).then(function(){
                  window.location.href='admin-corporatecustomer';
                  });
                }
                else
                {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: 'Status not changed.'
                  });
                }
              });   
        }
        
      });
    
  
    }
}
