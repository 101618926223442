import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-admin-editstudent',
  templateUrl: './admin-editstudent.component.html',
  styleUrls: ['./admin-editstudent.component.css']
})
export class AdminEditstudentComponent implements OnInit {
  
  public StudentModel: FormGroup;  
  public submitted = false;
  public flag = false;
  public studentData:any[]=[];
  public rowDataStudent:any[]=[];  
  public user: any;
  public id:string="";
  public dob: string = "";
  public firstName:string;
  public lastName:string;
  public from:string;

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 
     // this.titleservice.setTitle("Edit Student");
      this.StudentModel = fb.group({  
        RequestType:'ADD',
        id:'',       
        studentID:'',
        firstName:['',Validators.required],
        lastName:['',Validators.required],
        middleName:'',
        gender:['',Validators.required],
        dob:'',
        currentTerm:'',
        gradeName:'',
        schoolEmailAddress:['',Validators.email],
        contactNumber:'',      
        cellPhoneNumber:'',
        personalEmailAddress:'',
        maritalStatus:'',
        dependentStatus:'',
        socialSecurityNo:'',
        address:'',
        city:'',
        zipcode:'',
        state:'',
        taxYear:'',
      });
    }


  ngOnInit(): void {

    this.from = this.route.snapshot.queryParams["from"];
    if(this.from!=null&&this.from!=undefined){
      if (!this.loginService.getToken()) {
        this.routing.navigate(['admin']);
      }
    }

    let user=this.loginService.getUser();
    if(user == null)
    {
      this.routing.navigate(['admin']);
    }
    if(user!=null||user!=undefined){
      if(user.role=="staff"){
        window.location.href="admin";
      }
    }else{
      window.location.href="admin";
    }
    if(user.userRole=="admin"){
      this.titleservice.setTitle("Edit Student");
    }else if(user.role=="student"){
      this.titleservice.setTitle("Update Profile");
    }
    this.id="";
    this.user = this.loginService.getUser();
    this.id = this.route.snapshot.queryParams["id"];

    if(this.id!=null && this.id!=""){
      this.staffService.getStudentById(this.id).subscribe(res=>{
        if(res.status==1){
            this.EditFill(res);
        }
    });
  }
}

  
EditFill(rowData) {
  this.StudentModel = this.fb.group({ 
    RequestType:"UPDATE",
    id:[rowData.data.id],
    studentID:[rowData.data[0].studentID],   
    firstName:[rowData.data[0].firstName,Validators.required],    
    lastName:[rowData.data[0].lastName,Validators.required],
    middleName:[rowData.data[0].middleName],
    gender  : [rowData.data[0].gender,Validators.required],
    dob  : [rowData.data[0].dob],
    currentTerm  : [rowData.data[0].currentTerm,Validators.required],
    gradeName : [rowData.data[0].gradeName,Validators.required],   
    schoolEmailAddress : [rowData.data[0].schoolEmailAddress,Validators.required],
    personalEmailAddress : [rowData.data[0].personalEmailAddress],
    contactNumber : [rowData.data[0].contactNumber],
    cellPhoneNumber: [rowData.data[0].cellPhoneNumber],
    maritalStatus: [rowData.data[0].maritalStatus],
    dependentStatus : [rowData.data[0].dependentStatus],    
    socialSecurityNo  : [rowData.data[0].socialSecurityNo,Validators.required],
    address  : [rowData.data[0].address],
    city  : [rowData.data[0].city],
    state   : [rowData.data[0].state],
    zipcode   : [rowData.data[0].zipcode],
    taxYear   : [rowData.data[0].taxYear],
  });
  //this.disable=false;
  }
   //staff creation
  //  ValidateForm(): boolean {
  //   return true;
  // }
  get f() { return this.StudentModel.controls; }

  updateStudent() {  
    debugger
    this.submitted=true;
    if(this.StudentModel.invalid){
      return false;
    }    
    if(this.StudentModel.value.RequestType=="UPDATE")
    {     
      let obj = 
      {
        id:this.id,
        studentID:this.StudentModel.value.studentID,
        firstName:this.StudentModel.value.firstName,
        lastName:this.StudentModel.value.lastName,
        middleName:this.StudentModel.value.middleName,
        gender  : this.StudentModel.value.gender,
        dob  :this.StudentModel.value.dob,
        currentTerm  :this.StudentModel.value.currentTerm,
        gradeName : this.StudentModel.value.gradeName,
        schoolEmailAddress : this.StudentModel.value.schoolEmailAddress,
        personalEmailAddress : this.StudentModel.value.personalEmailAddress,
        contactNumber : this.StudentModel.value.contactNumber,
        cellPhoneNumber:this.StudentModel.value.cellPhoneNumber,
        maritalStatus: this.StudentModel.value.maritalStatus,
        dependentStatus :this.StudentModel.value.dependentStatus,
        socialSecurityNo:this.StudentModel.value.socialSecurityNo,
        address  :this.StudentModel.value.address,
        city  : this.StudentModel.value.city,
        state   : this.StudentModel.value.state,
        zipcode   :this.StudentModel.value.zipcode,
        taxYear   : this.StudentModel.value.taxYear,
      }
      this.staffService.UpdateStudent(obj).subscribe(res=>{
       
        if(res.status==1){
          if(this.from!="student"){
           window.location.href="admin-studentlist";
          }else{
            window.location.href="dashboard/student";
          }
        }
        else
        {
          Swal.fire({
            title: 'Warning',
            text: 'Error Updating Student Details',
            icon: 'warning',
          });
        }

      });
    }

  }


    //validation function to validate mobile number
  validateMobile(event:any){
    const pattern = /[0-9\+\-\+\{}\+\()\ ]/;
    
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }

  }

  //validate email
  validateChangeEmail(event,from,message){
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(!regex.test(event.target.value)){
       if(from=="personal email"){
        this.StudentModel.controls['personalEmailAddress'].setValue("");
      }else if(from=="school email"){
        this.StudentModel.controls['schoolEmailAddress'].setValue("");
      }
      Swal.fire("Warning!","Please enter the valid "+message+".","warning");
      return false;
    }
  }

  }