import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';



@Component({
  selector: 'app-corp-login',
  templateUrl: './corp-login.component.html',
  styleUrls: ['./corp-login.component.css']
})
export class CorpLoginComponent implements OnInit {

  public loginForm:FormGroup; 

  constructor(private fb:FormBuilder,private loginService: LoginService, private route: Router) { 
    this.loginForm=this.fb.group({
      username:'',
      password:''      
    });   
  }

  ngOnInit(): void {
    this.loginService.logout();
  
  }

  login(){
    
    if (!this.loginForm.invalid) {   
      let obj={
        key:"123",
        UserName:this.loginForm.value.username,
        UserPassword:this.loginForm.value.password
      }
      this.loginService.CorpLogin(obj).subscribe(res =>{
      
        if (res.status == 1) {
             this.loginService.storeUserData(             
             res.data.clientId,
             res.data           
           );
          //redirection
          if(res.data.clientStatus == false)
          {
               window.location.href="corp-dashboard";
          }
          else
          {
               window.location.href="corp";
          }
          
        } else {
          Swal.fire('Login Fail.', 'Please check username and password.', 'error');
        }
      }, error => {
        Swal.fire("Warning!","Error","warning");
      });
    } else {
      Swal.fire('Oops..', 'Please fill all fields.', 'error');
    }

  }
}
