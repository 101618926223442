import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Routes } from '@angular/router';
import { FormBuilder,FormControl, FormGroup,FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StaffService } from '../../shared/staff/staff.service';
import { LoginService } from '../../shared/login/login.service';
import { Route } from '@angular/compiler/src/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignUpComponent implements OnInit { 
 public emails:string
 public mode:string='';

  SignUpForm = this.fb.group({
    firstname:'',
    lastname:'',
    emailaddress:'',
    password:'',
    cnfpassword:'',
    refcode:''
  });

  constructor(private titleservice:Title,private fb: FormBuilder, private httpClient: HttpClient,
    private staffService: StaffService,private route:ActivatedRoute,private loginService: LoginService,public routing:Router) { 
      this.titleservice.setTitle("Sign Up");
      
    }

  ngOnInit(): void {
  }


  //staff creation
  ValidateForm(): boolean {
    return true;
  }

  get f(){return this.SignUpForm.controls;}

  btnSubmit(){ 
  debugger
    if(!this.SignUpForm.invalid){     
        if(this.SignUpForm.value.password!=this.SignUpForm.value.cnfpassword){
             Swal.fire('Warning','Confirm Password not matched with Password','warning');
             return;
        }
      
     var semail=this.SignUpForm.value.emailaddress;
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!regex.test(semail)){
      $("#emailaddress").focus();
      Swal.fire("Warning!","Please enter valid Email Address.","warning");
      $("#emailaddress").val("");
      
      return 
    } 

    // var _refcode=this.SignUpForm.value.refcode;
    // if(_refcode!="123456"){
    //   $("#refcode").focus();
    //   Swal.fire("Warning!","Please enter valid Referral Code.","warning");
    //   $("#refcode").val("");
      
    //   return 
    // }

        let objs={
                    key:"123",
                    email:this.SignUpForm.value.emailaddress
                 }
                 this.loginService.CheckEmailExists(objs).subscribe(res=>{                   
                     if(res.status==1){     
                        $("#emailaddress").focus();          
                        Swal.fire('Warning','Email Address already exists','warning');
                        $("#emailaddress").val("");
                        return;
                      }
                      else
                      {
                         let obj={
                           key:"123",
                           FirstName:this.SignUpForm.value.firstname,
                           LastName:this.SignUpForm.value.lastname,
                           CurrentEmail:this.SignUpForm.value.emailaddress,
                           Password:this.SignUpForm.value.password,
                           refCode:this.SignUpForm.value.refcode,
                           
                         }
                         this.loginService.AddClient(obj).subscribe(res=>{    
                           debugger                     
                         if(res.status==1){
                           // Swal.fire('Success','SignUp completed successfully.','success');
                            //return;
                            window.location.href='client-info?mode=success';
                          }
                          else{
                                Swal.fire('Error',res.message +' '+ res.data,'error')
                                return;
                                //window.location.href='client-info?mode=fail';
                              }
                        });
                    }
                });   
    }
    else
    {
           Swal.fire('Warning', 'Please fill all required fields.', 'warning');
           return;
    }
  }
  
 
  emailvalidation(){        
      var semail=this.SignUpForm.value.emailaddress;
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if(!regex.test(semail)){

        $("#emailaddress").focus();
        Swal.fire("Warning!","Please enter valid Email Address.","warning");
        $("#emailaddress").val("");
      return false;
    } 
    
  }
  
}
