<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
              <li class="breadcrumb-item active text-primary" aria-current="page"><span>Manage Billing Report</span></li>
            </ol>
         </nav>
         <div class="card">
              <div class="card-header  bg-light"><b class="text-secondary">Manage Billing Report</b></div>
                   <div class="card-body">
                    <form [formGroup]="CorporateCustomer" novalidate>
                                       
                    <div class="row">
                        <div class="col-md-2 pt-2">
                            <label for="validationDefault14">Referral Code :</label>                            
                        </div>
                        <div class="col-md-3 mb-4">
                            <select class="form-control" id="refcode" formControlName="refCode" required>
                                <option value="">Choose Referral Code</option>
                            <option *ngFor="let corp of CorpCustomer" value="{{corp.refCode}}">{{corp.refCode}}</option>
                            
                            </select>                            
                        </div>
                        <div class="col-md-3 pt-1">
                            <button type="button" class="btn btn-primary" (click)="btnSubmit()">Submit</button>&nbsp;
                                                   
                        </div>
                    </div>

                    <div class="table-responsive">
                      <table id="tblBilling" class="table  table-bordered">
                        <thead>
                          <tr>                           
                            <th>Full Name</th>
                            <th>Referral Code</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Billing Month</th>
                            <th>Challenges Sent</th>
                            <th>Letters Sent</th>
                            <!-- <th>Status</th>           -->
                          </tr>
                        </thead>
                         <tbody>
                         <tr *ngFor="let data of rowDataGrid">
                           <td>{{data.Fullname}}</td>    
                           <td>{{data.RefCode}}</td>                           
                           <td>{{data.Email}}</td>
                           <td>{{data.PhoneNumber}}</td>     
                           <td>{{data.BillingMonth}}</td>     
                           <td>{{data.ChallengesSent}}</td>     
                           <td>{{data.LettersSent}}</td>                            
                           <!-- <td>{{data.Status}}</td>        -->
                           </tr>                 
                        </tbody>
                        
                      </table>
                    </div> 
                   
                    </form>
                   </div>

         </div>
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
