import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../shared/login/login.service';

@Component({
  selector: 'app-client-login',
  templateUrl: './client-login.component.html',
  styleUrls: ['./client-login.component.css']
})
export class ClientLoginComponent implements OnInit {

  loginForm = this.fb.group({
    username:'',
    password:''
  });

  constructor(private fb:FormBuilder,private loginService: LoginService, private route: Router) { }



  ngOnInit(): void {this.loginService.logout();
  }

  login() {
    debugger
    if (!this.loginForm.invalid) {    

      let obj={
        key:"123",
        UserName:this.loginForm.value.username,
        UserPassword:this.loginForm.value.password
      }
      this.loginService.clientlogin(obj).subscribe(res =>{
        debugger
        if (res.status == 1) {
         // this.loginService.logout();
         debugger
           this.loginService.storeUserData(             
             res.data.clientId,
             res.data
            
           );
          //redirection
          if(res.data.clientStatus == true)
          {
            window.location.href="client-dashboard";
          }
          else
          {
            window.location.href="client-profile";
          }
          
        } else {
          Swal.fire('Login Fail.', 'Please check username and password.', 'error');
        }
      }, error => {
        Swal.fire("Warning!","Error","warning");
      });
    } else {
      Swal.fire('Oops..', 'Please fill all fields.', 'error');
    }
  }

}
