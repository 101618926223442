<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-12">           
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
                           <li class="breadcrumb-item active text-primary" aria-current="page"><span>{{htext}}</span></li>
                        </ol>        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-light">
                                <span class="text-secondary"><b>{{htext}}</b></span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="CustomerService" novalidate>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Firstname">First Name  </label> 
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">                                           
                                                    <input type="text" class="form-control"  
                                                    id="Firstname"   formControlName="FirstName" maxlength="20"  required/>                                                   
                                         
                                                </div>
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Lastname">Last Name  </label>
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control"  
                                                    id="Lastname"   formControlName="LastName" maxlength="20" required/>                                                   
                                                </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="Middlename">Middle Name </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" autocomplete="off" 
                                                    id="Middlename" formControlName="MiddleName" maxlength="20" />                                                   
                                                </div>                                            
                                            </div>
                                           
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="SetupFee">Email </label>
                                                    <span style="color: red;">*</span>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" autocomplete="off"  
                                                    id="Email" formControlName="Email" maxlength="50"  required/>                                                   
                                                </div>  
                                                <div class="col-md-4">
                                                    <label>Email format: john@gmail.com</label>
                                                 </div>                                            
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <label for="SetupFee">Phone </label>
                                                    <!-- <span style="color: red;">*</span> -->
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" autocomplete="off" 
                                                    id="Phone" formControlName="PhoneNumber" maxlength="14" />                                                   
                                                </div> 
                                                <div class="col-md-4">
                                                   <label>Phone Number format: (XXX) XXX-XXXX.</label>
                                                </div>  
                                                                                         
                                            </div>


                                            <div class="row pt-3">
                                                <div class="col-md-2"></div>
                                                <div class="col-md-4">
                                                    <button type="button" class="btn btn-primary" (click)="btnSave()">{{btext}}</button>&nbsp;
                                                    <a href="admin-managecustomerservice" class="btn btn-danger">Cancel</a>

                                                </div>
                                            </div>

                                               
                                        </form>
                                       
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                </div>   
            </div>   
            <app-footer></app-footer>    
        </div>
    </div>

    <!--  END CONTENT AREA  -->
    </div>
</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>