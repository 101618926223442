import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../shared/login/login.service';
import Swal from 'sweetalert2';
import { ElementSchemaRegistry } from '@angular/compiler';

@Component({
  selector: 'app-credit-items',
  templateUrl: './credit-items.component.html',
  styleUrls: ['./credit-items.component.css']
})
export class CreditItemsComponent implements OnInit {
  public id:string=""; public CreditReportId:string="";
  public itemscount:string="0";public inqcount:string="0";public prcount:string="0";
  public itemscredit:string="0";

  constructor(private loginService: LoginService) { }

  ngOnInit(): void {
    let user=this.loginService.getUser();
    if(user!=null||user!=undefined){
      this.id="";
    this.id = user.clientId;
    }
    this.GetCreditItemDetails();
    this.GetCreditReportData();
    this.GetCreditScoreData();
  }


  GetCreditItemDetails()
  {    
        
    if(this.id!=null && this.id!=""){    
      let obj={
        key:"123",
        clientid:this.id
      }
      this.loginService.GetCreditItemDetails(obj).subscribe(res=>{
        if(res.status==1){        
           if(res.data != null)
           {
            // this.CreditReportId=res.data.credReportId;
             
              $("#dateReportPulls").html(res.data.dateReportPulls);
              $("#roundType").html(res.data.roundType.toUpperCase());
            //  $("#totitems").html(res.data.totitems);
              $("#negativeItems").html(res.data.negativeItems);
           //   $("#nextDueDate").html(res.data.nextDueDate);
             // $("#nextRound").html(res.data.nextRound);
           }
        }
    });
    }
  }


  CreateChallengesForNegativeItems()
  {
    $("#btnChallenge").hide();
    var negativeItems = new Object();
    
    var AllAgenciesvalues = new Array();
    var items =parseInt(this.itemscount);
    for(var i=0; i < items; i++)
    {
      var j = i + 13;
      var c = i + 12;

      var hdnTRId = $("#hdnTRId_" + j).val();
      var hdnEQId = $("#hdnEQId_" + j).val();
      var hdnEXId = $("#hdnEXId_" + j).val();

      var lblRoundEQ = $("#lblRoundEQ_" + c).html();
      var lblRoundEX = $("#lblRoundEX_" + c).html();
      var lblRoundTR = $("#lblRoundTR_" + c).html();

    
      // var EXLoanStatus ="";var EQLoanStatus ="";var TULoanStatus ="";
      // var EXPastDueDays ="0";var EQPastDueDays ="0";var TUPastDueDays ="0";
      try {
        var m = i + 9; //LoanStatus
        
        var EXLoanStatus = $("#EXLoanStatus_" + m).val();
        var EQLoanStatus = $("#EQLoanStatus_" + m).val();
        var TULoanStatus = $("#TULoanStatus_" + m).val();

        var n = i + 10;//PastDue
        var TUPastDueDays = $("#TUPastDueDays_" + n).val();
        var EXPastDueDays = $("#EXPastDueDays_" + n).val();
        var EQPastDueDays = $("#EQPastDueDays_" + n).val();
    } catch (e) {
      EXPastDueDays ="0"; EQPastDueDays ="0"; TUPastDueDays ="0";
    }

    if(EXPastDueDays == null || EXPastDueDays == "")
    {
      EXPastDueDays="0";
    }
    if(EQPastDueDays == null || EQPastDueDays == "")
    {
      EQPastDueDays="0";
    }
    if(TUPastDueDays == null || TUPastDueDays == "")
    {
      TUPastDueDays="0";
    }


      var flag = false;
      if (hdnTRId != "" && hdnTRId != null && $("#customCheckTR_" + c).prop("checked") == true) {
        AllAgenciesvalues.push({
          'CredRepItemsId': parseInt(hdnTRId.toString()),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundTR, 'LoanStatus': TULoanStatus,
          'PastDueDays': parseInt(TUPastDueDays.toString()),
      });
      }

      if (hdnEQId != "" && hdnEQId != null && $("#customCheckEQ_" + c).prop("checked") == true) {
        AllAgenciesvalues.push({
          'CredRepItemsId': parseInt(hdnEQId.toString()),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEQ,
           'LoanStatus': EQLoanStatus,
          'PastDueDays': parseInt(EQPastDueDays.toString()),
      });
      }
      
      if (hdnEXId != "" && hdnEXId != null && $("#customCheckEX_" + c).prop("checked") == true) {
        AllAgenciesvalues.push({
          'CredRepItemsId': parseInt(hdnEXId.toString()),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEX,
           'LoanStatus': EXLoanStatus,
          'PastDueDays': parseInt(EXPastDueDays.toString()),
      });
      }

    }

///////////////PR
var AllAgenciesvaluesPR = new Array();
var itemsPR =parseInt(this.prcount);
for(var i=0; i < itemsPR; i++)
{
  var j = i + 5;
  var c = i + 4;

  var hdnTRIdPR = $("#hdnTRPRId_" + j).val();
  var hdnEQIdPR = $("#hdnEQPRId_" + j).val();
  var hdnEXIdPR = $("#hdnEXPRId_" + j).val();

  var lblRoundEQPR = $("#lblRoundEQPR_" + c).html();
  var lblRoundEXPR = $("#lblRoundEXPR_" + c).html();
  var lblRoundTRPR = $("#lblRoundTRPR_" + c).html();


  var flag = false;
  if (hdnTRIdPR != "" && hdnTRIdPR !=  null && $("#customCheckTRPR_" + c).prop("checked") == true) {
    AllAgenciesvaluesPR.push({
      'PublicRecordId': hdnTRIdPR.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundTRPR,
  });
  }

  if (hdnEQIdPR != "" && hdnEQIdPR != null && $("#customCheckEQPR_" + c).prop("checked") == true) {
    AllAgenciesvaluesPR.push({
      'PublicRecordId': hdnEQIdPR.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEQPR,
  });
  }
  
  if (hdnEXIdPR != "" && hdnEXIdPR != null && $("#customCheckEXPR_" + c).prop("checked") == true) {
    AllAgenciesvaluesPR.push({
      'PublicRecordId': hdnEXIdPR.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEXPR,
  });
  }

}
//////////////End

///////////////Inquires
var AllAgenciesvaluesINQ = new Array();
    var itemsINQ =parseInt(this.inqcount);
    for(var i=0; i < itemsINQ; i++)
    {
      var j = i + 5;
      var c = i + 4;

      var hdnTRIdINQ = $("#hdnTRInqId_" + j).val();
      var hdnEQIdINQ = $("#hdnEQInqId_" + j).val();
      var hdnEXIdINQ = $("#hdnEXInqId_" + j).val();

      var lblRoundEQINQ = $("#lblRoundEQI_" + c).html();
      var lblRoundEXINQ = $("#lblRoundEXI_" + c).html();
      var lblRoundTRINQ = $("#lblRoundTRI_" + c).html();


      var flag = false;
      if (hdnTRIdINQ != "" && hdnTRIdINQ !=  null && $("#customCheckTRI_" + c).prop("checked") == true) {
        AllAgenciesvaluesINQ.push({
          'CreditInqId': hdnTRIdINQ.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundTRINQ,
      });
      }

      if (hdnEQIdINQ != "" && hdnEQIdINQ != null && $("#customCheckEQI_" + c).prop("checked") == true) {
        AllAgenciesvaluesINQ.push({
          'CreditInqId': hdnEQIdINQ.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEQINQ,
      });
      }
      
      if (hdnEXIdINQ != "" && hdnEXIdINQ != null && $("#customCheckEXI_" + c).prop("checked") == true) {
        AllAgenciesvaluesINQ.push({
          'CreditInqId': hdnEXIdINQ.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEXINQ,
      });
      }

    }

///////////////END

    if (AllAgenciesvalues.length == 0)
    {
        
        Swal.fire("Warning!", "Please select challenge !", 'warning');
        return false;
    }

    let obj = {
      key:'123',
      CreditReportItems:AllAgenciesvalues,
      PublicRecords:AllAgenciesvaluesPR,
      Inquires:AllAgenciesvaluesINQ
    }
    
    this.loginService.CreateChallengeAH(obj).subscribe(res =>{
      
      if (res.status == 1) {

        Swal.fire("Success", "Challenge letter(s) generated successfully.", 'success');
          window.location.href="client-dashboard";
       
        
      } else {
        $("#btnChallenge").show();
        Swal.fire('Error.', 'Challenge not created.', 'error');
      }
    }, error => {$("#btnChallenge").show();
      Swal.fire("Warning!","Error","warning");
    });
   
  }

  CreateChallenge()
  {
    var items =parseInt(this.itemscount);
    var AllItemsChecked = true;
    for (var i = 0; i < items; i++) {
      var c = i + 12;
      if ($("#customCheckEX_" + c).prop("checked") == false) {
          AllItemsChecked = false;
          break;
      }
      if ($("#customCheckTR_" + c).prop("checked") == false) {
          AllItemsChecked = false;
          break;
      }
      if ($("#customCheckEQ_" + c).prop("checked") == false) {
          AllItemsChecked = false;
          break;
      }
    }

    if (AllItemsChecked == false) {
      Swal.fire({
          title: 'Some negative items are not selected. Are you sure you want to create challenge for selected negative items?',
          text: "This action is irreversible",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, create challenge!'
        }).then((result) => {
          if (result.value) {
            this.CreateChallengesForNegativeItems();
          }

      });
  }
  else { this.CreateChallengesForNegativeItems();}

  }


  // createChallengeInq()
  // {
    
  //   var AllAgenciesvalues = new Array();
  //   var items =parseInt(this.inqcount);
  //   for(var i=0; i < items; i++)
  //   {
  //     var j = i + 5;
  //     var c = i + 4;

  //     var hdnTRId = $("#hdnTRInqId_" + j).val();
  //     var hdnEQId = $("#hdnEQInqId_" + j).val();
  //     var hdnEXId = $("#hdnEXInqId_" + j).val();

  //     var lblRoundEQ = $("#lblRoundEQI_" + c).html();
  //     var lblRoundEX = $("#lblRoundEXI_" + c).html();
  //     var lblRoundTR = $("#lblRoundTRI_" + c).html();


  //     var flag = false;
  //     if (hdnTRId != "" && hdnTRId !=  null && $("#customCheckTRI_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'CreditInqId': hdnTRId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundTR,
  //     });
  //     }

  //     if (hdnEQId != "" && hdnEQId != null && $("#customCheckEQI_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'CreditInqId': hdnEQId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEQ,
  //     });
  //     }
      
  //     if (hdnEXId != "" && hdnEXId != null && $("#customCheckEXI_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'CreditInqId': hdnEXId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEX,
  //     });
  //     }

  //   }

  //   if (AllAgenciesvalues.length == 0)
  //   {
        
  //       Swal.fire("Warning!", "Please select challenge !", 'warning');
  //       return false;
  //   }

  //   this.loginService.CreateChallengeINQ(AllAgenciesvalues).subscribe(res =>{
      
  //     if (res.status == 1) {

  //       Swal.fire("Success", "Challenge letter(s) generated successfully.", 'success');
  //         window.location.href="client-dashboard";
       
        
  //     } else {
  //       Swal.fire('Error.', 'Challenge not created.', 'error');
  //     }
  //   }, error => {
  //     Swal.fire("Warning!","Error","warning");
  //   });

  // }

  //Not Used
  // CreateChallengeForInquiries()
  // {var AllItemsChecked = true;
  //   var items =parseInt(this.inqcount);
  //   for(var i=0; i < items; i++)
  //   {
  //     var j = i + 5;
  //     var c = i + 4;
  //     if ($("#customCheckEXI_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  //   if ($("#customCheckTRI_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  //   if ($("#customCheckEQI_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  // }

  //   if (AllItemsChecked == false) {
  //     Swal.fire({
  //       title: 'Some inquiries are not selected. Are you sure you want to create challenge for selected inquiries?',
  //         text: "This action is irreversible",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, create challenge!'
  //       }).then((result) => {
  //         if (result.value) {
  //           this.createChallengeInq();
  //         }

  //     });
  // }
  // else { this.createChallengeInq();}
  // }


  // createChallengePR()
  // {
    
  //   var AllAgenciesvalues = new Array();
  //   var items =parseInt(this.prcount);
  //   for(var i=0; i < items; i++)
  //   {
  //     var j = i + 5;
  //     var c = i + 4;

  //     var hdnTRId = $("#hdnTRPRId_" + j).val();
  //     var hdnEQId = $("#hdnEQPRId_" + j).val();
  //     var hdnEXId = $("#hdnEXPRId_" + j).val();

  //     var lblRoundEQ = $("#lblRoundEQPR_" + c).html();
  //     var lblRoundEX = $("#lblRoundEXPR_" + c).html();
  //     var lblRoundTR = $("#lblRoundTRPR_" + c).html();


  //     var flag = false;
  //     if (hdnTRId != "" && hdnTRId !=  null && $("#customCheckTRPR_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'PublicRecordId': hdnTRId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundTR,
  //     });
  //     }

  //     if (hdnEQId != "" && hdnEQId != null && $("#customCheckEQPR_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'PublicRecordId': hdnEQId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEQ,
  //     });
  //     }
      
  //     if (hdnEXId != "" && hdnEXId != null && $("#customCheckEXPR_" + c).prop("checked") == true) {
  //       AllAgenciesvalues.push({
  //         'PublicRecordId': hdnEXId.toString(),'key':'123','ClientId':this.id.toString(),'RoundType':lblRoundEX,
  //     });
  //     }

  //   }

  //   if (AllAgenciesvalues.length == 0)
  //   {
        
  //       Swal.fire("Warning!", "Please select challenge !", 'warning');
  //       return false;
  //   }

  //   this.loginService.CreateChallengePR(AllAgenciesvalues).subscribe(res =>{
      
  //     if (res.status == 1) {

  //       Swal.fire("Success", "Challenge letter(s) generated successfully.", 'success');
  //         window.location.href="client-dashboard";
       
        
  //     } else {
  //       Swal.fire('Error.', 'Challenge not created.', 'error');
  //     }
  //   }, error => {
  //     Swal.fire("Warning!","Error","warning");
  //   });

  // }

  // CreateChallengeForPR()
  // { 
  //   var AllItemsChecked = true;
  //   var items =parseInt(this.prcount);
  //   for(var i=0; i < items; i++)
  //   {
  //     var j = i + 5;
  //     var c = i + 4;
  //     if ($("#customCheckEXPR_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  //   if ($("#customCheckTRPR_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  //   if ($("#customCheckEQPR_" + c).prop("checked") == false) {
  //       AllItemsChecked = false;
  //       break;
  //   }
  // }

  //   if (AllItemsChecked == false) {
  //     Swal.fire({
  //       title: 'Some public records are not selected. Are you sure you want to create challenge for selected public records?',
  //         text: "This action is irreversible",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, create challenge!'
  //       }).then((result) => {
  //         if (result.value) {
  //           this.createChallengePR();
  //         }

  //     });
  // }
  // else { this.createChallengePR();}
  // }

  GetCreditReportData()
  {
  
    if(this.id!=null && this.id!=""){    
      let obj={
        key:"123",
        clientid:this.id
      }
      this.loginService.GetCreditReportData(obj).subscribe(res=>{
        if(res.status==1){     
           if(res.data != null)
           {var item="";var j=0;

            //CreditItems
            // this.CreditReportId=res.data.credReportId;
            this.itemscount=res.data.creditItems.length;
             for(var i=0; i< res.data.creditItems.length; i++)
             {
              // var j=(res.data.creditItems.length/14);
               

               var heading =res.data.creditItems[i].heading;
               var eqField =res.data.creditItems[i].equifax;
               var exField =res.data.creditItems[i].experian;
               var trField =res.data.creditItems[i].transunion;
                  //heading == "Challenge" ||  ||   heading == "CreditreportId"
              if(heading == "LoanStatus"  )
              {
                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                if(eqField == "EDUCATION")
                {
                  item+="<td><select name='LoanStatus' id='EQLoanStatus_"+i+"' class='form-control'>";
                  item+="<option value='0'>--Select LoanStatus--</option>";
                  item+="<option value='Deferment'>Deferment</option>";
                  item+="<option value='Forbearance'>Forbearance</option></select></td>";
                }
                else
                {
                  item +="<td></td>";
                }
                
                if(exField == "EDUCATION")
                {
                item+="<td><select name='LoanStatus' id='EXLoanStatus_"+i+"' class='form-control'>";
                item+="<option value='0'>--Select LoanStatus--</option>";
                item+="<option value='Deferment'>Deferment</option>";
                item+="<option value='Forbearance'>Forbearance</option></select></td>";
                }
                else
                {
                  item +="<td></td>";
                }

                if(trField == "EDUCATION")
                {
                item+="<td><select name='LoanStatus' id='TULoanStatus_"+i+"' class='form-control'>";
                item+="<option value='0'>--Select LoanStatus--</option>";
                item+="<option value='Deferment'>Deferment</option>";
                item+="<option value='Forbearance'>Forbearance</option></select></td>";
                }
                else
                {
                  item +="<td></td>";
                }

                item+="</tr>";

              }
              else if( heading == "PastDueDays" )
              {
                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                if(eqField == "EDUCATION")
                {
                item+="<td><input type='number' class='form-control' max='9' id='EQPastDueDays_"+i+"' /></td>";
                }
                else
                {
                  item +="<td></td>";
                }
                if(exField == "EDUCATION")
                {
                  item+="<td><input type='number' class='form-control' max='9' id='EXPastDueDays_"+i+"' /></td>";
                }
                else
                {
                  item +="<td></td>";
                }

                if(trField == "EDUCATION")
                {
                  item+="<td><input type='number' class='form-control' max='9' id='TUPastDueDays_"+i+"' /></td>";
                }
                else
                {
                  item +="<td></td>";
                }
                item+="</tr>";
              }
              else if(heading == "Challenge")
              { var trValue="-";var exValue="-";var eqValue="-";
                var tr = trField.split("^");
                var ex = exField.split("^");
                var eq = eqField.split("^");
                if(trField != "-" && trField != "")
                {
                  if(tr[0] != "")
                  {
                    var Round = tr[0].split("-");
                  if(Round.length > 1)
                  {
                    trValue= "ROUND-"+ (parseInt(Round[1]) +1);
                  }
                  else
                  {
                    trValue= "ROUND-"+ (parseInt(tr[0]) +1);
                  }
                    
                  }
               }
                
                if(exField != "-" && exField != "")
                {
                if(ex[0] != "")
                {
                  var Round = ex[0].split("-");
                  if(Round.length > 1)
                  {
                    exValue= "ROUND-"+ (parseInt(Round[1]) +1);
                  }
                  else
                  {
                    exValue= "ROUND-"+ (parseInt(ex[0]) +1);
                  }
                  
                }
                }

               if(eqField != "-" && eqField != "")
                {
                if(eq[0] != "")
                {
                  var eqRound = eq[0].split("-");
                  if(eqRound.length > 1)
                  {
                    eqValue= "ROUND-"+ (parseInt(eqRound[1]) +1);
                  }
                  else
                  {
                    eqValue= "ROUND-"+ (parseInt(eq[0]) +1);
                  }
                }
                }

                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                
                if(eqValue != "" && eqValue != "-")
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEQ_"+i+"' name='customCheckEX_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEQ_"+i+"' for='customCheckEQ_"+i+"'>"+ eqValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ eqValue +"</td>";   
                }

                if(exValue != "" && exValue != "-")
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEX_"+i+"' name='customCheckEQ_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEX_"+i+"' for='customCheckEX_"+i+"'>"+ exValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ exValue +"</td>";   
                }

                if(trValue != "" && trValue != "-")
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckTR_"+i+"' name='customCheckTR_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundTR_"+i+"' for='customCheckTR_"+i+"'>"+ trValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ trValue +"</td>";   
                }
                
                item+="</tr>"; 
              }
              else if(heading == "Payment Status")
              {
                
                var trValue="-";var exValue="-";var eqValue="-";
                var tr = trField.split("~");
                var ex = exField.split("~");
                var eq = eqField.split("~");
                if(trField != "-" && trField != "")
                {
                  if(tr[2] != "")
                  {
                    trValue= tr[0] + " ("+ tr[2] +")";
                  }
                  else
                  {
                    trValue= tr[0];
                  }
                }
                
                if(exField != "-" && exField != "")
                {
                if(ex[2] != "")
                {
                  exValue= ex[0] + " ("+ ex[2] +")";
                }
                else
                {
                  exValue= ex[0];
                }
              }

               if(eqField != "-" && eqField != "")
                {
                if(eq[2] != "")
                {
                  eqValue= eq[0] + " ("+ eq[2] +")";
                }
                else
                {
                  eqValue= eq[0];
                }
              }

                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                item += "<td id='eqfield' style='color:red;'>"+ eqValue +"</td>";
                item +="<td id='exfield' style='color:red;'>"+ exValue +"</td>";
                item+= "<td id='tufield' style='color:red;'>"+ trValue +"</td>";
                item+="</tr>"; 
              }
              else if(heading == "CreditreportId")
              {
                item +="<tr><td></td>";
                item += "<td><input type='hidden' id='hdnEQId_"+i+"' value="+ eqField +"></td>";
                item +="<td><input type='hidden' id='hdnEXId_"+i+"' value="+ exField +"></td>";
                item+= "<td><input type='hidden' id='hdnTRId_"+i+"' value="+ trField +"></td>";
                item+="</tr>";
              }
              else
              {
                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                item += "<td id='eqfield'>"+ eqField +"</td>";
                item +="<td id='exfield'>"+ exField +"</td>";
                item+= "<td id='tufield'>"+ trField +"</td>";
                item+="</tr>";
              }
                
               
               if(j == 13)
               {
                
                // item +="<tr><td></td>";
                // item+= "<td></td>";
                // item+= "<td></td>";
                // item+= "<td></td>";
                // item+="</tr>";
                j=0;
               }
               else
               {
                 j++;
               }
               

             }
              $("#credititems").html(item);
           
           
             //Inquiries
             
             item="";
             this.inqcount =res.data.inquires.length;
             for(var i=0; i< res.data.inquires.length; i++)
             {
               var heading =res.data.inquires[i].heading;
               var eqField =res.data.inquires[i].equifax;
               var exField =res.data.inquires[i].experian;
               var trField =res.data.inquires[i].transunion;

               if(heading == "Challenge Status")
               {

               }
               else if(heading == "Challenge")
              { var trValue="-";var exValue="-";var eqValue="-";
                if(trField != "-" && trField != "" && trField != null )
                {var tr = trField.split("-");
                  if(tr[1] != "")
                  {
                    trValue= "ROUND-"+ (parseInt(tr[1]) +1);
                  }
               }
               else
               {
                 if( trField != null)
                 {
                  trValue= "ROUND-1";
                 }
                   
               }
                
                if(exField != "-" && exField != "" && exField != null)
                {var ex = exField.split("-");
                if(ex[1] != "")
                {
                  exValue= "ROUND-"+ (parseInt(ex[1]) +1);
                }
                }
                else
               {
                if( exField != null)
                {
                  exValue= "ROUND-1";
                }
                
               }

               if(eqField != "-" && eqField != "" && eqField != null )
                {var eq = eqField.split("-");
                if(eq[1] != "")
                {
                  eqValue= "ROUND-"+ (parseInt(eq[1]) +1);
                }
                }
                else
                {
                  if( eqField != null)
                {
                  eqValue= "ROUND-1";
                }
                  
                }

                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                
                if(eqValue != "" && eqValue != "-" && eqValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEQI_"+i+"' name='customCheckEQI_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEQI_"+i+"' for='customCheckEQI_"+i+"'>"+ eqValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ eqValue +"</td>";   
                }
                if(exValue != "" && exValue != "-" && exValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEXI_"+i+"' name='customCheckEXI_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEXI_"+i+"' for='customCheckEXI_"+i+"'>"+ exValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ exValue +"</td>";   
                }

                if(trValue != "" && trValue != "-" && trValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckTRI_"+i+"' name='customCheckTRI_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundTRI_"+i+"' for='customCheckTRI_"+i+"'>"+ trValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ trValue +"</td>";   
                }
                item+="</tr>"; 
              }
               else if(heading == "CreditInqId")
              {
                item +="<tr><td></td>";
                item += "<td><input type='hidden' id='hdnEQInqId_"+i+"' value="+ eqField +"></td>";
                item +="<td><input type='hidden' id='hdnEXInqId_"+i+"' value="+ exField +"></td>";
                item+= "<td><input type='hidden' id='hdnTRInqId_"+i+"' value="+ trField +"></td>";
                item+="</tr>";
              }
               else
               {
                 if(eqField == null)
                 { eqField = "";}
                 if(exField == null)
                 { exField = "";}
                 if(trField == null)
                 { trField = "";}
                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                item += "<td id='eqfield'>"+ eqField +"</td>";
                item +="<td id='exfield'>"+ exField +"</td>";
                item+= "<td id='tufield'>"+ trField +"</td>";
                item+="</tr>";

               }
              }
              $("#inquiries").html(item);


              //Public Records
             
             item="";
             this.prcount =res.data.publicRecords.length;
             for(var i=0; i< res.data.publicRecords.length; i++)
             {
               var heading =res.data.publicRecords[i].heading;
               var eqField =res.data.publicRecords[i].equifax;
               var exField =res.data.publicRecords[i].experian;
               var trField =res.data.publicRecords[i].transunion;

               if(heading == "Challenge Status")
               {

               }
               else if(heading == "Challenge")
              { var trValue="-";var exValue="-";var eqValue="-";
                if(trField != "-" && trField != "" && trField != null )
                {var tr = trField.split("-");
                  if(tr[1] != "")
                  {
                    trValue= "ROUND-"+ (parseInt(tr[1]) +1);
                  }
               }
               else
               {
                 if( trField != null)
                 {
                  trValue= "ROUND-1";
                 }
                   
               }
                
                if(exField != "-" && exField != "" && exField != null)
                {var ex = exField.split("-");
                if(ex[1] != "")
                {
                  exValue= "ROUND-"+ (parseInt(ex[1]) +1);
                }
                }
                else
               {
                if( exField != null)
                {
                  exValue= "ROUND-1";
                }
                
               }

               if(eqField != "-" && eqField != "" && eqField != null )
                {var eq = eqField.split("-");
                if(eq[1] != "")
                {
                  eqValue= "ROUND-"+ (parseInt(eq[1]) +1);
                }
                }
                else
                {
                  if( eqField != null)
                {
                  eqValue= "ROUND-1";
                }
                  
                }

                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                
                if(eqValue != "" && eqValue != "-" && eqValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEQPR_"+i+"' name='customCheckEQPR_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEQPR_"+i+"' for='customCheckEQPR_"+i+"'>"+ eqValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ eqValue +"</td>";   
                }
                if(exValue != "" && exValue != "-" && exValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckEXPR_"+i+"' name='customCheckEXPR_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundEXPR_"+i+"' for='customCheckEXPR_"+i+"'>"+ exValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ exValue +"</td>";   
                }

                if(trValue != "" && trValue != "-" && trValue != null)
                {
                item+="<td><div class='input-group-prepend'>";
                item+="<span class='input-group-text'>";
                item+="<div class='custom-control custom-checkbox'>";
                item+="<input type='checkbox' checked class='custom-control-input' id='customCheckTRPR_"+i+"' name='customCheckTRPR_"+i+"'>";
                item+="<label class='custom-control-label' id='lblRoundTRPR_"+i+"' for='customCheckTRPR_"+i+"'>"+ trValue +"</label>";
                item+="</div></span></div></td>";
                }
                else
                {
                  item+= "<td >"+ trValue +"</td>";   
                }
                item+="</tr>"; 
              }
               else if(heading == "PublicRecordId")
              {
                item +="<tr><td></td>";
                item += "<td><input type='hidden' id='hdnEQPRId_"+i+"' value="+ eqField +"></td>";
                item +="<td><input type='hidden' id='hdnEXPRId_"+i+"' value="+ exField +"></td>";
                item+= "<td><input type='hidden' id='hdnTRPRId_"+i+"' value="+ trField +"></td>";
                item+="</tr>";
              }
               else
               {
                 if(eqField == null)
                 { eqField = "";}
                 if(exField == null)
                 { exField = "";}
                 if(trField == null)
                 { trField = "";}
                item +="<tr><td><strong><h6 id='heading'>"+ heading +"</h6></strong></td>";
                item += "<td id='eqfield'>"+ eqField +"</td>";
                item +="<td id='exfield'>"+ exField +"</td>";
                item+= "<td id='tufield'>"+ trField +"</td>";
                item+="</tr>";

               }
              }
              $("#PublicRecords").html(item);

            }

        }
        
    });
  }
  }
  CreateRefreshCredit(){
  debugger
    if(this.id!="" && this.id!=null){
      var roundtype=$("#roundType").text().trim();
     // var roundtype=$("#roundType").val();
      let obj={
        key:"123",
        clientid:this.id,
        round:roundtype
      }
      this.loginService.GetRefreshCreditPull(obj).subscribe(res=>{
          if(res.status==1){
              Swal.fire('Success','Credit Pull refreshed successfully.','success');
              window.location.href="credit-items";
              return;
          }
          else
          {
            Swal.fire('Warning',res.message,'warning');
            return;
          }
      });
    }
  }

  GetCreditScoreData()
  {
    var items="";
    
    if(this.id!=null && this.id!=""){    
      let obj={
        key:"123",
        clientid:this.id
      }
      this.loginService.GetCreditReportData(obj).subscribe(res=>{
        
        if(res.status==1){         
            if(res.data != null)
            {           
                 var itemscredit=res.data.creditScores.length;               
                  if(itemscredit!="0")
                  {        
                    var eqScore=res.data.creditScores[0].score;                        
                    var eqRank=res.data.creditScores[0].lenderRank;
                    var exScore=res.data.creditScores[1].score;
                    var exRank=res.data.creditScores[1].lenderRank;
                    var tuScore=res.data.creditScores[2].score;
                    var tuRank=res.data.creditScores[2].lenderRank;
  
                    items+="<tr><td><strong><h6>Credit Score</h6><strong></td><td>"+eqScore+"</td><td>"+exScore+"</td><td>"+tuScore+"</td></tr>";
                    items+="<tr><td><strong><h6>Lender Rank</h6><strong></td><td>"+eqRank+"</td><td>"+exRank+"</td><td>"+tuRank+"</td></tr>";
                    items+="<tr><td><strong><h6>Score Scale</h6><strong></td><td>300-850</td><td>300-850</td><td>300-850</td></tr>";
                 }  
                 else{
                  
                  items+="<tr><td><strong><h6>Credit Score</h6><strong></td><td>"+0+"</td><td>"+0+"</td><td>"+0+"</td></tr>";
                  items+="<tr><td><strong><h6>Lender Rank</h6><strong></td><td>-</td><td>-</td><td>-</td></tr>";
                  items+="<tr><td><strong><h6>Score Scale</h6><strong></td><td>300-850</td><td>300-850</td><td>300-850</td></tr>";

                }             

            }
            
          }
          $("#creditScore").html(items);
        });
      }    
  }
}
