import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customerservice-footer',
  templateUrl: './customerservice-footer.component.html',
  styleUrls: ['./customerservice-footer.component.css']
})
export class CustomerserviceFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
