<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-admin-sidebar></app-admin-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
              <li class="breadcrumb-item active text-primary" aria-current="page"><span>Manage Corporate Customer</span></li>
            </ol>
         </nav>
         <div class="row">
           <div class="col-md-12 text-right mb-3">
            <a href="admin-addcorporatecustomer" class="btn btn-primary"><i class="fa fa-plus"></i>&nbsp; Create Corporate Customer</a>&nbsp; 
           </div>
         </div>
         <div class="card">
              <div class="card-header  bg-light"><b class="text-secondary">Manage Corporate Customer</b></div>
                   <div class="card-body">
                    <div class="table-responsive">
                      <table id="tblCorporate"  class="table  table-bordered">
                        <thead>
                          <tr>
                            <th>Full Name</th>     
                            <th>Referral Code</th>                       
                            <th>Email Address</th>
                            <th>Phone Number</th>                            
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                         <tr *ngFor="let data of rowDataGrid">
                           <td>{{data.Fullname}}</td>
                           <td>{{data.RefferalCode}}</td>
                           <td>{{data.Email}}</td>
                           <td>{{data.PhoneNumber}}</td>                            
                           <td id="tduid"> <button title="click to active/inactive client"
                            (click)="UpdateStatus(data.RowId,data.Status)" class="{{data.chgColor}}" id="btnActive" 
                             style="width: 100px;">{{data.Status}}</button>
                           </td>      
                        
                           <td> <a href="admin-addcorporatecustomer?Rid={{data.RowId}}" class="btn btn-info fa fa-edit" title="Edit"  style="padding-left:1rem;margin:.2rem;width:50px;"></a>
                           
                            </td>
                         </tr>
                       </tbody>
                      </table>
                  </div>  
                   </div>
         </div>
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
