<app-header></app-header> 


<div class="main-container" id="container">
  <div class="overlay"></div>
  <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-corp-sidebar></app-corp-sidebar>
    </div>
    <!--  END SIDEBAR  -->    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">      
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <nav class="breadcrumb-one" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ml-auto"><a href="dashboard/admin">Dashboard</a></li>
              <li class="breadcrumb-item active text-primary" aria-current="page"><span>Client Challenges</span></li>
            </ol>
         </nav>
         <div class="card">
              <div class="card-header  bg-light"><b class="text-secondary">Client Challenges</b></div>
                   <div class="card-body">
                    <div class="col-md-12 pt-1 pb-3 text-right">
                         <button type="button" class="btn btn-secondary" (click)="btnMark()">Mark as Print</button>&nbsp;
                                
                    </div>               
                               
                    <div class="table-responsive">
                      <table id="tblClientChallenges" class="table  table-bordered">
                        <thead>
                          <tr>     
                            <th>                               
                                <input type="checkbox"  id="selectAll"  [checked]="checkAll()">
                                                     
                              </th>                      
                            <th>Client Name</th>
                            <th>Referral Code</th>
                            <th>Round Type</th>                                    
                            <th>File Name</th>   
                            <th>Actions</th> 
                        
                          </tr>
                        </thead>
                         <tbody>
                          <tr *ngFor="let data of rowDataGrid">
                             <td  class="checkbox-column pt-3">
                                 <input type="checkbox" value="{{data.creditRepFileId}}" name='row-check' id="checkOne" [checked]="checkAllCheckBox()"/></td>
                           <td>{{data.Fullname}}</td>    
                           <td>{{data.RefCode}}</td>                           
                           <td>{{data.RoundType}}</td>
                           <td>{{data.Agency}}</td>    
                           <td><a (click)="ViewPDF(data.Filename)" class="btn btn-success" title="print">Print</a> 
                           </td>                   
                           </tr>                 
                        </tbody>                         
                      </table>
                    </div>                    
                    
                   </div>

         </div>
        </div>   
        <app-footer></app-footer>    
    </div>
  </div>

    <!--  END CONTENT AREA  -->
  </div>

</div>
