<app-header></app-header>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="main-container" id="container">
    <div class="overlay"></div>
    <div class="search-overlay"></div>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">        
        <app-corp-sidebar></app-corp-sidebar>
    </div>
    <!--  END SIDEBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">      
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-md-12">           
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item ml-auto"><a href="corp-dashboard">Dashboard</a></li>
                           <li class="breadcrumb-item active text-primary" aria-current="page"><span>{{htext}}</span></li>
                        </ol>        
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header bg-light">
                                <span class="text-secondary"><b>{{htext}}</b></span>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form class="text-left" [formGroup]="SignUpForm" novalidate>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <div id="refcode-field" class="field-wrapper input">
                                                        <label for="refcode">Referral Code<span class="text-danger" style="font-size:medium;">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control"
                                                     formControlName="refcode"  id="refcode"
                                                          maxlength="6" required>                                               
                                                </div>                                               
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <div id="firstname-field" class="field-wrapper input">
                                                        <label for="firstname">First Name<span class="text-danger" style="font-size:medium;">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" formControlName="firstname"  id="firstname"
                                                         maxlength="20" required>                                                
                                                </div>                                               
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">                                            
                                                        <div id="lastname-field" class="field-wrapper input">
                                                            <label for="lastname">Last Name<span class="text-danger" style="font-size:medium;">*</span></label>
                                                        </div>                                    
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" formControlName="lastname"  id="lastname"
                                                    required>                      
                                           
                                                </div>
                                            </div>
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <div id="emailaddress-field" class="field-wrapper input">
                                                        <label for="emailaddress">Email Address<span class="text-danger" style="font-size:medium;">*</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="text" class="form-control" formControlName="emailaddress"  id="emailaddress"
                                                     (change)=emailvalidation() required>        
                                     
                                                </div>
                                            </div>                      
                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <div id="password-field" class="field-wrapper input">
                                                        <div class="d-flex justify-content-between">
                                                            <label for="password">Password<span class="text-danger" style="font-size:medium;">*</span></label>
                                                          </div>
                                                        </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="password" class="form-control"
                                                     formControlName="password"  id="Password" required>                      
                                                </div>
                                            </div>

                                            <div class="row pt-3">
                                                <div class="col-md-2">
                                                    <div id="confirmpassword-field" class="field-wrapper input mb-2">
                                                        <div class="d-flex justify-content-between">
                                                            <label for="confirmpassword">Confirm Password<span class="text-danger" style="font-size:medium;">*</span></label>
                                                        </div>
                                                     </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <input type="password" class="form-control" 
                                                    formControlName="cnfpassword"  
                                                    id="cnfPassword" required>                      
                                                </div>                                               
                                            </div>
                                            <div class="row pt-3"> 
                                                <div class="col-lg-2"></div>    
                                                <div class="col-lg-10 pb-5">                                 
                                                       <div class="field-wrapper">                                                                   
                                                        <button type="button" class="btn btn-primary" (click)="btnSave()">{{btext}}</button>&nbsp;
                                                        <a href="corp-dashboard" class="btn btn-danger">Cancel</a>
                                                       </div>
                                               
                                                </div>
                                            </div>
                                     </form>
                    
                                       
                                    </div>
                                </div>
                                                           
                            </div>
                        </div>
                    </div>
                </div>   
            </div>   
            <app-footer></app-footer>    
        </div>
    </div>

    <!--  END CONTENT AREA  -->
    </div>
</div>

<!-- END MAIN CONTAINER --><html><head><META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=utf-8"><meta name="Robots" content="NOINDEX " /></head><body></body>
                <script type="text/javascript">
                 var gearPage = document.getElementById('GearPage');
                 if(null != gearPage)
                 {
                     gearPage.parentNode.removeChild(gearPage);
                     document.title = "Error";
                 }
                 </script>
                 </html>